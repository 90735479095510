import { IGoodDocument } from './good.type';

export interface IGoodDTO {
  id?: number;
  titleDe: string;
  titleRu: string;
  price: number;
  weight: number;
  tara: number;
  code: string;
  goodUnitId: number | null;
  goodCategoryId: number | null;
}

export const transferDTO = (item: IGoodDocument): IGoodDTO => ({
  id: item.id,
  titleDe: item.titleDe,
  titleRu: item.titleRu,
  price: item.price,
  tara: item.tara,
  code: item.code,
  weight: item.weight,
  goodUnitId: item.goodUnit?.id ? item.goodUnit.id : null,
  goodCategoryId: item.goodCategory?.id ? item.goodCategory.id : null,
});

export const transferItems = (item: IGoodDocument, index: number) => ({
  key: `key-${index}`,
  id: item.id,
  titleDe: item.titleDe,
  titleRu: item.titleRu,
  price: item.price,
  tara: item.tara,
  code: item.code,
  weight: item.weight,
  goodUnit: item.goodUnit?.title,
  goodCategory: item.goodCategory?.title,
});
