import { INavLink, INavLinkGroup, INavStyles, Nav } from "@fluentui/react";
import * as React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HelperService } from "../../../services/helper.service";
import { contextSegments } from "../../Controls/context-segments";

const helperService: HelperService = HelperService.getInstance();

const navStyles: Partial<INavStyles> = {
  root: {
    flexBasis: "230px",
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
    border: "1px solid #eee",
    overflowY: "auto",
  },
};

const navLinkGroups: INavLinkGroup[] = [
  {
    links: [
      {
        name: "Home",
        url: "/",
        key: helperService.makeId(10),
        target: "_self",
      },
      {
        name: contextSegments.cargolist.pluralName,
        url: contextSegments.cargolist.apiKey,
        key: helperService.makeId(10),
        target: "_self",
      },
      {
        name: "Grunddaten",
        url: "",
        disabled: true,
        isExpanded: true,
        links: [
          {
            name: contextSegments.tour.pluralName,
            url: contextSegments.tour.apiKey,
            key: helperService.makeId(10),
            target: "_self",
          },
          {
            name: contextSegments.address.pluralName,
            url: contextSegments.address.apiKey,
            key: helperService.makeId(10),
            target: "_self",
          },
          {
            name: contextSegments.loadingLocation.pluralName,
            url: contextSegments.loadingLocation.apiKey,
            key: helperService.makeId(10),
            target: "_self",
          },
          {
            name: contextSegments.church.pluralName,
            url: contextSegments.church.apiKey,
            key: helperService.makeId(10),
            target: "_self",
          },
          {
            name: contextSegments.driver.pluralName,
            url: contextSegments.driver.apiKey,
            key: helperService.makeId(10),
            target: "_self",
          },
          {
            name: contextSegments.vehicle.pluralName,
            url: contextSegments.vehicle.apiKey,
            key: helperService.makeId(10),
            target: "_self",
          },
          {
            name: contextSegments.vehicleType.pluralName,
            url: contextSegments.vehicleType.apiKey,
            key: helperService.makeId(10),
            target: "_self",
          },
          {
            name: contextSegments.trailer.pluralName,
            url: contextSegments.trailer.apiKey,
            key: helperService.makeId(10),
            target: "_self",
          },
          {
            name: contextSegments.trailerType.pluralName,
            url: contextSegments.trailerType.apiKey,
            key: helperService.makeId(10),
            target: "_self",
          },
          {
            name: contextSegments.good.pluralName,
            url: contextSegments.good.apiKey,
            key: helperService.makeId(10),
            target: "_self",
          },
          {
            name: contextSegments.goodCategory.pluralName,
            url: contextSegments.goodCategory.apiKey,
            key: helperService.makeId(10),
            target: "_self",
          },
          {
            name: contextSegments.goodCondition.pluralName,
            url: contextSegments.goodCondition.apiKey,
            key: helperService.makeId(10),
            target: "_self",
          },
          {
            name: contextSegments.goodInfo.pluralName,
            url: contextSegments.goodInfo.apiKey,
            key: helperService.makeId(10),
            target: "_self",
          },
          {
            name: contextSegments.goodUnit.pluralName,
            url: contextSegments.goodUnit.apiKey,
            key: helperService.makeId(10),
            target: "_self",
          },
          {
            name: contextSegments.text.pluralName,
            url: contextSegments.text.apiKey,
            key: helperService.makeId(10),
            target: "_self",
          },
        ],
      },
    ],
  },
];

interface IProps {}

const Sidemenu = (props: IProps) => {
  // Own state
  const [activeLinkKey, setActiveLinkKey] = useState<string | undefined>(
    navLinkGroups[0].links[0].key
  );
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    selectCurrentMenuOption(location.pathname);
  });

  const selectCurrentMenuOption = (path: string) => {
    const activeKey =
      navLinkGroups[0].links.find((l) => l.url === path.substring(1))?.key ||
      navLinkGroups[0].links[2].links?.find((l) => l.url === path.substring(1))
        ?.key;

    setActiveLinkKey(activeKey);
  };

  const onLinkClick = (
    ev?: React.MouseEvent<HTMLElement>,
    item?: INavLink
  ): void => {
    if (ev) {
      ev.preventDefault();
    }

    if (item && !item.isMenuParent) setActiveLinkKey(item.key);

    if (item) {
      navigate(item.url);
    }
  };

  return (
    <div style={{ flex: "1 1 230px" }}>
      <Nav
        onLinkClick={onLinkClick}
        selectedKey={activeLinkKey}
        styles={navStyles}
        groups={navLinkGroups}
      />
    </div>
  );
};

export default Sidemenu;
