import { useState } from "react";
import { contextSegments, FormMode } from "../../../Controls/context-segments";
import { columns } from "./cargo-columns";
import { transferItems } from "./cargo.dto";
import { ICargoDocument } from "./cargo.type";
import { CargoDetailsList } from "./CargoDetailsList/CargoDetailsList";
import CargoForm from "./CargoForm";

interface IProps {
  cargoListFormMode: FormMode;
  cargos: ICargoDocument[];
  setCargos: (newCargos: ICargoDocument[]) => void;
}

const Cargo = (props: IProps) => {
  // Own state
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode>(FormMode.create);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const closeModal = async () => {
    setIsModalOpen(false);
    setSelectedItem(null);
    setFormMode(FormMode.create);
  };

  const openItem = (itemKey: string) => {
    const item = props.cargos.find((c) => c.key === itemKey);

    // Set found item
    setSelectedItem(item);
    setFormMode(FormMode.view);
    setIsModalOpen(true);
  };

  return (
    <>
      <CargoForm
        isModalOpen={isModalOpen}
        formMode={formMode}
        selectedItem={selectedItem}
        cargoListFormMode={props.cargoListFormMode}
        cargos={props.cargos}
        setFormMode={setFormMode}
        closeModal={closeModal}
        setCargos={props.setCargos}
      />
      <CargoDetailsList
        columns={columns}
        title={contextSegments.cargo.singularName}
        cargoListFormMode={props.cargoListFormMode}
        cargos={props.cargos}
        showModal={() => setIsModalOpen(true)}
        openItem={openItem}
        setCargos={props.setCargos}
        items={props.cargos.map((x) => transferItems(x))}
      />
    </>
  );
};

export default Cargo;
