import { useState } from "react";
import { ApiService } from "../../../services/api.service";
import { contextSegments, FormMode } from "../../Controls/context-segments";
import { DynamicDetailsList } from "../../Controls/DetailsList/DynamicDetailsList";
import { columns } from "./text-columns";
import { ITextDocument } from "./text.type";
import TextForm from "./TextForm";

interface IProps {}

const apiCalls: ApiService = ApiService.getInstance();

const Text = (props: IProps) => {
  // Own state
  const [reload, setReload] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode>(FormMode.create);
  const [selectedItem, setSelectedItem] = useState<ITextDocument | null>(null);

  const closeModal = async () => {
    setIsModalOpen(false);
    setSelectedItem(null);
    setFormMode(FormMode.create);
  };

  const openItem = async (itemId: number) => {
    //   Fetch selected item by id
    const res = await apiCalls.getSingleText(itemId);
    if (res?.status === 200) {
      setSelectedItem(res.data);
      setFormMode(FormMode.view);
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <TextForm
        isModalOpen={isModalOpen}
        formMode={formMode}
        selectedItem={selectedItem}
        setFormMode={setFormMode}
        closeModal={closeModal}
        setReload={setReload}
      />
      <DynamicDetailsList
        reload={reload}
        columns={columns}
        contextKey={contextSegments.text.contextKey}
        title={contextSegments.text.pluralName}
        setReload={setReload}
        showModal={() => setIsModalOpen(true)}
        openItem={openItem}
      />
    </>
  );
};

export default Text;
