import { IColumn } from "@fluentui/react";
import { HelperService } from "../../../services/helper.service";
import { attributes } from "./address.type";

const helperService: HelperService = HelperService.getInstance();

export const columns: IColumn[] = [
  {
    key: helperService.makeId(10),
    name: "Ansehen",
    fieldName: "edit",
    minWidth: 60,
    maxWidth: 70,
  },
  {
    key: helperService.makeId(10),
    name: attributes.titleDe.label,
    fieldName: attributes.titleDe.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.titleRu.label,
    fieldName: attributes.titleRu.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.countryDe.label,
    fieldName: attributes.countryDe.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.countryRu.label,
    fieldName: attributes.countryRu.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.cityDe.label,
    fieldName: attributes.cityDe.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.cityRu.label,
    fieldName: attributes.cityRu.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.streetDe.label,
    fieldName: attributes.streetDe.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.streetRu.label,
    fieldName: attributes.streetRu.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.zip.label,
    fieldName: attributes.zip.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },

  {
    key: helperService.makeId(10),
    name: attributes.kod.label,
    fieldName: attributes.kod.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },

  {
    key: helperService.makeId(10),
    name: attributes.countryIso.label,
    fieldName: attributes.countryIso.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.exitCustomsOffice.label,
    fieldName: attributes.exitCustomsOffice.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.contactPerson.label,
    fieldName: attributes.contactPerson.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.phone.label,
    fieldName: attributes.phone.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.fax.label,
    fieldName: attributes.fax.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.mail.label,
    fieldName: attributes.mail.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.homepage.label,
    fieldName: attributes.homepage.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.additionalTextDe.label,
    fieldName: attributes.additionalTextDe.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.additionalTextRu.label,
    fieldName: attributes.additionalTextRu.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
];
