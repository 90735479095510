import { ICargoDocument } from './cargo.type';

export interface ICargoDTO {
  id?: number;
  customTitleDe: string;
  customTitleRu: string;
  goodId: number | null;
  goodConditionId: number | null;
  cargolistId: number | null;
  amount: number;
  customNettoWeight: number;
  customBruttoWeight: number;
  customCode: string;
  customPrice: number;
}

export const transferDTO = (item: ICargoDocument): ICargoDTO => ({
  id: item.id,
  customTitleDe: item.customTitleDe,
  customTitleRu: item.customTitleRu,
  goodId: item.good?.id ? item.good.id : null,
  goodConditionId: item.goodCondition?.id ? item.goodCondition.id : null,
  amount: item.amount,
  customNettoWeight: getNettoWeight(item),
  customBruttoWeight: getBruttoWeight(item),
  customCode: item.customCode || '',
  customPrice: getPrice(item),
  cargolistId: item.cargolistId,
});

export const transferCargo = (item: ICargoDocument) => ({
  key: `key-${getRandomInt()}`,
  ...item,
});

export const transferItems = (item: ICargoDocument) => ({
  id: item.id,
  key: item.key,
  customTitleDe: item.customTitleDe,
  customTitleRu: item.customTitleRu,
  good: item.good?.titleDe,
  goodCondition: item.goodCondition?.title,
  amount: `${item.amount} ${item.good?.goodUnit?.title}`,
  customNettoWeight: getNettoWeight(item),
  customCode: item.customCode || '',
  customBruttoWeight: getBruttoWeight(item),
  customPrice: getPrice(item),
});

const getPrice = (item: any): number => {
  if (item.customPrice) {
    return item.customPrice;
  }
  if (item.good) {
    return Math.trunc(item.good.price * item.amount);
  }
  return 0;
};

const getNettoWeight = (item: any): number => {
  if (item.customNettoWeight) {
    return item.customNettoWeight;
  }
  if (item.good && item?.good?.weight !== 0) {
    return Math.trunc((item.good.weight - item.good.tara) * item.amount);
  }
  return 0;
};

const getBruttoWeight = (item: any): number => {
  if (item.customBruttoWeight) {
    return item.customBruttoWeight;
  }
  if (item.good) {
    return Math.trunc(item.good.weight * item.amount);
  }
  return 0;
};

const getRandomInt = () => Math.floor(Math.random() * 300000);
