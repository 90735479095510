import { ILoadingLocationDocument } from './loading-location.type';

export interface ILoadingLocationDTO {
  id?: number;
  city: string;
  country: string;
  street: string;
  zip: string;
  contactPerson: string;
  mail: string;
  phone: string;
  fax: string;
  exportCustomsOffice: string;
  originCountry: string;
  code: string;
}

export const transferDTO = (item: ILoadingLocationDocument): ILoadingLocationDTO => ({
  id: item.id,
  city: item.city,
  country: item.country,
  street: item.street,
  zip: item.zip,
  contactPerson: item.contactPerson,
  mail: item.mail,
  phone: item.phone,
  fax: item.fax,
  exportCustomsOffice: item.exportCustomsOffice,
  originCountry: item.originCountry,
  code: item.code,
});

export const transferItems = (item: ILoadingLocationDocument, index: number): any => ({
  key: `key-${index}`,
  id: item.id,
  city: `${item.city} ${item.zip ?? ''}`,
  country: item.country,
  street: item.street,
  zip: item.zip,
  contactPerson: item.contactPerson,
  mail: item.mail,
  phone: item.phone,
  fax: item.fax,
  exportCustomsOffice: item.exportCustomsOffice,
  originCountry: item.originCountry,
  code: item.code,
});
