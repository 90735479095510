import {
    ComboBox,
    IComboBox,
    IComboBoxOption,
    IconButton,
    Modal
} from "@fluentui/react";
import { ITextProps, Text } from "@fluentui/react/lib/Text";
import React, { useState } from "react";
import { HelperService } from "../../../../services/helper.service";
import {
    cancelIcon,
    contentStyles,
    iconButtonStyles
} from "../../../Controls/component-styles";
import { contextSegments, FormMode } from "../../../Controls/context-segments";
import { DynamicFormCommandBar } from "../../../Controls/DynamicFormCommandBar/DynamicFormCommandBar";
interface IProps {
  isModalOpen: boolean;
  closeModal: () => void;
}
const helperService: HelperService = HelperService.getInstance();

const ExportForm = (props: IProps) => {
  // Own state
  const [entity, setEntity] = useState<IComboBoxOption>({
    key: contextSegments.cargolist.apiKey,
    text: "Ladelisten",
  });

  const titleId = helperService.makeId(10);
  const formName: string = "export-form";

  const options: IComboBoxOption[] = [
    { key: contextSegments.tour.apiKey, text: contextSegments.tour.pluralName },
    {
      key: contextSegments.cargolist.apiKey,
      text: contextSegments.cargolist.pluralName,
    },
    {
      key: contextSegments.church.apiKey,
      text: contextSegments.church.pluralName,
    },
    {
      key: contextSegments.driver.apiKey,
      text: contextSegments.driver.pluralName,
    },
    { key: "drivers/yearly", text: "Fahrtenliste - Fahrer" }, //TODO: Need to be reimplemented. Drivers rides shall be visible in the list it self
    { key: contextSegments.cargo.apiKey, text: "Beladungen" },
    {
      key: contextSegments.address.apiKey,
      text: contextSegments.address.pluralName,
    },
    {
      key: contextSegments.loadingLocation.apiKey,
      text: contextSegments.loadingLocation.pluralName,
    },
    {
      key: contextSegments.trailer.apiKey,
      text: contextSegments.trailer.pluralName,
    },
    {
      key: contextSegments.trailerType.apiKey,
      text: contextSegments.trailerType.pluralName,
    },
    {
      key: contextSegments.vehicle.apiKey,
      text: contextSegments.vehicle.pluralName,
    },
    {
      key: contextSegments.vehicleType.apiKey,
      text: contextSegments.vehicleType.pluralName,
    },
    { key: contextSegments.good.apiKey, text: contextSegments.good.pluralName },
    {
      key: contextSegments.goodCategory.apiKey,
      text: contextSegments.goodCategory.pluralName,
    },
    {
      key: contextSegments.goodUnit.apiKey,
      text: contextSegments.goodUnit.pluralName,
    },
    {
      key: contextSegments.goodCondition.apiKey,
      text: contextSegments.goodCondition.pluralName,
    },
    {
      key: contextSegments.goodInfo.apiKey,
      text: contextSegments.goodInfo.pluralName,
    },
  ];

  const setCombobox = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption | undefined,
    index?: number | undefined,
    value?: string | undefined
  ) => {
    setEntity(option!);
  };

  return (
    <Modal
      titleAriaId={titleId}
      isOpen={props.isModalOpen}
      onDismiss={props.closeModal}
      isBlocking={false}
      containerClassName={contentStyles.container}
    >
      <div className={contentStyles.header}>
        <Text id={titleId} variant={"xxLarge" as ITextProps["variant"]}>
          Daten exportieren
        </Text>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={props.closeModal}
        />
      </div>
      <div className={contentStyles.body}>
        <DynamicFormCommandBar
          formMode={FormMode.view}
          entity={{ text: entity.text.toString(), key: entity.key.toString() }}
          closeModal={props.closeModal}
          formName={formName}
        />
        <ComboBox
          onChange={setCombobox}
          required
          selectedKey={entity.key}
          label="Entität"
          options={options}
        />
      </div>
    </Modal>
  );
};

export default ExportForm;
