import { ITrailerTypeDocument } from '../TrailerType/trailer-type.type';

export const attributes = {
  trailerType: {
    label: 'Anhängerart',
    name: 'trailerType',
  },
  licensePlate: {
    label: 'Kennzeichen',
    name: 'licensePlate',
  },
  countryCode: {
    label: 'Landcode',
    name: 'countryCode',
  },
  payload: {
    label: 'Nutzlast',
    name: 'payload',
  },
};

export interface ITrailerDocument {
  id?: number;
  licensePlate: string;
  payload: number;
  countryCode: string;
  trailerType: ITrailerTypeDocument | null;
}

export class Trailer implements ITrailerDocument {
  licensePlate = '';

  payload = 0;

  countryCode = '';

  trailerType = null;
}
