import { ActionButton } from "@fluentui/react";
import { ITextProps, Text } from "@fluentui/react/lib/Text";
import { useState } from "react";
import { exportIcon } from "../../../Controls/component-styles";
import ExportForm from "./ExportForm";
interface IProps {
  isLoading: boolean;
  overview: any;
}

export const Export = (props: IProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  return props.isLoading ? null : (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ margin: "5px" }}>
          <Text
            variant={"large" as ITextProps["variant"]}
            style={{ textDecoration: "underline" }}
          >
            Kurzübersicht Jahr {new Date().getFullYear()}
          </Text>
          <div style={{ display: "flex", gap: "10px" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text variant={"medium" as ITextProps["variant"]}>Anzahl:</Text>
              <Text variant={"medium" as ITextProps["variant"]}>
                Gesamtgewicht:
              </Text>
              <Text variant={"medium" as ITextProps["variant"]}>
                Gesamtwert:
              </Text>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text variant={"medium" as ITextProps["variant"]}>
                {props.overview.amount}
              </Text>
              <Text variant={"medium" as ITextProps["variant"]}>
                {props.overview.weight} Kg
              </Text>
              <Text variant={"medium" as ITextProps["variant"]}>
                {props.overview.price} €
              </Text>
            </div>
          </div>
        </div>
        <Text variant={"xxLarge" as ITextProps["variant"]}>
          Jährliche Statistik
        </Text>
        <div>
          <ActionButton
            iconProps={exportIcon}
            onClick={() => setIsModalOpen(true)}
          >
            Daten exportieren
          </ActionButton>
        </div>
      </div>
      <ExportForm
        isModalOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
      />
    </div>
  );
};
