import { IconButton, Label, Modal, TextField } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { HelperService } from "../../../../services/helper.service";
import {
  cancelIcon,
  contentStyles,
  iconButtonStyles,
  labelStyles,
  valueStyles,
} from "../../../Controls/component-styles";
import {
  contextSegments,
  FormMode,
  InputType,
} from "../../../Controls/context-segments";
import Dropdown from "../../../Controls/Dropdown/Dropdown";
import { Option } from "../../../Controls/Dropdown/option";
import { DynamicFormCommandBar } from "../../../Controls/DynamicFormCommandBar/DynamicFormCommandBar";
import ErrorCallout from "../../../Controls/ErrorCallout/ErrorCallout";
import { IGoodDocument } from "../../Good/good.type";
import { GoodForm } from "../../Good/GoodForm";
import { IGoodConditionDocument } from "../../GoodCondition/good-condition.type";
import GoodConditionForm from "../../GoodCondition/GoodConditionForm";
import { transferCargo } from "./cargo.dto";
import { attributes, Cargo, ICargoDocument } from "./cargo.type";

interface IProps {
  isModalOpen: boolean;
  selectedItem: ICargoDocument | null;
  formMode: FormMode;
  cargoListFormMode: FormMode;
  cargos: ICargoDocument[];
  closeModal: () => void;
  setFormMode: (formMode: FormMode) => void;
  setCargos: (newCargos: ICargoDocument[]) => void;
}

const formName: string = "cargo-form";
const helperService = HelperService.getInstance();
const buttonId: string = helperService.makeId(10);

const CargoForm = (props: IProps) => {
  // Own state
  const [payload, setPayload] = useState<ICargoDocument>(new Cargo());

  //   Good
  const [isGoodModalOpen, setIsGoodModalOpen] = useState<boolean>(false);
  const [showGoodSelectedError, setShowGoodSelectedError] =
    useState<boolean>(false);
  const [goodFormMode, setGoodFormMode] = useState<FormMode>(FormMode.create);
  const [selectedGoodItem, setSelectedGoodItem] =
    useState<IGoodDocument | null>(null);
  const [shouldSetDefaultGoodOption, setShouldSetDefaultGoodOption] =
    useState<boolean>(true);

  const closeGoodModal = async () => {
    setIsGoodModalOpen(false);
    setSelectedGoodItem(null);
    setGoodFormMode(FormMode.create);
  };

  const setGoodOption = async (e: any, newValue: any) => {
    handleOnChange(e, newValue);
    // Signal that the item need to be preselected in the dropdown
    setShouldSetDefaultGoodOption(true);
  };

  const formHeading: string = helperService.getFormHeading(
    contextSegments.cargo.singularName,
    props.formMode
  );

  //   GoodCondition
  const [isGoodConditionModalOpen, setIsGoodConditionModalOpen] =
    useState<boolean>(false);
  const [goodConditionFormMode, setGoodConditionFormMode] = useState<FormMode>(
    FormMode.create
  );
  const [selectedGoodConditionItem, setSelectedGoodConditionItem] =
    useState<IGoodConditionDocument | null>(null);
  const [
    shouldSetDefaultGoodConditionOption,
    setShouldSetDefaultGoodConditionOption,
  ] = useState<boolean>(true);

  const closeGoodConditionModal = async () => {
    setIsGoodConditionModalOpen(false);
    setSelectedGoodConditionItem(null);
    setGoodConditionFormMode(FormMode.create);
  };

  const setGoodConditionOption = async (e: any, newValue: any) => {
    handleOnChange(e, newValue);
    // Signal that the item need to be preselected in the dropdown
    setShouldSetDefaultGoodConditionOption(true);
  };

  useEffect(() => {
    // In case some item was passed to the form
    // Set this item to the payload
    if (props.selectedItem) {
      setPayload(props.selectedItem);
    }
    // In case no item was passed to the form
    // Set default empty payload
    else {
      setPayload(new Cargo());
      setShouldSetDefaultGoodConditionOption(true);
      setShouldSetDefaultGoodOption(true);
    }
    // Clear the input when closing the modal
    if (!props.isModalOpen) {
      setPayload(new Cargo());
    }
  }, [props.selectedItem, props.isModalOpen]);

  const titleId = helperService.makeId(10);

  /**
   * Invoke create action
   * @param e
   */
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!payload.good) {
      setShowGoodSelectedError(true);

      return;
    }
    setShowGoodSelectedError(false);

    // In case payload id exist
    // Update the item
    if (payload.key) {
      let newCargos: ICargoDocument[] = [];
      // Remove the previous item from cargos array and push modified item to it
      newCargos = props.cargos.filter((item) => item.key !== payload.key);
      newCargos.push(payload);

      props.setCargos(newCargos);

      props.closeModal();
      setPayload(new Cargo());
    }
    // In case no payload id exist
    // Create a new item
    else {
      props.setCargos([...props.cargos, transferCargo(payload)]);
      props.closeModal();
      setPayload(new Cargo());
    }
  };

  /**
   * Write newValue to state by element name
   * @param e
   * @param newValue
   */
  const handleOnChange = (e: any, newValue: any) => {
    const { name } = e.target;

    if (newValue === undefined) {
      setPayload({ ...payload, [name]: null });
    } else {
      const inputType: string = e.target.type;
      if (inputType === InputType.text || inputType === InputType.textarea) {
        setPayload({ ...payload, [name]: String(newValue) });
      } else if (inputType === InputType.number) {
        // Calculate the predefined values according to the amount
        if (name === attributes.amount.name && payload.good) {
          const newAmount: number = Number(
            !newValue || newValue < 1 ? 1 : newValue
          );

          const predefinedPrice = Math.trunc(payload.good.price * newAmount);
          const predefinedBruttoWeight = Math.trunc(
            payload.good.weight * newAmount
          );
          const predefinedNettoWeight =
            payload.good.weight === 0
              ? 0
              : Math.trunc(
                  (payload.good.weight - payload.good.tara) * newAmount
                );

          setPayload({
            ...payload,
            [name]: Number(newValue),
            customPrice: predefinedPrice,
            customBruttoWeight: predefinedBruttoWeight,
            customNettoWeight: predefinedNettoWeight,
          });
        } else {
          setPayload({
            ...payload,
            [name]: Number(newValue),
          });
        }
      } else if (inputType === InputType.checkbox) {
        setPayload({ ...payload, [name]: Boolean(newValue) });
      } else if (inputType === InputType.dropdown) {
        // Calculate the predefined values
        if (name === attributes.good.name) {
          const predefinedPrice = Math.trunc(newValue.price * payload.amount);
          const predefinedBruttoWeight = Math.trunc(
            newValue.weight * payload.amount
          );
          const predefinedNettoWeight =
            newValue.weight === 0
              ? 0
              : Math.trunc((newValue.weight - newValue.tara) * payload.amount);
          const predefinedCustomTitleRu = newValue.titleRu;
          const predefinedCustomTitleDe = newValue.titleDe;
          const predefinedCode = newValue.code;

          setPayload({
            ...payload,
            [name]: newValue,
            customPrice: predefinedPrice,
            customBruttoWeight: predefinedBruttoWeight,
            customNettoWeight: predefinedNettoWeight,
            customTitleRu: predefinedCustomTitleRu,
            customTitleDe: predefinedCustomTitleDe,
            customCode: predefinedCode,
          });
          // Good error handling
          if (newValue.id !== undefined) {
            setShowGoodSelectedError(false);
          }
        } else {
          setPayload({ ...payload, [name]: newValue });
        }
      }
    }
  };

  const isEditable: boolean =
    props.formMode === FormMode.create || props.formMode === FormMode.edit;

  return (
    <Modal
      titleAriaId={titleId}
      isOpen={props.isModalOpen}
      onDismiss={props.closeModal}
      isBlocking={false}
      containerClassName={contentStyles.container}
    >
      <div className={contentStyles.header}>
        <span id={titleId}>{formHeading} </span>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={props.closeModal}
        />
      </div>
      <div className={contentStyles.body}>
        {isEditable && props.isModalOpen ? (
          <form id={formName} onSubmit={onSubmit}>
            <DynamicFormCommandBar
              formMode={props.formMode}
              closeModal={props.closeModal}
              formName={formName}
            />
            <Dropdown
              required={true}
              id={buttonId}
              shouldSetDefaultOption={shouldSetDefaultGoodOption}
              isMulti={false}
              contextKey={contextSegments.good.contextKey}
              optionKey={contextSegments.good.displayAttribute}
              attributeName={attributes.good.name}
              label={attributes.good.label}
              setOption={setGoodOption}
              setShouldSetDefaultOption={setShouldSetDefaultGoodOption}
              showModal={() => setIsGoodModalOpen(true)}
              defaultOption={
                new Option(
                  String(payload.good?.titleDe),
                  String(payload.good?.id)
                )
              }
            />
            {showGoodSelectedError && (
              <ErrorCallout
                hideCallout={() => setShowGoodSelectedError(false)}
                buttonId={buttonId}
              />
            )}
            <Dropdown
              shouldSetDefaultOption={shouldSetDefaultGoodConditionOption}
              isMulti={false}
              contextKey={contextSegments.goodCondition.contextKey}
              optionKey={contextSegments.goodCondition.displayAttribute}
              attributeName={attributes.goodCondition.name}
              label={attributes.goodCondition.label}
              setOption={setGoodConditionOption}
              setShouldSetDefaultOption={setShouldSetDefaultGoodConditionOption}
              showModal={() => setIsGoodConditionModalOpen(true)}
              defaultOption={
                new Option(
                  String(payload.goodCondition?.title),
                  String(payload.goodCondition?.id)
                )
              }
            />
            <TextField
              name={attributes.customTitleDe.name}
              label={attributes.customTitleDe.label}
              type="text"
              onChange={handleOnChange}
              value={String(payload.customTitleDe ?? "")}
            />
            <TextField
              name={attributes.customTitleRu.name}
              label={attributes.customTitleRu.label}
              type="text"
              onChange={handleOnChange}
              value={String(payload.customTitleRu ?? "")}
            />
            <TextField
              name={attributes.amount.name}
              label={attributes.amount.label}
              type="number"
              min="1"
              onChange={handleOnChange}
              defaultValue={String(payload.amount)}
            />
            <TextField
              name={attributes.customNettoWeight.name}
              label={attributes.customNettoWeight.label}
              suffix="Kg"
              type="number"
              min="0"
              onChange={handleOnChange}
              value={String(payload.customNettoWeight ?? "")}
            />
            <TextField
              name={attributes.customBruttoWeight.name}
              label={attributes.customBruttoWeight.label}
              suffix="Kg"
              type="number"
              min="0"
              onChange={handleOnChange}
              value={String(payload.customBruttoWeight ?? "")}
            />
            <TextField
              name={attributes.customPrice.name}
              label={attributes.customPrice.label}
              type="number"
              suffix="€"
              min="0"
              onChange={handleOnChange}
              value={String(payload.customPrice ?? "")}
            />
            <TextField
              name={attributes.customCode.name}
              label={attributes.customCode.label}
              type="text"
              onChange={handleOnChange}
              value={String(payload.customCode ?? "")}
            />
          </form>
        ) : (
          <div>
            {props.cargoListFormMode === FormMode.view ? null : (
              <DynamicFormCommandBar
                formMode={props.formMode}
                formName={formName}
                setFormMode={props.setFormMode}
                closeModal={props.closeModal}
              />
            )}
            <Label styles={labelStyles}>{attributes.good.label}</Label>
            <span style={valueStyles}>{payload.good?.titleDe}</span>
            <Label styles={labelStyles}>{attributes.goodCondition.label}</Label>
            <span style={valueStyles}>{payload.goodCondition?.title}</span>
            <Label styles={labelStyles}>{attributes.customTitleDe.label}</Label>
            <span style={valueStyles}>{payload.customTitleDe}</span>
            <Label styles={labelStyles}>{attributes.customTitleRu.label}</Label>
            <span style={valueStyles}>{payload.customTitleRu}</span>
            <Label styles={labelStyles}>{attributes.amount.label}</Label>
            <span style={valueStyles}>{payload.amount}</span>
            <Label styles={labelStyles}>
              {attributes.customNettoWeight.label}
            </Label>
            <span style={valueStyles}>{payload.customNettoWeight}</span>
            <Label styles={labelStyles}>
              {attributes.customBruttoWeight.label}
            </Label>
            <span style={valueStyles}>{payload.customBruttoWeight}</span>
            <Label styles={labelStyles}>{attributes.customPrice.label}</Label>
            <span style={valueStyles}>{payload.customPrice} €</span>
            <Label styles={labelStyles}>{attributes.customCode.label}</Label>
            <span style={valueStyles}>{payload.customCode}</span>
          </div>
        )}
      </div>
      {/* Additional Forms */}
      <GoodForm
        isModalOpen={isGoodModalOpen}
        formMode={goodFormMode}
        selectedItem={selectedGoodItem}
        name={attributes.good.name}
        setFormMode={setGoodFormMode}
        closeModal={closeGoodModal}
        setCreatedItem={setGoodOption}
      />
      <GoodConditionForm
        isModalOpen={isGoodConditionModalOpen}
        formMode={goodConditionFormMode}
        selectedItem={selectedGoodConditionItem}
        fkName={attributes.goodCondition.name}
        setFormMode={setGoodConditionFormMode}
        closeModal={closeGoodConditionModal}
        setCreatedItem={setGoodConditionOption}
      />
    </Modal>
  );
};

export default CargoForm;
