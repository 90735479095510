/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-else-return */
import {
  DetailsList,
  DetailsListLayoutMode,
  Fabric,
  IColumn,
  ITextProps,
  Label,
  MarqueeSelection,
  Selection,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Text,
} from "@fluentui/react";
import * as React from "react";
import { ApiService } from "../../../../../services/api.service";
import { HelperService } from "../../../../../services/helper.service";
import { FormMode } from "../../../../Controls/context-segments";
import { DynamicListCommandBar } from "../../../../Controls/DetailsList/DynamicListCommandBar";
import DeleteDialog from "../../../../Controls/Dialogs/DeleteDialog";
import { ICargoDocument } from "../cargo.type";
import "./CargoDetailsList.scss";

export interface IState {
  items: any[];
  hideDeleteDialog: boolean;
  isLoading: boolean;
  isFiltering: boolean;
}

interface IProps {
  columns: IColumn[];
  title: string;
  cargoListFormMode: FormMode;
  items: any[];
  cargos: ICargoDocument[];
  showModal: () => void;
  openItem: (itemKey: string) => void;
  setCargos: (newCargos: ICargoDocument[]) => void;
}

export class CargoDetailsList extends React.Component<IProps, IState> {
  private selection: Selection;

  public apiCalls: any = ApiService.getInstance();

  public helperService: any = HelperService.getInstance();

  constructor(props: IProps) {
    super(props);

    this.selection = new Selection();

    this.state = {
      items: [],
      hideDeleteDialog: true,
      isLoading: true,
      isFiltering: false,
    };
    this.toggleHideDeleteDialog = this.toggleHideDeleteDialog.bind(this);
    this.deleteSelectedItems = this.deleteSelectedItems.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
  }

  componentDidMount() {
    // Set items to state
    if (this.props.items.length >= 0) {
      this.setState({ items: this.props.items, isLoading: false });
    }
  }

  static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
    // Loading interaction
    if (prevState.isLoading && prevState.items.length > 0) {
      return {
        isLoading: false,
      };
    }

    // Usual cargo update behavior
    if (
      !prevState.isFiltering &&
      JSON.stringify(nextProps.items) !== JSON.stringify(prevState.items)
    ) {
      return {
        items: nextProps.items,
        isLoading: false,
      };
    }

    // Does not update the state, if no condition matched
    return null;
  }

  /**
   * Search for items by text
   * @param event
   * @param search Current input value
   */
  public onChangeSearch = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    search?: string
  ): void => {
    const { items } = this.props;
    const filterKeys = [
      "customTitleRu",
      "customTitleDe",
      "good",
      "amount",
      "customNettoWeight",
      "customBruttoWeight",
      "customPrice",
    ];
    if (search) {
      search.toLowerCase();
      const result = items.filter(
        (v, i) =>
          v[filterKeys[0]]?.toString().toLowerCase().includes(search) ||
          v[filterKeys[1]]?.toString().toLowerCase().includes(search) ||
          v[filterKeys[2]]?.toString().toLowerCase().includes(search) ||
          v[filterKeys[3]]?.toString().toLowerCase().includes(search) ||
          v[filterKeys[4]]?.toString().toLowerCase().includes(search) ||
          v[filterKeys[5]]?.toString().toLowerCase().includes(search) ||
          v[filterKeys[6]]?.toString().toLowerCase().includes(search)
      );

      this.setState({
        items: result,
        isFiltering: true,
      });
    } else {
      this.setState({
        items,
        isFiltering: false,
      });
    }
  };

  /**
   * Does execute delete action
   */
  public deleteSelectedItems(): void {
    const selectedItems: any[] = this.selection.getSelection();
    const currentItems: any[] = JSON.parse(JSON.stringify(this.state.items));
    // Subtract selected items from all items in the list
    const leftoverItems: any[] = currentItems.filter(
      (ci) => !selectedItems.map((si) => si.key).includes(ci.key)
    );

    const leftoverCargos = this.props.cargos.filter((c) =>
      leftoverItems.some((l) => l.key === c.key)
    );

    this.props.setCargos(leftoverCargos);
    this.setState({ hideDeleteDialog: true });

    this.selection.setAllSelected(false);
  }

  public toggleHideDeleteDialog(): void {
    if (this.selection.getSelectedCount() > 0) {
      this.setState({
        hideDeleteDialog: !this.state.hideDeleteDialog,
      });
    }
  }

  public render() {
    const { items, isLoading, hideDeleteDialog } = this.state;
    const { title, cargoListFormMode } = this.props;
    return (
      <Fabric>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text variant={"xxLarge" as ITextProps["variant"]}>{title}</Text>
          <div style={cargoListFormMode ? { margin: "0 0 0 -25px" } : {}}>
            <DynamicListCommandBar
              reload={true}
              cargoListFormMode={cargoListFormMode}
              onChangeSearch={this.onChangeSearch}
              toggleHideDeleteDialog={this.toggleHideDeleteDialog}
              showModal={this.props.showModal}
            />
            <DeleteDialog
              hideDeleteDialog={hideDeleteDialog}
              toggleHideDeleteDialog={this.toggleHideDeleteDialog}
              selectedItemsCount={this.selection.getSelectedCount()}
              deleteSelectedItems={this.deleteSelectedItems}
            />
          </div>
        </div>
        {isLoading ? (
          <div className="loading-container">
            <div>
              <Label>Wird geladen...</Label>
              <Spinner size={SpinnerSize.large} />
            </div>
          </div>
        ) : cargoListFormMode !== FormMode.view ? (
          <div>
            <MarqueeSelection selection={this.selection}>
              <DetailsList
                items={items}
                columns={this.props.columns}
                selectionMode={SelectionMode.multiple}
                setKey="multiple"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                selection={this.selection}
                selectionPreservedOnEmptyClick={true}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="Row checkbox"
                onRenderRow={this.helperService.onRenderRow}
                onRenderItemColumn={(
                  item?: any,
                  index?: number | undefined,
                  column?: IColumn | undefined
                ) =>
                  this.helperService.renderItemColumn(
                    item,
                    index,
                    column,
                    this.props.openItem,
                    true
                  )
                }
                getKey={this.helperService.getKey}
              />
            </MarqueeSelection>
          </div>
        ) : (
          <DetailsList
            items={items}
            columns={this.props.columns}
            selectionMode={SelectionMode.none}
            setKey="multiple"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            selection={this.selection}
            selectionPreservedOnEmptyClick={true}
            enterModalSelectionOnTouch={true}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="Row checkbox"
            onRenderRow={this.helperService.onRenderRow}
            onRenderItemColumn={(
              item?: any,
              index?: number | undefined,
              column?: IColumn | undefined
            ) =>
              this.helperService.renderItemColumn(
                item,
                index,
                column,
                this.props.openItem,
                true
              )
            }
            getKey={this.helperService.getKey}
          />
        )}
      </Fabric>
    );
  }
}
