import {
  IconButton,
  ITextProps,
  Label,
  Modal,
  Text,
  TextField,
} from "@fluentui/react";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { ApiService } from "../../../services/api.service";
import { HelperService } from "../../../services/helper.service";
import {
  cancelIcon,
  contentStyles,
  iconButtonStyles,
  labelStyles,
  valueStyles,
} from "../../Controls/component-styles";
import {
  contextSegments,
  FormMode,
  InputType,
} from "../../Controls/context-segments";
import DeleteDialog from "../../Controls/Dialogs/DeleteDialog";
import { DynamicFormCommandBar } from "../../Controls/DynamicFormCommandBar/DynamicFormCommandBar";
import { attributes, ITextDocument, Text as TextClass } from "./text.type";

interface IProps {
  isModalOpen: boolean;
  selectedItem: ITextDocument | null;
  formMode: FormMode;
  name?: string;
  closeModal: () => void;
  setFormMode: (formMode: FormMode) => void;
  setReload?: (reload: boolean) => void;
  setCreatedItem?: (e: any, newItem: any) => void;
}
const helperService = HelperService.getInstance();

const apiCalls: ApiService = ApiService.getInstance();
const formName: string = "text-form";

const TextForm = (props: IProps) => {
  const formHeading: string = helperService.getFormHeading(
    contextSegments.text.singularName,
    props.formMode
  );

  const [payload, setPayload] = useState<ITextDocument>(new TextClass());
  const [hideDeleteDialog, toggleHideDeleteDialog] = useState<boolean>(true);

  useEffect(() => {
    // In case some item was passed to the form
    // Set this item to the payload
    if (props.selectedItem) {
      setPayload(props.selectedItem);
    }
    // In case no item was passed to the form
    // Set default empty payload
    else {
      setPayload(new TextClass());
    }
    // Clear the input when closing the modal
    if (!props.isModalOpen) {
      setPayload(new TextClass());
    }
  }, [props.selectedItem, props.isModalOpen]);

  const titleId = helperService.makeId(10);

  /**
   * Invoke create action
   * @param e
   */
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // In case payload id exist
    // Update the item
    if (payload.id) {
      apiCalls
        .updateText(payload, payload.id)
        .then((res: AxiosResponse<any>) => {
          props.setReload && props.setReload(true);

          props.closeModal();
          setPayload(new TextClass());
        });
    }
    // In case no payload id exist
    // Create a new item
    else {
      apiCalls.createText(payload).then((res: AxiosResponse<any>) => {
        //   In case creating an item through the dropdown context
        if (props.setCreatedItem && props.name && res.data.id) {
          const fakeEvent = {
            target: {
              name: props.name,
              type: "dropdown",
            },
          };

          const newItem: ITextDocument = {
            id: res.data["id"],
            topicDe: res.data["topicDe"],
            topicRu: res.data["topicRu"],
            de: res.data["de"],
            ru: res.data["ru"],
          };

          props.setCreatedItem(fakeEvent, newItem);
        }
        props.setReload && props.setReload(true);

        props.closeModal();
        setPayload(new TextClass());
      });
    }
  };

  /**
   * Write newValue to state by element name
   * @param e
   * @param newValue
   */
  const handleOnChange = (e: any, newValue: any) => {
    const { name } = e.target;

    if (newValue === undefined) {
      setPayload({ ...payload, [name]: null });
    } else {
      const inputType: string = e.target.type;
      if (inputType === InputType.text || inputType === InputType.textarea) {
        setPayload({ ...payload, [name]: String(newValue) });
      } else if (inputType === InputType.number) {
        setPayload({ ...payload, [name]: Number(newValue) });
      } else if (inputType === InputType.checkbox) {
        setPayload({ ...payload, [name]: Boolean(newValue) });
      } else if (inputType === InputType.dropdown) {
        setPayload({ ...payload, [name]: newValue });
      }
    }
  };
  const isEditable: boolean =
    props.formMode === FormMode.create || props.formMode === FormMode.edit;
  const deleteOpenedItem = async () => {
    toggleHideDeleteDialog(true);
    props.closeModal();
    await apiCalls.deleteText(props.selectedItem!.id!);
    props.setReload && props.setReload(true);
  };

  return (
    <Modal
      titleAriaId={titleId}
      isOpen={props.isModalOpen}
      onDismiss={props.closeModal}
      isBlocking={false}
      containerClassName={contentStyles.container}
    >
      <DeleteDialog
        toggleHideDeleteDialog={toggleHideDeleteDialog}
        hideDeleteDialog={hideDeleteDialog}
        selectedItemsCount={1}
        deleteSelectedItems={deleteOpenedItem}
      />
      <div className={contentStyles.header}>
        <Text id={titleId} variant={"xxLarge" as ITextProps["variant"]}>
          {formHeading}
        </Text>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={props.closeModal}
        />
      </div>
      <div className={contentStyles.body}>
        {isEditable && props.isModalOpen ? (
          <form id={formName} onSubmit={onSubmit}>
            <DynamicFormCommandBar
              formMode={props.formMode}
              toggleHideDeleteDialog={toggleHideDeleteDialog}
              closeModal={props.closeModal}
              formName={formName}
            />
            <TextField
              required
              name={attributes.topicDe.name}
              label={attributes.topicDe.label}
              type="text"
              onChange={handleOnChange}
              defaultValue={payload.topicDe}
            />
            <TextField
              required
              name={attributes.topicRu.name}
              label={attributes.topicRu.label}
              type="text"
              onChange={handleOnChange}
              defaultValue={payload.topicRu}
            />
            <TextField
              name={attributes.de.name}
              label={attributes.de.label}
              type="text"
              rows={8}
              multiline
              resizable={false}
              onChange={handleOnChange}
              defaultValue={payload.de}
            />
            <TextField
              name={attributes.ru.name}
              label={attributes.ru.label}
              type="text"
              rows={8}
              multiline
              resizable={false}
              onChange={handleOnChange}
              defaultValue={payload.ru}
            />
          </form>
        ) : (
          <div>
            <DynamicFormCommandBar
              formMode={props.formMode}
              toggleHideDeleteDialog={toggleHideDeleteDialog}
              formName={formName}
              setFormMode={props.setFormMode}
              closeModal={props.closeModal}
            />
            <Label styles={labelStyles}>{attributes.topicDe.label}</Label>
            <span style={valueStyles}>{payload.topicDe}</span>
            <Label styles={labelStyles}>{attributes.topicRu.label}</Label>
            <span style={valueStyles}>{payload.topicRu}</span>
            <Label styles={labelStyles}>{attributes.de.label}</Label>
            <span style={valueStyles}>{payload.de}</span>
            <Label styles={labelStyles}>{attributes.ru.label}</Label>
            <span style={valueStyles}>{payload.ru}</span>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default TextForm;
