import {
  IDriverDTO,
  transferDTO as transferDriverDTO,
} from "../Driver/driver.dto";
import { ITextDTO } from "../Text/text.dto";
import { ICargoDTO, transferDTO as transferCargoDTO } from "./Cargo/cargo.dto";
import { ICargoDocument } from "./Cargo/cargo.type";
import { ICargolistDocument } from "./cargolist.type";

export interface ICargolistDTO {
  id?: number;
  displayNumber: string;
  addressId: number | null;
  goodInfoId: number | null;
  loadingLocationId: number | null;
  vehicleId: number | null;
  trailerId: number | null;
  tourId: number | null;
  drivers: IDriverDTO[] | null;
  certificateContent: ITextDTO[] | null;
  listContent: ITextDTO[] | null;
  cargos: ICargoDTO[] | null;
  aeTrafficBranch: number;
  date: string | null;
  seal: string;
  transported: string;
  sent: boolean;
  sign: boolean;
  layout: boolean;
  commodityCode: boolean;
  transportCosts: number;
  notes: string;
  packType: string;
  registrationType: string;
}

export const transferDTO = (
  item: ICargolistDocument,
  cargos: ICargoDocument[]
): ICargolistDTO => ({
  id: item.id,
  displayNumber: item.displayNumber,
  addressId: item.address?.id ? item.address?.id : null,
  goodInfoId: item.goodInfo?.id ? item.goodInfo?.id : null,
  loadingLocationId: item.loadingLocation?.id ? item.loadingLocation?.id : null,
  vehicleId: item.vehicle?.id ? item.vehicle?.id : null,
  trailerId: item.trailer?.id ? item.trailer?.id : null,
  tourId: item.tour?.id ? item.tour?.id : null,
  aeTrafficBranch: item.aeTrafficBranch,
  seal: item.seal,
  sent: item.sent,
  sign: item.sign,
  layout: item.layout,
  commodityCode: item.commodityCode,
  transported: item.transported,
  date: item.date ? item.date : null,
  drivers: item.drivers.map((d) => transferDriverDTO(d)),
  certificateContent: item.certificateContent,
  listContent: item.listContent,
  cargos: cargos.map((x) => transferCargoDTO(x)),
  transportCosts: item.transportCosts,
  notes: item.notes,
  packType: item.packType,
  registrationType: item.registrationType,
});

export const transferItems = (
  item: ICargolistDocument,
  index: number
): any => ({
  key: `key-${index}`,
  id: item.id,
  displayNumber: item.displayNumber,
  tour: item.tour?.title,
  address: item.address
    ? `${item.address?.countryDe}, ${item.address?.cityDe} ${item.address?.zip}, ${item.address?.titleDe}`
    : "",
  goodInfo: item.goodInfo?.commodityNumber,
  loadingLocation: item.loadingLocation
    ? `${item.loadingLocation?.city} ${item.loadingLocation?.zip || ""}`
    : undefined,
  vehicle: item.vehicle?.vehicleType
    ? `${item.vehicle?.vehicleType?.titleDe} ${item.vehicle?.licensePlate}`
    : "",
  trailer: item.trailer?.trailerType
    ? `${item.trailer?.trailerType?.titleDe} ${item.trailer?.licensePlate}`
    : "",
  aeTrafficBranch: item.aeTrafficBranch,
  date: item.date,
  seal: item.seal,
  sent: item.sent,
  sign: item.sign,
  layout: item.layout,
  commodityCode: item.commodityCode,
  transported: item.transported,
  transportCosts: item.transportCosts,
  notes: item.notes,
  packType: item.packType,
  registrationType: item.registrationType,
});
