import { IGoodInfoDocument } from './good-info.type';

export interface IGoodInfoDTO {
  id?: number;
  commodityNumber: string;
  approvalNumber: string;
  referenceNumber: string;
  issueDate: string;
}

export const transferDTO = (item: IGoodInfoDocument): IGoodInfoDTO => ({
  id: item.id,
  commodityNumber: item.commodityNumber,
  approvalNumber: item.commodityNumber,
  referenceNumber: item.commodityNumber,
  issueDate: item.commodityNumber,
});

export const transferItems = (item: IGoodInfoDocument, index: number): any => ({
  key: `key-${index}`,
  id: item.id,
  commodityNumber: item.commodityNumber,
  approvalNumber: item.approvalNumber,
  referenceNumber: item.referenceNumber,
  issueDate: item.issueDate,
});
