import {
  FontWeights,
  getTheme,
  ICheckboxStyles,
  IIconProps,
  ITextFieldStyleProps,
  ITextFieldStyles,
  mergeStyleSets,
} from "@fluentui/react";

export const theme = getTheme();

export const labelStyles = { root: { color: "bold", fontSize: "18px" } };
export const valueStyles = {
  fontFamily:
    "Segoe UI, Segoe UI Web (West European), Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif",
  fontSize: "18px",
  fontWeight: 400,
  display: "inline-block",
  minHeight: 10,
};
export const cancelIcon: IIconProps = { iconName: "Cancel" };
export const saveIcon: IIconProps = { iconName: "Save" };
export const exportIcon: IIconProps = { iconName: "DownloadDocument" };
export const editIcon: IIconProps = { iconName: "Edit" };
export const documentIcon: IIconProps = { iconName: "TextDocument" };
export const wordDocumentIcon: IIconProps = { iconName: "WordDocument" };
export const certificateIcon: IIconProps = { iconName: "Certificate" };
export const filterIcon: IIconProps = { iconName: "FilterSettings" };
export const printIcon: IIconProps = { iconName: "Print" };
export const clearIcon: IIconProps = { iconName: "Clear" };
export const addIcon: IIconProps = { iconName: "Add" };
export const saveAndCloseIcon: IIconProps = { iconName: "SaveAndClose" };
export const deLanguageIcon: IIconProps = { iconName: "LocaleLanguage" };
export const assignIcon: IIconProps = { iconName: "Assign" };
export const customsIcon: IIconProps = { iconName: "PeopleRepeat" };
export const downloadIcon: IIconProps = { iconName: "Download" };
export const customsRegistrationIcon: IIconProps = {
  iconName: "InsertSignatureLine",
};
export const ruLanguageIcon: IIconProps = {
  iconName: "MicrosoftTranslatorLogo",
};
export const leaveIcon: IIconProps = { iconName: "Leave" };
export const invoiceIcon: IIconProps = { iconName: "Assign" };
export const mautIcon: IIconProps = { iconName: "InsertSignatureLine" };
export const billingIcon: IIconProps = { iconName: "EntitlementPolicy" };
export const mailIcon: IIconProps = { iconName: "Mail" };
export const customMailIcon: IIconProps = { iconName: "EditMail" };
export const tollCollectIcon: IIconProps = { iconName: "MailForward" };
export const deleteIcon: IIconProps = { iconName: "Delete" };
export const listIcon: IIconProps = { iconName: "Print" };
export const cmrIcon: IIconProps = { iconName: "ReportDocument" };
export const zipIcon: IIconProps = { iconName: "ZipFolder" };

export const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: "1 1 auto",
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px",
    },
  ],
  body: {
    flex: "4 4 auto",
    width: "500px",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
});

export const calloutStyles = mergeStyleSets({
  callout: {
    width: 300,
    padding: "10px 14px",
  },
});

export const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

export const twoGroupedForm = {
  container: {
    display: "flex",
  },
  leftGroup: {
    margin: "5px",
    flex: "1 1 50%",
  },
  rightGroup: {
    margin: "5px",
    flex: "1 1 50%",
  },
};

export const textFieldStyles = (
  stylePprops: ITextFieldStyleProps
): Partial<ITextFieldStyles> => ({
  fieldGroup: [{ height: 38 }],
});

export const wideFormContentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: "1 1 auto",
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px",
    },
  ],
  body: {
    flex: "4 4 auto",
    width: "1200px",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
});

export const checkboxStyles: ICheckboxStyles = {
  checkbox: {
    width: "32px",
    height: "32px",
  },
  text: {
    margin: "6px 0 0 0",
  },
};
