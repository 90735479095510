export const attributes = {
  title: {
    label: 'Titel',
    name: 'title',
  },
  indicationDe: {
    label: 'Kurzbezeichnung - Deutsch',
    name: 'indicationDe',
  },
  indicationRu: {
    label: 'Kurzbezeichnung - Russisch',
    name: 'indicationRu',
  },
};

export interface IGoodConditionDocument {
  id?: number;
  title: string;
  indicationDe: string;
  indicationRu: string;
}

export class GoodCondition implements IGoodConditionDocument {
  title = '';

  indicationDe = '';

  indicationRu = '';
}
