import { DatePicker, IconButton, Label } from "@fluentui/react";
import moment from "moment";
import "moment/locale/de";
import React, { useState } from "react";
import { clearIcon } from "../component-styles";
import { DayPickerStrings } from "./DatePickerConfig";

moment.locale("de");
interface IProps {
  // eslint-disable-next-line react/no-unused-prop-types
  isRequired: boolean;
  defaultValue: string | null;
  label: string;
  name: string;
  handleOnChange: (e: any, newValue: any) => void;
}
const DynamicDatePicker = (props: IProps) => {
  // Own state
  const [date, setDate] = useState<Date | null | undefined>(
    props.defaultValue ? new Date(props.defaultValue) : undefined
  );

  /**
   * Format the given param date and return it.
   * @param currentDate
   */
  const formatDate = (currentDate: Date | undefined): string => {
    if (currentDate) {
      return moment(currentDate).format("L");
    }
    return "";
  };

  const onSelectedDate = (newDate: Date | null | undefined) => {
    const fakeEvent = {
      target: {
        name: props.name,
        type: "datepicker",
      },
    };
    if (newDate) {
      const formattedDate = moment(newDate).format("YYYY-MM-DD");
      setDate(newDate);
      props.handleOnChange(fakeEvent, formattedDate);
    } else {
      setDate(null);
      props.handleOnChange(fakeEvent, undefined);
    }
  };

  const resetDatePicker = (): void => {
    onSelectedDate(null);
  };

  const onParseDateFromString = (val: string): Date => {
    const currentDate = date || new Date();
    const values = (val || "").trim().split(".");
    const day =
      val.length > 0
        ? Math.max(1, Math.min(31, parseInt(values[0], 10)))
        : currentDate.getDate();
    const month =
      val.length > 1
        ? Math.max(1, Math.min(12, parseInt(values[1], 10))) - 1
        : currentDate.getMonth();
    let year =
      val.length > 2 ? parseInt(values[2], 10) : currentDate.getFullYear();
    if (year < 100) {
      year += currentDate.getFullYear() - (currentDate.getFullYear() % 100);
    }
    return new Date(year, month, day);
  };

  return (
    <div>
      <div>
        <Label>{props.label}</Label>
      </div>
      <div>
        <div>
          <div style={{ display: "flex" }}>
            <div style={{ flex: "1 1 90%" }}>
              <DatePicker
                strings={DayPickerStrings}
                placeholder="Datum auswählen..."
                allowTextInput={true}
                formatDate={formatDate}
                initialPickerDate={date || undefined}
                value={date || undefined}
                onSelectDate={onSelectedDate}
                parseDateFromString={onParseDateFromString}
              />
            </div>
            <IconButton
              className="date-picker-reset-button"
              style={{ marginLeft: "5px", flex: "1 1 10%" }}
              onClick={resetDatePicker}
              iconProps={{ ...clearIcon, style: { fontSize: "15px" } }}
              title="Datum zurücksetzen"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicDatePicker;
