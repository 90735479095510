import { IDatePickerStrings } from "@fluentui/react";

export const DayPickerStrings: IDatePickerStrings = {
  months: [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ],

  shortMonths: [
    "Jan",
    "Feb",
    "Mär",
    "Apr",
    "Mai",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Okt",
    "Nov",
    "Dez",
  ],

  days: [
    "Sonntag",
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
  ],

  shortDays: ["S", "M", "D", "M", "D", "F", "S"],

  goToToday: "Springe zu heute",
  prevMonthAriaLabel: "Springe zum letzten Monat",
  nextMonthAriaLabel: "Springe zum nächsten Monat",
  prevYearAriaLabel: "Springe zum letzten Jahr",
  nextYearAriaLabel: "Springe zum nächsten Jahr",
  closeButtonAriaLabel: "Schließen",
};
