import { ITrailerDocument } from './trailer.type';

export interface ITrailerDTO {
  id?: number;
  licensePlate: string;
  payload: number;
  countryCode: string;
  trailerTypeId: number | null;
}

export const transferDTO = (item: ITrailerDocument): ITrailerDTO => ({
  id: item.id,
  licensePlate: item.licensePlate,
  payload: item.payload,
  countryCode: item.countryCode,
  trailerTypeId: item.trailerType?.id ? item.trailerType.id : null,
});

export const transferItems = (item: ITrailerDocument, index: number) => ({
  key: `key-${index}`,
  id: item.id,
  licensePlate: item.licensePlate,
  payload: item.payload,
  countryCode: item.countryCode,
  trailerType: item.trailerType?.titleDe,
});
