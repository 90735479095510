import { IDriverDocument } from "./driver.type";

export interface IDriverDTO {
  id?: number;
  name: string;
  churchId: number | null;
  phone: string;
  mail: string;
  driverType: string;
}

export const transferDTO = (item: IDriverDocument): IDriverDTO => ({
  id: item.id,
  name: item.name,
  churchId: item.church?.id ? item.church?.id : null,
  phone: item.phone,
  mail: item.mail,
  driverType: item.driverType,
});

export const transferItems = (item: IDriverDocument, index: number): any => ({
  key: `key-${index}`,
  id: item.id,
  name: item.name,
  church: item.church?.title,
  phone: item.phone,
  mail: item.mail,
  driverType: item.driverType,
  rides: item.rides,
});
