/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/no-unused-prop-types */
import {
  ActionButton,
  CommandBar,
  IComboBoxOption,
  ICommandBarItemProps,
} from "@fluentui/react";
import { useEffect } from "react";
import { ApiService } from "../../../services/api.service";
import { HelperService } from "../../../services/helper.service";
import { IAddressDocument } from "../../Pages/Address/address.type";
import {
  assignIcon,
  billingIcon,
  cancelIcon,
  certificateIcon,
  cmrIcon,
  customMailIcon,
  deLanguageIcon,
  deleteIcon,
  documentIcon,
  downloadIcon,
  editIcon,
  exportIcon,
  invoiceIcon,
  listIcon,
  mailIcon,
  mautIcon,
  ruLanguageIcon,
  saveIcon,
  tollCollectIcon,
  wordDocumentIcon,
  zipIcon,
} from "../component-styles";
import { DocType, FormMode, Format, Lang } from "../context-segments";

const apiCalls: ApiService = ApiService.getInstance();
const helperService: HelperService = HelperService.getInstance();

const commandBar = {
  root: {
    marginLeft: -30,
  },
};

interface IProps {
  formMode: FormMode;
  formName: string;
  entity?: IComboBoxOption;
  cargolistId?: number;
  fileName?: string;
  cargoReceiver?: IAddressDocument;
  tollCollectMail?: string;
  closeModal: () => void;
  setFormMode?: (formMode: FormMode) => void;
  toggleHideDeleteDialog?: (flag: boolean) => void;
}

export const DynamicFormCommandBar = (props: IProps) => {
  const items: ICommandBarItemProps[] = [
    props.setFormMode
      ? {
          key: helperService.makeId(10),
          onRender: () => (
            <ActionButton
              onClick={() =>
                props.setFormMode && props.setFormMode(FormMode.edit)
              }
              iconProps={editIcon}
            >
              Bearbeiten
            </ActionButton>
          ),
        }
      : props.entity
      ? {
          key: helperService.makeId(10),
          onRender: () => (
            <ActionButton
              onClick={() => apiCalls.downloadCsv(props.entity!)}
              iconProps={exportIcon}
            >
              Exportieren
            </ActionButton>
          ),
        }
      : {
          key: helperService.makeId(10),
          text: "Speichern",
          iconProps: saveIcon,
          onRender: () => (
            <ActionButton
              form={props.formName}
              type="submit"
              iconProps={saveIcon}
            >
              Speichern
            </ActionButton>
          ),
        },
    {
      key: helperService.makeId(10),
      onRender: () => (
        <ActionButton onClick={props.closeModal} iconProps={cancelIcon}>
          Abbrechen
        </ActionButton>
      ),
    },
  ];

  const overflowItems: ICommandBarItemProps[] =
    props.formName === "cargo-form"
      ? []
      : props.formMode === FormMode.edit || props.formMode === FormMode.view
      ? [
          {
            key: helperService.makeId(10),
            onRender: () => (
              <ActionButton
                onClick={() => {
                  props.toggleHideDeleteDialog &&
                    props.toggleHideDeleteDialog(false);
                }}
                iconProps={deleteIcon}
              >
                Löschen
              </ActionButton>
            ),
          },
        ]
      : [];

  // Print actions
  const farItems: any[] = props.cargolistId
    ? [
        {
          key: helperService.makeId(10),
          iconProps: mailIcon,
          text: "E-Mail",
          subMenuProps: {
            items: [
              {
                key: helperService.makeId(10),
                iconProps: tollCollectIcon,
                text: "Toll Collect",
                title: `E-Mail senden an: ${props.tollCollectMail!}`,
                href: `mailto:${props.tollCollectMail!}`,
              },
              {
                key: helperService.makeId(10),
                iconProps: customMailIcon,
                text: "Ladungsempfänger",
                title: `E-Mail senden an: ${
                  props.cargoReceiver ? props.cargoReceiver.mail : ""
                }`,
                href: `mailto:${
                  props.cargoReceiver ? props.cargoReceiver.mail : ""
                }`,
              },
            ],
          },
        },
        {
          key: helperService.makeId(10),
          iconProps: assignIcon,
          text: "Anträge",
          subMenuProps: {
            items: [
              //   {
              //     key: helperService.makeId(10),
              //     iconProps: customsIcon,
              //     text: "Zoll",
              //     subMenuProps: {
              //       items: [
              //         {
              //           key: helperService.makeId(10),
              //           onRender: () => (
              //             <ActionButton
              //               title="XML Datei für die Zollanmeldung herunterladen"
              //               onClick={() =>
              //                 apiCalls.getDocument(
              //                   props.cargolistId!,
              //                   DocType.customs,
              //                   Format.xml,
              //                   props.fileName!
              //                 )
              //               }
              //               iconProps={downloadIcon}
              //             >
              //               Zollanmeldung - XML
              //             </ActionButton>
              //           ),
              //         },
              //       ],
              //     },
              //   },
              {
                key: helperService.makeId(10),
                iconProps: invoiceIcon,
                text: "Ausgabebeleg",
                subMenuProps: {
                  items: [
                    {
                      key: helperService.makeId(10),
                      onRender: () => (
                        <ActionButton
                          title="Ausgabebeleg in PDF Format herunterladen"
                          onClick={() =>
                            apiCalls.getDocument(
                              props.cargolistId!,
                              DocType.invoice,
                              Format.pdf,
                              props.fileName!
                            )
                          }
                          iconProps={documentIcon}
                        >
                          Ausgabebeleg - PDF
                        </ActionButton>
                      ),
                    },
                    {
                      key: helperService.makeId(10),
                      onRender: () => (
                        <ActionButton
                          title="Ausgabebeleg in DOCX Format herunterladen"
                          onClick={() =>
                            apiCalls.getDocument(
                              props.cargolistId!,
                              DocType.invoice,
                              Format.docx,
                              props.fileName!
                            )
                          }
                          iconProps={wordDocumentIcon}
                        >
                          Ausgabebeleg - DOCX
                        </ActionButton>
                      ),
                    },
                  ],
                },
              },
              {
                key: helperService.makeId(10),
                iconProps: billingIcon,
                text: "Transport Abrechnung",
                subMenuProps: {
                  items: [
                    {
                      key: helperService.makeId(10),
                      onRender: () => (
                        <ActionButton
                          title="Transport Abrechnung in PDF Format herunterladen"
                          onClick={() =>
                            apiCalls.getDocument(
                              props.cargolistId!,
                              DocType.billing,
                              Format.pdf,
                              props.fileName!
                            )
                          }
                          iconProps={documentIcon}
                        >
                          Transport Abrechnung - PDF
                        </ActionButton>
                      ),
                    },
                    {
                      key: helperService.makeId(10),
                      onRender: () => (
                        <ActionButton
                          title="Transport Abrechnung in DOCX Format herunterladen"
                          onClick={() =>
                            apiCalls.getDocument(
                              props.cargolistId!,
                              DocType.billing,
                              Format.docx,
                              props.fileName!
                            )
                          }
                          iconProps={wordDocumentIcon}
                        >
                          Transport Abrechnung - DOCX
                        </ActionButton>
                      ),
                    },
                  ],
                },
              },
              {
                key: helperService.makeId(10),
                iconProps: mautIcon,
                text: "Mautbefreiung",
                subMenuProps: {
                  items: [
                    {
                      key: helperService.makeId(10),
                      onRender: () => (
                        <ActionButton
                          title="Mautbefreiung in PDF Format herunterladen"
                          onClick={() =>
                            apiCalls.getDocument(
                              props.cargolistId!,
                              DocType.maut,
                              Format.pdf,
                              props.fileName!
                            )
                          }
                          iconProps={documentIcon}
                        >
                          Mautbefreiung - PDF
                        </ActionButton>
                      ),
                    },
                    {
                      key: helperService.makeId(10),
                      onRender: () => (
                        <ActionButton
                          title="Mautbefreiung in DOCX Format herunterladen"
                          onClick={() =>
                            apiCalls.getDocument(
                              props.cargolistId!,
                              DocType.maut,
                              Format.docx,
                              props.fileName!
                            )
                          }
                          iconProps={wordDocumentIcon}
                        >
                          Mautbefreiung - DOCX
                        </ActionButton>
                      ),
                    },
                  ],
                },
              },
            ],
          },
        },
        {
          key: helperService.makeId(10),
          iconProps: certificateIcon,
          text: "Zertifikat",
          subMenuProps: {
            items: [
              {
                key: helperService.makeId(10),
                iconProps: deLanguageIcon,
                text: "Sprache - Deutsch",
                subMenuProps: {
                  items: [
                    {
                      key: helperService.makeId(10),
                      onRender: () => (
                        <ActionButton
                          title="Deutsches Zertifikat in PDF Format ausdrucken"
                          onClick={() =>
                            apiCalls.getDocument(
                              props.cargolistId!,
                              DocType.cert,
                              Format.pdf,
                              props.fileName!,
                              Lang.de
                            )
                          }
                          iconProps={documentIcon}
                        >
                          Format - PDF
                        </ActionButton>
                      ),
                    },
                    {
                      key: helperService.makeId(10),
                      onRender: () => (
                        <ActionButton
                          title="Deutsches Zertifikat in DOCX Format herunterladen"
                          onClick={() =>
                            apiCalls.getDocument(
                              props.cargolistId!,
                              DocType.cert,
                              Format.docx,
                              props.fileName!,
                              Lang.de
                            )
                          }
                          iconProps={wordDocumentIcon}
                        >
                          Format - DOCX
                        </ActionButton>
                      ),
                    },
                  ],
                },
              },
              {
                key: helperService.makeId(10),
                iconProps: ruLanguageIcon,
                text: "Sprache - Russisch",
                subMenuProps: {
                  items: [
                    {
                      key: helperService.makeId(10),
                      onRender: () => (
                        <ActionButton
                          title="Russisches Zertifikat in PDF Format herunterladen"
                          onClick={() =>
                            apiCalls.getDocument(
                              props.cargolistId!,
                              DocType.cert,
                              Format.pdf,
                              props.fileName!,
                              Lang.ru
                            )
                          }
                          iconProps={documentIcon}
                        >
                          Format - PDF
                        </ActionButton>
                      ),
                    },
                    {
                      key: helperService.makeId(10),
                      onRender: () => (
                        <ActionButton
                          title="Russisches Zertifikat in DOCX Format herunterladen"
                          onClick={() =>
                            apiCalls.getDocument(
                              props.cargolistId!,
                              DocType.cert,
                              Format.docx,
                              props.fileName!,
                              Lang.ru
                            )
                          }
                          iconProps={wordDocumentIcon}
                        >
                          Format - DOCX
                        </ActionButton>
                      ),
                    },
                  ],
                },
              },
            ],
          },
        },
        {
          key: helperService.makeId(10),
          iconProps: listIcon,
          text: "Ladeliste",
          subMenuProps: {
            items: [
              {
                key: helperService.makeId(10),
                iconProps: deLanguageIcon,
                text: "Sprache - Deutsch",
                subMenuProps: {
                  items: [
                    {
                      key: helperService.makeId(10),
                      onRender: () => (
                        <ActionButton
                          title="Deutsche Ladeliste in PDF Format ausdrucken"
                          onClick={() =>
                            apiCalls.getDocument(
                              props.cargolistId!,
                              DocType.list,
                              Format.pdf,
                              props.fileName!,
                              Lang.de
                            )
                          }
                          iconProps={documentIcon}
                        >
                          Format - PDF
                        </ActionButton>
                      ),
                    },
                    {
                      key: helperService.makeId(10),
                      onRender: () => (
                        <ActionButton
                          title="Deutsche Ladeliste in DOCX Format herunterladen"
                          onClick={() =>
                            apiCalls.getDocument(
                              props.cargolistId!,
                              DocType.list,
                              Format.docx,
                              props.fileName!,
                              Lang.de
                            )
                          }
                          iconProps={wordDocumentIcon}
                        >
                          Format - DOCX
                        </ActionButton>
                      ),
                    },
                  ],
                },
              },
              {
                key: helperService.makeId(10),
                iconProps: ruLanguageIcon,
                text: "Sprache - Russisch",
                subMenuProps: {
                  items: [
                    {
                      key: helperService.makeId(10),
                      onRender: () => (
                        <ActionButton
                          title="Russische Ladeliste in PDF Format ausdrucken"
                          onClick={() =>
                            apiCalls.getDocument(
                              props.cargolistId!,
                              DocType.list,
                              Format.pdf,
                              props.fileName!,
                              Lang.ru
                            )
                          }
                          iconProps={documentIcon}
                        >
                          Format - PDF
                        </ActionButton>
                      ),
                    },
                    {
                      key: helperService.makeId(10),
                      onRender: () => (
                        <ActionButton
                          title="Russische Ladeliste in DOCCX Format ausdrucken"
                          onClick={() =>
                            apiCalls.getDocument(
                              props.cargolistId!,
                              DocType.list,
                              Format.docx,
                              props.fileName!,
                              Lang.ru
                            )
                          }
                          iconProps={wordDocumentIcon}
                        >
                          Format - DOCX
                        </ActionButton>
                      ),
                    },
                  ],
                },
              },
            ],
          },
        },
        {
          key: helperService.makeId(10),
          text: "CMR",
          iconProps: cmrIcon,
          subMenuProps: {
            items: [
              {
                key: helperService.makeId(10),
                onRender: () => (
                  <ActionButton
                    title="CMR in PDF Format ausdrucken"
                    onClick={() =>
                      apiCalls.getDocument(
                        props.cargolistId!,
                        DocType.cmr,
                        Format.pdf,
                        props.fileName!
                      )
                    }
                    iconProps={documentIcon}
                  >
                    Format - PDF
                  </ActionButton>
                ),
              },
              {
                key: helperService.makeId(10),
                onRender: () => (
                  <ActionButton
                    title="CMR in DOCX Format herunterladen"
                    onClick={() =>
                      apiCalls.getDocument(
                        props.cargolistId!,
                        DocType.cmr,
                        Format.docx,
                        props.fileName!
                      )
                    }
                    iconProps={wordDocumentIcon}
                  >
                    Format - DOCX
                  </ActionButton>
                ),
              },
            ],
          },
        },
        {
          key: helperService.makeId(10),
          text: "ZIP",
          iconProps: zipIcon,
          subMenuProps: {
            items: [
              {
                key: helperService.makeId(10),
                onRender: () => (
                  <ActionButton
                    disabled
                    title="Alle Dokumente heruntergeladen"
                    onClick={() =>
                      apiCalls.getDocument(
                        props.cargolistId!,
                        DocType.docs,
                        Format.zip,
                        props.fileName!
                      )
                    }
                    iconProps={downloadIcon}
                  >
                    Alle Dokumente herunterladen
                  </ActionButton>
                ),
              },
            ],
          },
        },
      ]
    : [];

  useEffect(() => {
    // Necessary for the notification interaction
    const modals = document.querySelectorAll(".ms-Layer, .ms-Layer--fixed");
    modals.forEach((m: any) => (m.style.zIndex = 1));
  }, []);

  return (
    <div>
      <CommandBar
        styles={commandBar}
        overflowItems={overflowItems}
        items={items}
        farItems={farItems}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
    </div>
  );
};
