import { ICargolistDocument } from "../Cargolist/cargolist.type";

export const attributes = {
  title: {
    label: "Titel",
    name: "title",
  },
  cargolists: {
    label: "Ladelisten",
    name: "cargolists",
  },
};
export interface ITourDocument {
  id?: number;
  title: string;
  cargolists?: ICargolistDocument[];
}

export class Tour implements ITourDocument {
  title = "";
}
