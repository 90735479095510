/* eslint-disable consistent-return */
import { IconButton, Label } from "@fluentui/react";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { ApiService } from "../../../services/api.service";
import { addIcon } from "../component-styles";
import { contextSegments, IItemsFragment } from "../context-segments";
import "./Dropdown.scss";
import { IOption } from "./option";

interface IProps {
  id?: string;
  attributeName: string;
  label: string;
  contextKey: string;
  optionKey: string;
  isMulti: boolean;
  required?: boolean;
  defaultOption: IOption | IOption[];
  shouldSetDefaultOption: boolean;
  hideAddButton?: boolean;
  setOption: (e: any, newValue: any) => void;
  showModal: () => void;
  setShouldSetDefaultOption: (flag: boolean) => void;
}

interface OptionFragment {
  options: IOption[];
  isLastPage: boolean;
}

const Dropdown = (props: IProps) => {
  // Own state
  const [value, setValue] = useState<IOption | IOption[]>();
  const apiCalls: any = ApiService.getInstance();
  const limit: number = 20;

  useEffect(() => {
    const isDefaultOptionArrayChanged = (): boolean => {
      let result: boolean = false;
      const options = props.defaultOption as IOption[];

      if (Array.isArray(options) && options.length > 0) {
        options.forEach((i) => {
          if (
            i.value !== "undefined" &&
            i.name !== "undefined" &&
            props.shouldSetDefaultOption
          ) {
            result = true;
          }
        });
      }
      return result;
    };

    const isDefaultOptionChanged = (): boolean => {
      let result: boolean = false;
      const option = props.defaultOption as IOption;

      if (
        option &&
        option.value !== "undefined" &&
        option.name !== "undefined" &&
        props.shouldSetDefaultOption
      ) {
        result = true;
      }
      return result;
    };

    if (isDefaultOptionChanged() || isDefaultOptionArrayChanged()) {
      setValue(props.defaultOption);
      //   Signal that the item is preselected
      props.setShouldSetDefaultOption(false);
    }
  }, [props]);

  const onSelectOption = async (newInput: any, actionMeta?: any) => {
    const fakeEvent = {
      target: {
        name: props.attributeName,
        type: "dropdown",
      },
    };
    if (actionMeta && actionMeta.action === "clear") {
      props.setOption(fakeEvent, undefined);
      setValue(newInput);
    } else if (Array.isArray(newInput)) {
      const selectedItems: any = [];

      for await (const option of newInput) {
        const selectedItem = await getSingleItem(Number(option.value));
        selectedItems.push(selectedItem);
      }

      props.setOption(fakeEvent, selectedItems);
      setValue(newInput);
    } else {
      const selectedItem = await getSingleItem(Number(newInput.value));
      props.setOption(fakeEvent, selectedItem);
      setValue(newInput);
    }
  };

  /**
   * Queries a single item
   * @param id Id of the desired item
   * @returns The desired item object
   */
  const getSingleItem = async (id: number) => {
    const res: AxiosResponse<any> = await apiCalls[
      `getSingle${props.contextKey}`
    ](id);
    return res.data;
  };

  /**
   * Converts items to Options
   * @param items Response from the api
   * @returns {IOption[]} An array of Options
   */
  const getOptions = (items: IItemsFragment[]): IOption[] => {
    const result: IOption[] = [];

    if (items.length > 0) {
      const isGoodContext =
        props.contextKey === contextSegments.good.contextKey;

      items.forEach((i: any) => {
        const option = {
          name: isGoodContext
            ? `${i[props.optionKey]} - ${i["goodUnit"]?.title}`
            : i[props.optionKey],
          value: String(i.id),
        };
        result.push(option);
      });
    }
    return result;
  };

  /**
   * Constructs an options fragment
   * @param query A Query which will be passed to the api call
   * @returns {Promise<OptionFragment>}
   */
  const getOptionsFragment = async (query: string): Promise<OptionFragment> => {
    let options: IOption[] = [];
    let isLastPage: boolean = false;
    const res: AxiosResponse<IItemsFragment> = await apiCalls[
      `get${props.contextKey}`
    ](query);
    options = getOptions(res.data.items);
    isLastPage = res.data.isLastPage;

    return {
      options,
      isLastPage,
    };
  };

  const loadOptions = async (
    inputValue: string,
    loadedOptions: any,
    { page }: any
  ) => {
    let query: string = "";
    let hasMore: boolean = false;
    let optionsFragment: OptionFragment = { options: [], isLastPage: false };

    // In case some next page exist
    // Paste next page parameter with its value in the query
    if (page && page > 0) {
      const pageQuery: string = `limit=${limit}&page=${page}`;
      query += pageQuery;
    }

    // In case no search exist
    if (!inputValue) {
      optionsFragment = await getOptionsFragment(query);

      // In case there are some next pages
      if (page > 0 && !optionsFragment.isLastPage) {
        hasMore = true;
      }
    }
    // In case some search exist
    else if (inputValue) {
      // Paste the search parameter with its value in the query
      const searchQuery: string = `&search=${inputValue}`;
      query += searchQuery;

      optionsFragment = await getOptionsFragment(query);

      // In case there are some next pages
      if (page > 0 && !optionsFragment.isLastPage) {
        hasMore = true;
      }
    }

    return {
      options: optionsFragment.options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const key: string = `${JSON.stringify(props.contextKey)}: ${
    props.attributeName
  }`;
  const isValueArray: boolean = Array.isArray(value) && value.length >= 2;
  //   Dropdown styles
  const adoptDropdownHeight = () => {
    if (isValueArray) {
      return {
        height: "auto",
        minHeight: "auto",
      };
    }
    return {
      height: 32,
      minHeight: 32,
    };
  };

  const dropdownStyles = {
    control: (base: any) => ({
      ...base,
      ...adoptDropdownHeight(),
    }),
    singleValue: (styles: any) => ({ ...styles, marginTop: "-2px" }),
    multiValue: (styles: any) =>
      isValueArray ? { ...styles } : { ...styles, marginTop: "-2px" },
    placeholder: (styles: any) => ({ ...styles, margin: "-3px 0 0 0" }),
    dropdownIndicator: (styles: any) => ({ ...styles, margin: "-3px 0 0 0" }),
    clearIndicator: (styles: any) => ({ ...styles, margin: "-3px 0 0 0" }),
    valueContainer: (styles: any) => ({
      ...styles,
      ...adoptDropdownHeight(),
    }),
  };

  return (
    <>
      <div id={props.id}>
        <Label required={props.required}>{props.label}</Label>
        <div style={{ display: "flex", alignItems: "center" }}>
          <AsyncPaginate
            className="dropdown"
            styles={dropdownStyles}
            key={key}
            isSearchable
            isClearable
            isMulti={props.isMulti}
            value={value}
            onChange={onSelectOption}
            loadOptions={loadOptions}
            getOptionValue={(option: any) => option.name}
            getOptionLabel={(option: any) => option.name}
            additional={{
              page: 1,
            }}
            placeholder="Auswahl..."
            noOptionsMessage={(obj: { inputValue: string }) => "Keine Option"}
            loadingMessage={(obj: { inputValue: string }) => "Lade..."}
          />
          {props.hideAddButton ? null : (
            <IconButton
              className="dropdown-add-button"
              style={{
                marginLeft: "5px",
                flex: "1 1 10%",
              }}
              onClick={props.showModal}
              iconProps={{ ...addIcon, style: { fontSize: "18px" } }}
              title={`${props.label} hinzufügen`}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Dropdown;
