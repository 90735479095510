import {
  ComboBox,
  IComboBox,
  IComboBoxOption,
  IconButton,
  ITextProps,
  Label,
  Modal,
  Text,
  TextField,
} from "@fluentui/react";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { ApiService } from "../../../services/api.service";
import { HelperService } from "../../../services/helper.service";
import {
  cancelIcon,
  contentStyles,
  iconButtonStyles,
  labelStyles,
  valueStyles,
} from "../../Controls/component-styles";
import {
  contextSegments,
  FormMode,
  InputType,
} from "../../Controls/context-segments";
import DeleteDialog from "../../Controls/Dialogs/DeleteDialog";
import Dropdown from "../../Controls/Dropdown/Dropdown";
import { Option } from "../../Controls/Dropdown/option";
import { DynamicFormCommandBar } from "../../Controls/DynamicFormCommandBar/DynamicFormCommandBar";
import { IChurchDocument } from "../Church/church.type";
import ChurchForm from "../Church/ChurchForm";
import { attributes, Driver, IDriverDocument } from "./driver.type";

interface IProps {
  isModalOpen: boolean;
  selectedItem: IDriverDocument | null;
  formMode: FormMode;
  fkName?: string;
  closeModal: () => void;
  setFormMode: (formMode: FormMode) => void;
  setReload?: (reload: boolean) => void;
  setCreatedItem?: (e: any, newItem: any) => void;
}

const apiCalls: ApiService = ApiService.getInstance();
const helperService = HelperService.getInstance();
const formName: string = "driver-form";
const driverTypeOptions: IComboBoxOption[] = [
  { key: "carrier", text: "Spedition" },
  { key: "mission", text: "Mission" },
];

const DriverForm = (props: IProps) => {
  const formHeading: string = helperService.getFormHeading(
    contextSegments.driver.singularName,
    props.formMode
  );

  const [payload, setPayload] = useState<IDriverDocument>(new Driver());
  const [hideDeleteDialog, toggleHideDeleteDialog] = useState<boolean>(true);

  //   Church
  const [isChurchModalOpen, setIsChurchModalOpen] = useState<boolean>(false);
  const [churchFormMode, setChurchFormMode] = useState<FormMode>(
    FormMode.create
  );
  const [selectedChurchItem, setSelectedChurchItem] =
    useState<IChurchDocument | null>(null);
  const [shouldSetDefaultChurchOption, setShouldSetDefaultChurchOption] =
    useState<boolean>(true);

  const closeChurchModal = async () => {
    setIsChurchModalOpen(false);
    setSelectedChurchItem(null);
    setChurchFormMode(FormMode.create);
  };

  const setChurchOption = async (e: any, newValue: any) => {
    handleOnChange(e, newValue);
    // Signal that the item need to be preselected in the dropdown
    setShouldSetDefaultChurchOption(true);
  };

  useEffect(() => {
    // In case some item was passed to the form
    // Set this item to the payload
    if (props.selectedItem) {
      setPayload(props.selectedItem);
    }
    // In case no item was passed to the form
    // Set default empty payload
    else {
      setPayload(new Driver());
    }

    // Clear the input when closing the modal
    if (!props.isModalOpen) {
      setPayload(new Driver());
    }
  }, [props.selectedItem, props.isModalOpen]);

  const titleId = helperService.makeId(10);

  /**
   * Invoke create action
   * @param e
   */
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // In case payload id exist
    // Update the item
    if (payload.id) {
      apiCalls
        .updateDriver(payload, payload.id)
        .then((res: AxiosResponse<any>) => {
          props.setReload && props.setReload(true);

          props.closeModal();
          setPayload(new Driver());
        });
    }
    // In case no payload id exist
    // Create a new item
    else {
      apiCalls.createDriver(payload).then((res: AxiosResponse<any>) => {
        //   In case creating an item through the dropdown context
        if (props.setCreatedItem && props.fkName && res.data.id) {
          const fakeEvent = {
            target: {
              name: props.fkName,
              type: "dropdown",
            },
          };
          const newItem: IDriverDocument = {
            id: res.data["id"],
            name: res.data["name"],
            church: res.data["church"],
            phone: res.data["phone"],
            mail: res.data["mail"],
            driverType: res.data["driverType"],
            rides: res.data["rides"],
          };
          props.setCreatedItem(fakeEvent, newItem);
        }
        props.setReload && props.setReload(true);

        props.closeModal();
        setPayload(new Driver());
      });
    }
  };

  /**
   * Write newValue to state by element name
   * @param e
   * @param newValue
   */
  const handleOnChange = (e: any, newValue: any) => {
    const { name } = e.target;

    if (newValue === undefined) {
      setPayload({ ...payload, [name]: null });
    } else {
      const inputType: string = e.target.type;
      if (inputType === InputType.text || inputType === InputType.textarea) {
        setPayload({ ...payload, [name]: String(newValue) });
      } else if (inputType === InputType.number) {
        setPayload({ ...payload, [name]: Number(newValue) });
      } else if (inputType === InputType.checkbox) {
        setPayload({ ...payload, [name]: Boolean(newValue) });
      } else if (inputType === InputType.dropdown) {
        setPayload({ ...payload, [name]: newValue });
      } else if (inputType === InputType.combobox) {
        setPayload({ ...payload, [name]: newValue.text });
      }
    }
  };
  const isEditable: boolean =
    props.formMode === FormMode.create || props.formMode === FormMode.edit;
  const driverTypeDefaultOption = driverTypeOptions.find(
    (o) => o.text === payload.driverType
  );
  const driverTypeFakeEvent = {
    target: {
      name: "driverType",
      type: "combobox",
    },
  };
  const deleteOpenedItem = async () => {
    toggleHideDeleteDialog(true);
    props.closeModal();
    await apiCalls.deleteDriver(props.selectedItem!.id!);
    props.setReload && props.setReload(true);
  };

  return (
    <Modal
      titleAriaId={titleId}
      isOpen={props.isModalOpen}
      onDismiss={props.closeModal}
      isBlocking={false}
      containerClassName={contentStyles.container}
    >
      <DeleteDialog
        toggleHideDeleteDialog={toggleHideDeleteDialog}
        hideDeleteDialog={hideDeleteDialog}
        selectedItemsCount={1}
        deleteSelectedItems={deleteOpenedItem}
      />
      <div className={contentStyles.header}>
        <Text id={titleId} variant={"xxLarge" as ITextProps["variant"]}>
          {formHeading}
        </Text>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={props.closeModal}
        />
      </div>
      <div className={contentStyles.body}>
        {isEditable && props.isModalOpen ? (
          <form id={formName} onSubmit={onSubmit}>
            <DynamicFormCommandBar
              formMode={props.formMode}
              toggleHideDeleteDialog={toggleHideDeleteDialog}
              closeModal={props.closeModal}
              formName={formName}
            />
            <Dropdown
              shouldSetDefaultOption={shouldSetDefaultChurchOption}
              isMulti={false}
              contextKey={contextSegments.church.contextKey}
              optionKey={contextSegments.church.displayAttribute}
              attributeName={attributes.church.name}
              label={attributes.church.label}
              setOption={setChurchOption}
              setShouldSetDefaultOption={setShouldSetDefaultChurchOption}
              showModal={() => setIsChurchModalOpen(true)}
              defaultOption={
                new Option(
                  String(payload.church?.title),
                  String(payload.church?.id)
                )
              }
            />
            <ComboBox
              onChange={
                (
                  event: React.FormEvent<IComboBox>,
                  option?: IComboBoxOption | undefined
                ) => handleOnChange(driverTypeFakeEvent, option)
                // eslint-disable-next-line react/jsx-curly-newline
              }
              defaultSelectedKey={driverTypeDefaultOption?.key}
              label={attributes.driverType.label}
              options={driverTypeOptions}
            />
            <TextField
              name={attributes.name.name}
              label={attributes.name.label}
              type="text"
              required
              onChange={handleOnChange}
              defaultValue={payload.name}
            />
            <TextField
              name={attributes.phone.name}
              label={attributes.phone.label}
              type="text"
              onChange={handleOnChange}
              defaultValue={payload.phone}
            />
            <TextField
              name={attributes.mail.name}
              label={attributes.mail.label}
              type="email"
              onChange={handleOnChange}
              defaultValue={payload.mail}
            />
          </form>
        ) : (
          <div>
            <DynamicFormCommandBar
              formMode={props.formMode}
              toggleHideDeleteDialog={toggleHideDeleteDialog}
              formName={formName}
              setFormMode={props.setFormMode}
              closeModal={props.closeModal}
            />
            <Label styles={labelStyles}>{attributes.church.label}</Label>
            <span style={valueStyles}>{payload.church?.title}</span>
            <Label styles={labelStyles}>{attributes.driverType.label}</Label>
            <span style={valueStyles}>{payload.driverType}</span>
            <Label styles={labelStyles}>{attributes.name.label}</Label>
            <span style={valueStyles}>{payload.name}</span>
            <Label styles={labelStyles}>{attributes.phone.label}</Label>
            <span style={valueStyles}>{payload.phone}</span>
            <Label styles={labelStyles}>{attributes.mail.label}</Label>
            <span style={valueStyles}>{payload.mail}</span>
          </div>
        )}
      </div>

      {/* Additional Forms */}
      <ChurchForm
        isModalOpen={isChurchModalOpen}
        formMode={churchFormMode}
        selectedItem={selectedChurchItem}
        fkName={attributes.church.name}
        setFormMode={setChurchFormMode}
        closeModal={closeChurchModal}
        setCreatedItem={setChurchOption}
      />
    </Modal>
  );
};

export default DriverForm;
