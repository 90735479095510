import {
  IconButton,
  ITextProps,
  Label,
  Link,
  Modal,
  Text,
  TextField,
} from "@fluentui/react";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { ApiService } from "../../../services/api.service";
import { HelperService } from "../../../services/helper.service";
import {
  cancelIcon,
  iconButtonStyles,
  labelStyles,
  twoGroupedForm,
  valueStyles,
  wideFormContentStyles,
} from "../../Controls/component-styles";
import {
  contextSegments,
  FormMode,
  InputType,
} from "../../Controls/context-segments";
import DeleteDialog from "../../Controls/Dialogs/DeleteDialog";
import { DynamicFormCommandBar } from "../../Controls/DynamicFormCommandBar/DynamicFormCommandBar";
import { Address, attributes, IAddressDocument } from "./address.type";

interface IProps {
  isModalOpen: boolean;
  selectedItem: IAddressDocument | null;
  formMode: FormMode;
  fkName?: string;
  closeModal: () => void;
  setFormMode: (formMode: FormMode) => void;
  setReload?: (reload: boolean) => void;
  setCreatedItem?: (e: any, newItem: any) => void;
}

const apiCalls: ApiService = ApiService.getInstance();
const helperService: HelperService = HelperService.getInstance();
const formName: string = "address-form";

const AddressForm = (props: IProps) => {
  const formHeading: string = helperService.getFormHeading(
    contextSegments.address.singularName,
    props.formMode
  );

  const [payload, setPayload] = useState<IAddressDocument>(new Address());
  const [hideDeleteDialog, toggleHideDeleteDialog] = useState<boolean>(true);

  useEffect(() => {
    // In case some item was passed to the form
    // Set this item to the payload
    if (props.selectedItem) {
      setPayload(props.selectedItem);
    }
    // In case no item was passed to the form
    // Set default empty payload
    else {
      setPayload(new Address());
    }

    // Clear the input when closing the modal
    if (!props.isModalOpen) {
      setPayload(new Address());
    }
  }, [props.selectedItem, props.isModalOpen]);

  const titleId = helperService.makeId(10);

  /**
   * Invoke create action
   * @param e
   */
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // In case payload id exist
    // Update the item
    if (payload.id) {
      apiCalls
        .updateAddress(payload, payload.id)
        .then((res: AxiosResponse<any>) => {
          props.setReload && props.setReload(true);

          props.closeModal();
          setPayload(new Address());
        });
    }
    // In case no payload id exist
    // Create a new item
    else {
      apiCalls.createAddress(payload).then((res: AxiosResponse<any>) => {
        //   In case creating an item through the dropdown context
        if (props.setCreatedItem && props.fkName && res.data.id) {
          const fakeEvent = {
            target: {
              name: props.fkName,
              type: "dropdown",
            },
          };
          const newItem: IAddressDocument = {
            id: res.data["id"],
            titleDe: res.data["titleDe"],
            titleRu: res.data["titleRu"],
            streetDe: res.data["streetDe"],
            streetRu: res.data["streetRu"],
            zip: res.data["zip"],
            cityDe: res.data["cityDe"],
            cityRu: res.data["cityRu"],
            kod: res.data["kod"],
            countryDe: res.data["countryDe"],
            countryRu: res.data["countryRu"],
            countryIso: res.data["countryIso"],
            exitCustomsOffice: res.data["exitCustomsOffice"],
            contactPerson: res.data["contactPerson"],
            phone: res.data["phone"],
            fax: res.data["fax"],
            mail: res.data["mail"],
            homepage: res.data["homepage"],
            additionalTextDe: res.data["additionalTextDe"],
            additionalTextRu: res.data["additionalTextRu"],
          };

          props.setCreatedItem(fakeEvent, newItem);
        }
        props.setReload && props.setReload(true);

        props.closeModal();
        setPayload(new Address());
      });
    }
  };

  /**
   * Write newValue to state by element name
   * @param e
   * @param newValue
   */
  const handleOnChange = (e: any, newValue: any) => {
    const { name } = e.target;

    if (newValue === undefined) {
      setPayload({ ...payload, [name]: null });
    } else {
      const inputType: string = e.target.type;
      if (inputType === InputType.text || inputType === InputType.textarea) {
        setPayload({ ...payload, [name]: String(newValue) });
      } else if (inputType === InputType.number) {
        setPayload({ ...payload, [name]: Number(newValue) });
      } else if (inputType === InputType.checkbox) {
        setPayload({ ...payload, [name]: Boolean(newValue) });
      } else if (inputType === InputType.dropdown) {
        setPayload({ ...payload, [name]: newValue });
      }
    }
  };
  const isEditable: boolean =
    props.formMode === FormMode.create || props.formMode === FormMode.edit;
  const deleteOpenedItem = async () => {
    toggleHideDeleteDialog(true);
    props.closeModal();
    await apiCalls.deleteAddress(props.selectedItem!.id!);
    props.setReload && props.setReload(true);
  };

  return (
    <Modal
      titleAriaId={titleId}
      isOpen={props.isModalOpen}
      onDismiss={props.closeModal}
      isBlocking={false}
      containerClassName={wideFormContentStyles.container}
    >
      <DeleteDialog
        toggleHideDeleteDialog={toggleHideDeleteDialog}
        hideDeleteDialog={hideDeleteDialog}
        selectedItemsCount={1}
        deleteSelectedItems={deleteOpenedItem}
      />
      <div className={wideFormContentStyles.header}>
        <Text id={titleId} variant={"xxLarge" as ITextProps["variant"]}>
          {formHeading}
        </Text>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={props.closeModal}
        />
      </div>

      <div className={wideFormContentStyles.body}>
        {isEditable && props.isModalOpen ? (
          <form id={formName} onSubmit={onSubmit}>
            <DynamicFormCommandBar
              formMode={props.formMode}
              toggleHideDeleteDialog={toggleHideDeleteDialog}
              closeModal={props.closeModal}
              formName={formName}
            />
            <div style={twoGroupedForm.container}>
              <div style={twoGroupedForm.leftGroup}>
                <TextField
                  name={attributes.titleDe.name}
                  label={attributes.titleDe.label}
                  required
                  type="text"
                  onChange={handleOnChange}
                  defaultValue={payload.titleDe}
                />
                <TextField
                  name={attributes.streetDe.name}
                  label={attributes.streetDe.label}
                  type="text"
                  onChange={handleOnChange}
                  defaultValue={payload.streetDe}
                />
                <TextField
                  name={attributes.cityDe.name}
                  label={attributes.cityDe.label}
                  type="text"
                  onChange={handleOnChange}
                  defaultValue={payload.cityDe}
                />
                <TextField
                  name={attributes.countryDe.name}
                  label={attributes.countryDe.label}
                  type="text"
                  onChange={handleOnChange}
                  defaultValue={payload.countryDe}
                />
                <TextField
                  name={attributes.additionalTextDe.name}
                  label={attributes.additionalTextDe.label}
                  type="text"
                  maxLength={120}
                  rows={3}
                  multiline
                  resizable={false}
                  onChange={handleOnChange}
                  defaultValue={payload.additionalTextDe}
                />
              </div>
              <div style={twoGroupedForm.rightGroup}>
                <TextField
                  name={attributes.titleRu.name}
                  label={attributes.titleRu.label}
                  required
                  type="text"
                  onChange={handleOnChange}
                  defaultValue={payload.titleRu}
                />
                <TextField
                  name={attributes.streetRu.name}
                  label={attributes.streetRu.label}
                  type="text"
                  onChange={handleOnChange}
                  defaultValue={payload.streetRu}
                />
                <TextField
                  name={attributes.cityRu.name}
                  label={attributes.cityRu.label}
                  type="text"
                  onChange={handleOnChange}
                  defaultValue={payload.cityRu}
                />
                <TextField
                  name={attributes.countryRu.name}
                  label={attributes.countryRu.label}
                  type="text"
                  onChange={handleOnChange}
                  defaultValue={payload.countryRu}
                />
                <TextField
                  name={attributes.additionalTextRu.name}
                  label={attributes.additionalTextRu.label}
                  type="text"
                  maxLength={120}
                  rows={3}
                  multiline
                  resizable={false}
                  onChange={handleOnChange}
                  defaultValue={payload.additionalTextRu}
                />
              </div>
            </div>
            <div style={{ margin: "5px" }}>
              <TextField
                name={attributes.zip.name}
                label={attributes.zip.label}
                maxLength={5}
                type="text"
                onChange={handleOnChange}
                defaultValue={payload.zip}
              />
              <TextField
                name={attributes.kod.name}
                label={attributes.kod.label}
                type="text"
                onChange={handleOnChange}
                defaultValue={payload.kod}
              />
              <TextField
                name={attributes.countryIso.name}
                label={attributes.countryIso.label}
                type="text"
                onChange={handleOnChange}
                defaultValue={payload.countryIso}
              />
              <TextField
                name={attributes.exitCustomsOffice.name}
                label={attributes.exitCustomsOffice.label}
                required
                type="text"
                onChange={handleOnChange}
                defaultValue={payload.exitCustomsOffice}
              />

              <TextField
                name={attributes.contactPerson.name}
                label={attributes.contactPerson.label}
                type="text"
                onChange={handleOnChange}
                defaultValue={payload.contactPerson}
              />
              <TextField
                name={attributes.phone.name}
                label={attributes.phone.label}
                type="text"
                onChange={handleOnChange}
                defaultValue={payload.phone}
              />
              <TextField
                name={attributes.fax.name}
                label={attributes.fax.label}
                type="text"
                onChange={handleOnChange}
                defaultValue={payload.fax}
              />
              <TextField
                name={attributes.mail.name}
                label={attributes.mail.label}
                type="text"
                onChange={handleOnChange}
                defaultValue={payload.mail}
              />
              <TextField
                name={attributes.homepage.name}
                label={attributes.homepage.label}
                type="text"
                onChange={handleOnChange}
                defaultValue={payload.homepage}
              />
            </div>
          </form>
        ) : (
          <div>
            <DynamicFormCommandBar
              formMode={props.formMode}
              toggleHideDeleteDialog={toggleHideDeleteDialog}
              formName={formName}
              setFormMode={props.setFormMode}
              closeModal={props.closeModal}
            />
            <div style={twoGroupedForm.container}>
              <div style={twoGroupedForm.leftGroup}>
                <Label styles={labelStyles}>{attributes.titleDe.label}</Label>
                <span style={valueStyles}>{payload.titleDe}</span>
                <Label styles={labelStyles}>{attributes.countryDe.label}</Label>
                <span style={valueStyles}>{payload.countryDe}</span>
                <Label styles={labelStyles}>{attributes.cityDe.label}</Label>
                <span style={valueStyles}>{payload.cityDe}</span>
                <Label styles={labelStyles}>{attributes.streetDe.label}</Label>
                <span style={valueStyles}>{payload.streetDe}</span>
                <Label styles={labelStyles}>
                  {attributes.additionalTextDe.label}
                </Label>
                <span style={valueStyles}>{payload.additionalTextDe}</span>
              </div>
              <div style={twoGroupedForm.rightGroup}>
                <Label styles={labelStyles}>{attributes.titleRu.label}</Label>
                <span style={valueStyles}>{payload.titleRu}</span>
                <Label styles={labelStyles}>{attributes.countryRu.label}</Label>
                <span style={valueStyles}>{payload.countryRu}</span>
                <Label styles={labelStyles}>{attributes.cityRu.label}</Label>
                <span style={valueStyles}>{payload.cityRu}</span>
                <Label styles={labelStyles}>{attributes.streetRu.label}</Label>
                <span style={valueStyles}>{payload.streetRu}</span>
                <Label styles={labelStyles}>
                  {attributes.additionalTextRu.label}
                </Label>
                <span style={valueStyles}>{payload.additionalTextRu}</span>
              </div>
            </div>

            <div style={{ margin: "0 5px 0 5px" }}>
              <Label styles={labelStyles}>{attributes.zip.label}</Label>
              <span style={valueStyles}>{payload.zip}</span>
              <Label styles={labelStyles}>{attributes.kod.label}</Label>
              <span style={valueStyles}>{payload.kod}</span>
              <Label styles={labelStyles}>{attributes.countryIso.label}</Label>
              <span style={valueStyles}>{payload.countryIso}</span>
              <Label styles={labelStyles}>
                {attributes.exitCustomsOffice.label}
              </Label>
              <span style={valueStyles}>{payload.exitCustomsOffice}</span>
              <Label styles={labelStyles}>
                {attributes.contactPerson.label}
              </Label>
              <span style={valueStyles}>{payload.contactPerson}</span>
              <Label styles={labelStyles}>{attributes.phone.label}</Label>
              <span style={valueStyles}>{payload.phone}</span>
              <Label styles={labelStyles}>{attributes.fax.label}</Label>
              <span style={valueStyles}>{payload.fax}</span>
              <Label styles={labelStyles}>{attributes.mail.label}</Label>
              <span style={valueStyles}>{payload.mail}</span>
              <Label styles={labelStyles}>{attributes.homepage.label}</Label>
              <Link target="_blank" href={payload.homepage}>
                {payload.homepage}
              </Link>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AddressForm;
