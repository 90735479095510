import {
  DetailsRow,
  IColumn,
  Icon,
  IDetailsListProps,
  IDetailsRowStyles,
  TooltipHost,
  TooltipOverflowMode,
} from '@fluentui/react';
import moment from 'moment';
import 'moment/locale/de';
import * as React from 'react';
import { theme } from '../components/Controls/component-styles';
import { FormMode } from '../components/Controls/context-segments';

moment.locale('de');

export class HelperService {
  private static instance: HelperService;

  public static getInstance(): HelperService {
    if (!this.instance) {
      this.instance = new HelperService();
    }
    return this.instance;
  }

  public getFormHeading(name: string, mode: FormMode) {
    return `${name} ${mode === FormMode.create ? 'hinzufügen' : mode === FormMode.edit ? 'bearbeiten' : ''}`;
  }

  /**
   * Generates edit button
   * @param item
   */
  private generateEditButton(item: any, openItem: (itemId: number) => void, isCargo: boolean): JSX.Element {
    return (
      <Icon
        iconName="View"
        onClick={() => {
          openItem && openItem(isCargo ? item.key : item.id);
        }}
        style={{ cursor: 'pointer', fontSize: '15px' }}
      />
    );
  }

  public isPromise(promise: any) {
    return !!promise && typeof promise.then === 'function';
  }

  public generateRowValue(value: string) {
    return (
      <TooltipHost id={this.makeId(1000)} content={value} overflowMode={TooltipOverflowMode.Parent}>
        <span aria-describedby={this.makeId(1000)}>{value}</span>
      </TooltipHost>
    );
  }

  public removeUmlaute(str: string) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  /**
   * Does apply some content functionality on items
   * @param item
   * @param index
   * @param column
   */
  public renderItemColumn(
    item: any,
    index: number,
    column: IColumn,
    openItem: (itemId: number) => void,
    isCargo: boolean
  ) {
    let result;

    if (item && column) {
      // Edit button representation
      if (column.fieldName === 'edit') {
        result = this.generateEditButton(item, openItem, isCargo);
      } else {
        // Usual text representation
        if (column.data === 'string') {
          const value = item[Object(column).fieldName];
          result = this.generateRowValue(value || '');
        }
        // Boolean representation
        else if (column.data === 'boolean') {
          const value = item[Object(column).fieldName];
          result = this.generateRowValue(value ? 'Ja' : 'Nein');
        }
        // Date representation
        else if (column.data === 'date') {
          const value = item[Object(column).fieldName];
          result = this.generateRowValue(value ? moment(value).format('DD.MM.YYYY') : '');
        }
        // Currency representation
        else if (column.data === 'currency') {
          const value = item[Object(column).fieldName];
          result = this.generateRowValue(`${value} €`);
        }
        // Weight representation
        else if (column.data === 'weight') {
          const value = item[Object(column).fieldName];
          result = this.generateRowValue(`${value} Kg`);
        }
      }
    }
    return result;
  }

  public onRenderRow: IDetailsListProps['onRenderRow'] = (props: any) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      if (props.itemIndex % 2 === 0) {
        // Every other row renders with a different background color
        customStyles.root = { backgroundColor: theme.palette.themeLighterAlt };
      }

      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  };

  public getKey(item: any, index?: number): string {
    return item.key;
  }

  public makeId(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return `id-${result}`;
  }
}
