import { Component } from "react";
import Header from "./Header/Header";
import "./Layout.scss";
import Sidemenu from "./Sidemenu/Sidemenu";

interface IProps {}

interface IState {}

export class Layout extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <div className="layout-container">
        <Header />
        <div className="layout-container-content">
          <Sidemenu />
          <div className="layout-container-content-list">
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}
