import { IChurchDocument } from "../Church/church.type";

export const attributes = {
  name: {
    label: "Name",
    name: "name",
  },
  church: {
    label: "Gemeinde",
    name: "church",
  },
  phone: {
    label: "Telefon",
    name: "phone",
  },

  mail: {
    label: "E-Mail",
    name: "mail",
  },
  driverType: {
    label: "Fahrertyp",
    name: "driverType",
  },
  rides: {
    label: "Farten",
    name: "rides",
  },
};
export interface IDriverDocument {
  id?: number;
  name: string;
  church: IChurchDocument | null;
  phone: string;
  mail: string;
  driverType: string;
  rides: number;
}

export class Driver implements IDriverDocument {
  name = "";

  church = null;

  phone = "";

  mail = "";

  driverType = "Mission";

  rides = 0;
}
