import { IGoodDocument } from "../../Good/good.type";
import { IGoodConditionDocument } from "../../GoodCondition/good-condition.type";

export const attributes = {
  customTitleDe: {
    label: "Angepasster deutscher Titel",
    name: "customTitleDe",
  },
  customTitleRu: {
    label: "Angepasster russischer Titel",
    name: "customTitleRu",
  },
  good: {
    label: "Ware",
    name: "good",
  },
  goodCondition: {
    label: "Warenzustand",
    name: "goodCondition",
  },
  amount: {
    label: "Menge",
    name: "amount",
  },
  customNettoWeight: {
    label: "Netto",
    name: "customNettoWeight",
  },
  customBruttoWeight: {
    label: "Brutto",
    name: "customBruttoWeight",
  },
  customPrice: {
    label: "Preis",
    name: "customPrice",
  },
  customCode: {
    label: "Angepasster Code",
    name: "customCode",
  },
};

const goodConditionDefault: IGoodConditionDocument = {
  id: 1,
  title: "Gewöhnlich",
  indicationDe: "",
  indicationRu: "",
};

export interface ICargoDocument {
  key?: string;
  id?: number;
  good?: IGoodDocument;
  goodCondition?: IGoodConditionDocument;
  customTitleDe: string;
  customTitleRu: string;
  amount: number;
  customNettoWeight?: number;
  customCode?: string;
  customBruttoWeight?: number;
  customPrice?: number;
  cargolistId: number | null;
}

export class Cargo implements ICargoDocument {
  good = undefined;

  goodCondition = goodConditionDefault;

  customTitleDe = "";

  customTitleRu = "";

  amount = 1;

  customNettoWeight = undefined;

  customBruttoWeight = undefined;

  customCode = undefined;

  customPrice = undefined;

  cargolistId = 0;
}
