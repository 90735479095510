import { IVehicleTypeDocument } from '../VehicleType/vehicle-type.type';

export const attributes = {
  licensePlate: {
    label: 'Kennzeichen',
    name: 'licensePlate',
  },
  payload: {
    label: 'Nutzlast',
    name: 'payload',
  },
  countryCode: {
    label: 'Landcode',
    name: 'countryCode',
  },
  vehicleType: {
    label: 'Fahrzeugart',
    name: 'vehicleType',
  },
};

export interface IVehicleDocument {
  id?: number;
  licensePlate: string;
  payload: number;
  countryCode: string;
  vehicleType: IVehicleTypeDocument | null;
}

export class Vehicle implements IVehicleDocument {
  licensePlate = '';

  payload = 0;

  countryCode = '';

  vehicleType = null;
}
