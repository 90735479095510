import { Callout, Text } from '@fluentui/react';
import * as React from 'react';
import { calloutStyles } from '../component-styles';

interface IProps {
  buttonId: string;
  hideCallout: () => void;
}

const ErrorCallout = (props: IProps) => (
  <Callout
    className={calloutStyles.callout}
    gapSpace={0}
    target={`#${props.buttonId}`}
    onDismiss={props.hideCallout}
    setInitialFocus={true}
  >
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img src="required-icon.png" style={{ width: '30px', marginRight: '10px' }} alt="required-icon" />
      <Text block variant="medium">
        Bitte ein Eintrag auswählen
      </Text>
    </div>
  </Callout>
);

export default ErrorCallout;
