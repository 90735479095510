export const attributes = {
  title: {
    label: 'Titel',
    name: 'title',
  },
  country: {
    label: 'Land',
    name: 'country',
  },
  city: {
    label: 'Stadt',
    name: 'city',
  },
  street: {
    label: 'Straße',
    name: 'street',
  },
  phone: {
    label: 'Telefon',
    name: 'phone',
  },
  mail: {
    label: 'E-Mail',
    name: 'mail',
  },
};

export interface IChurchDocument {
  id?: number;
  title: string;
  country: string;
  city: string;
  street: string;
  phone: string;
  mail: string;
}

export class Church implements IChurchDocument {
  title = '';

  country = '';

  city = '';

  street = '';

  phone = '';

  mail = '';
}
