export const attributes = {
  topicDe: {
    label: 'Überschrift - Deutsch',
    name: 'topicDe',
  },
  topicRu: {
    label: 'Überschrift - Russisch',
    name: 'topicRu',
  },
  de: {
    label: 'Text - Deutsch',
    name: 'de',
  },
  ru: {
    label: 'Text - Russisch',
    name: 'ru',
  },
};

export interface ITextDocument {
  id?: number;
  topicDe: string;
  topicRu: string;
  de: string;
  ru: string;
}

export class Text implements ITextDocument {
  topicDe = '';

  topicRu = '';

  de = '';

  ru = '';
}
