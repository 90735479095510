import { useState } from "react";
import { ApiService } from "../../../services/api.service";
import { contextSegments, FormMode } from "../../Controls/context-segments";
import { DynamicDetailsList } from "../../Controls/DetailsList/DynamicDetailsList";
import { columns } from "./good-info-columns";
import { transferItems } from "./good-info.dto";
import { IGoodInfoDocument } from "./good-info.type";
import GoodInfoForm from "./GoodInfoForm";

interface IProps {}

const apiCalls: ApiService = ApiService.getInstance();

const GoodInfo = (props: IProps) => {
  // Own state
  const [reload, setReload] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode>(FormMode.create);
  const [selectedItem, setSelectedItem] = useState<IGoodInfoDocument | null>(
    null
  );

  const closeModal = async () => {
    setIsModalOpen(false);
    setSelectedItem(null);
    setFormMode(FormMode.create);
  };

  const openItem = async (itemId: number) => {
    //   Fetch selected item by id
    const res = await apiCalls.getSingleGoodInfo(itemId);
    if (res?.status === 200) {
      setSelectedItem(res.data);
      setFormMode(FormMode.view);
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <GoodInfoForm
        isModalOpen={isModalOpen}
        formMode={formMode}
        selectedItem={selectedItem}
        setFormMode={setFormMode}
        closeModal={closeModal}
        setReload={setReload}
      />
      <DynamicDetailsList
        reload={reload}
        columns={columns}
        contextKey={contextSegments.goodInfo.contextKey}
        title={contextSegments.goodInfo.pluralName}
        transferItems={transferItems}
        setReload={setReload}
        showModal={() => setIsModalOpen(true)}
        openItem={openItem}
      />
    </>
  );
};

export default GoodInfo;
