import moment from "moment";
import "moment/locale/de";
import { IAddressDocument } from "../Address/address.type";
import { IDriverDocument } from "../Driver/driver.type";
import { IGoodInfoDocument } from "../GoodInfo/good-info.type";
import { ILoadingLocationDocument } from "../LoadingLocation/loading-location.type";
import { ITextDocument } from "../Text/text.type";
import { ITourDocument } from "../Tour/tour.type";
import { ITrailerDocument } from "../Trailer/trailer.type";
import { IVehicleDocument } from "../Vehicle/vehicle.type";
import { ICargoDocument } from "./Cargo/cargo.type";

export const attributes = {
  displayNumber: {
    label: "Nummer",
    name: "displayNumber",
  },
  loadingLocation: {
    label: "Ladeort",
    name: "loadingLocation",
  },
  seal: {
    label: "Plombe",
    name: "seal",
  },
  aeTrafficBranch: {
    label: "Verkehrszweig",
    name: "aeTrafficBranch",
  },
  date: {
    label: "Datum",
    name: "date",
  },
  transported: {
    label: "Transportiert",
    name: "transported",
  },
  tour: {
    label: "Tour",
    name: "tour",
  },
  drivers: {
    label: "Fahrer",
    name: "drivers",
  },
  certificateContent: {
    label: "Zertifikatinhalte",
    name: "certificateContent",
  },
  listContent: {
    label: "Listeninhalte",
    name: "listContent",
  },
  address: {
    label: "Adresse",
    name: "address",
  },
  goodInfo: {
    label: "Warennummer",
    name: "goodInfo",
  },
  vehicle: {
    label: "Fahrzeug",
    name: "vehicle",
  },
  trailer: {
    label: "Anhänger",
    name: "trailer",
  },
  sent: {
    label: "Verschickt",
    name: "sent",
  },
  layout: {
    label: "Layout",
    name: "layout",
  },
  sign: {
    label: "Unterschrieben",
    name: "sign",
  },
  commodityCode: {
    label: "Warencode",
    name: "commodityCode",
  },
  transportCosts: {
    label: "Transportkosten",
    name: "transportCosts",
  },
  notes: {
    label: "Notizen",
    name: "notes",
  },
  packType: {
    label: "Verpackungstyp",
    name: "packType",
  },
  registrationType: {
    label: "Registrierungstyp",
    name: "registrationType",
  },
};

export interface ICargolistDocument {
  id?: number;
  displayNumber: string;
  tour: ITourDocument | null;
  address: IAddressDocument | null;
  goodInfo: IGoodInfoDocument | null;
  vehicle: IVehicleDocument | null;
  trailer: ITrailerDocument | null;
  loadingLocation: ILoadingLocationDocument | null;
  drivers: IDriverDocument[];
  certificateContent: ITextDocument[];
  listContent: ITextDocument[];
  cargos: ICargoDocument[];
  aeTrafficBranch: number;
  date: string;
  seal: string;
  transported: string;
  sent: boolean;
  layout: boolean;
  sign: boolean;
  commodityCode: boolean;
  transportCosts: number;
  notes: string;
  packType: string;
  registrationType: string;
}

export class Cargolist implements ICargolistDocument {
  displayNumber = "";

  address = null;

  goodInfo = null;

  tour = null;

  vehicle = null;

  trailer = null;

  loadingLocation = null;

  sent = false;

  layout = true;

  sign = true;

  commodityCode = false;

  drivers = [];

  certificateContent = [];

  listContent = [];

  cargos = [];

  aeTrafficBranch = 3;

  date = moment().format("YYYY-MM-DD");

  seal = "";

  transported = "Spedition";

  transportCosts = 0;

  notes = "Haushaltsware, Kleidung, Sonstiges";

  packType = "NG";

  registrationType = "e";
}
