import { IVehicleDocument } from './vehicle.type';

export interface IVehicleDTO {
  id?: number;
  licensePlate: string;
  payload: number;
  countryCode: string;
  vehicleTypeId: number | null;
}

export const transferDTO = (item: IVehicleDocument): IVehicleDTO => ({
  id: item.id,
  licensePlate: item.licensePlate,
  payload: item.payload,
  countryCode: item.countryCode,
  vehicleTypeId: item.vehicleType?.id ? item.vehicleType.id : null,
});

export const transferItems = (item: IVehicleDocument, index: number) => ({
  key: `key-${index}`,
  id: item.id,
  licensePlate: item.licensePlate,
  payload: item.payload,
  countryCode: item.countryCode,
  vehicleType: item.vehicleType?.titleDe,
});
