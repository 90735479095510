export interface IOption {
  name: string;
  value: string;
}

export class Option {
  name: string;

  value: string;

  constructor(name: string, value: string) {
    this.name = name;
    this.value = value;
  }
}
