/* eslint-disable no-console */
import { IComboBoxOption } from "@fluentui/react";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { Buffer } from "buffer";
import FileSaver from "file-saver";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import {
  contextSegments,
  DocType,
  Format,
  IClient,
  IItemsFragment,
  Lang,
  NotificationTitle,
} from "../components/Controls/context-segments";
import { IAddressDocument } from "../components/Pages/Address/address.type";
import { ICargolistDTO } from "../components/Pages/Cargolist/cargolist.dto";
import { IChurchDocument } from "../components/Pages/Church/church.type";
import { IDriverDocument } from "../components/Pages/Driver/driver.type";
import { IGoodDTO } from "../components/Pages/Good/good.dto";
import { IGoodCategoryDocument } from "../components/Pages/GoodCategory/good-category.type";
import { IGoodConditionDocument } from "../components/Pages/GoodCondition/good-condition.type";
import { IGoodInfoDTO } from "../components/Pages/GoodInfo/good-info.dto";
import { IGoodUnitDocument } from "../components/Pages/GoodUnit/good-unit.type";
import { ILoadingLocationDTO } from "../components/Pages/LoadingLocation/loading-location.dto";
import { ITextDocument } from "../components/Pages/Text/text.type";
import { ITourDocument } from "../components/Pages/Tour/tour.type";
import { ITrailerDTO } from "../components/Pages/Trailer/trailer.dto";
import { ITrailerTypeDocument } from "../components/Pages/TrailerType/trailer-type.type";
import { IVehicleDTO } from "../components/Pages/Vehicle/vehicle.dto";
import { IVehicleTypeDocument } from "../components/Pages/VehicleType/vehicle-type.type";
import { error$ } from "../store/error";
import { IAccessToken } from "./atc";
import { ICredentials } from "./credentials";

export class ApiService {
  public static getInstance(): ApiService {
    if (!this.instance) {
      this.instance = new ApiService();
    }
    return this.instance;
  }

  private static instance: ApiService;

  public baseRequestURL: string;

  private promiseConfig = {
    pending: NotificationTitle.pending,
    success: NotificationTitle.success,
    error: NotificationTitle.error,
  };

  private tostifyConfig = {
    position: toast.POSITION.BOTTOM_RIGHT,
  };

  private constructor() {
    this.baseRequestURL = this.getApiURL();
  }

  public getApiURL(): string {
    let url: string = "";

    if (
      window.location.href.includes("localhost") &&
      process.env.REACT_APP_BACKEND_PORT
    ) {
      url = `http://localhost:${process.env.REACT_APP_BACKEND_PORT}/api`;
    } else if (
      !window.location.href.includes("localhost") &&
      process.env.REACT_APP_DOMAIN
    ) {
      url = `${process.env.REACT_APP_DOMAIN}/api`;
    }

    return url;
  }

  private jsonOptions(): AxiosRequestConfig {
    return {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${this.atc}`,
      },
    };
  }

  private docOptions(): AxiosRequestConfig {
    return {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/pdf",
        accept: "application/pdf",
        Authorization: `Bearer ${this.atc}`,
      },
    };
  }

  public async login(credentials: ICredentials) {
    return axios.post<{ atc: string }>(
      `${this.baseRequestURL}/auth/login`,
      credentials,
      this.jsonOptions()
    );
  }

  public logout() {
    sessionStorage.clear();
    window.location.href = "/";
  }

  public setToken(atc: string) {
    sessionStorage.setItem("atc", atc);
    window.location.href = "/";
  }

  get atc() {
    return sessionStorage.getItem("atc");
  }

  get user(): IAccessToken {
    return jwt_decode(this.atc!);
  }

  private getNamesByDocumentType(type: DocType) {
    let result: string = "";
    switch (type) {
      case DocType.cert:
        result = "Zertifikat";
        break;
      case DocType.list:
        result = "Ladeliste";
        break;
      case DocType.maut:
        result = "Mautbefreiung";
        break;
      case DocType.cmr:
        result = "CMR";
        break;
      case DocType.invoice:
        result = "Ausgabebeleg";
        break;
      case DocType.docs:
        result = "Dokumente";
        break;
      case DocType.customs:
        result = "Zollanmeldung";
        break;

      default:
        result = type;
        break;
    }
    return result;
  }

  public async getDocument(
    id: number,
    type: DocType,
    format: Format,
    fileName: string,
    lang?: Lang
  ) {
    try {
      fileName = `${fileName} - ${this.getNamesByDocumentType(type)} ${
        lang ? `- ${lang}` : ""
      }`;
      const res = await toast.promise(
        axios.get(
          `${this.getApiURL()}/${
            contextSegments.cargolist.apiKey
          }/${id}/${type}?format=${format}${lang ? `&lang=${lang}` : ""}`,
          this.docOptions()
        ),
        this.promiseConfig,
        this.tostifyConfig
      );

      if (format === Format.pdf) {
        this.openNewTab(res.data, fileName);
      } else if (format === Format.docx) {
        const blob = new Blob([res.data], { type: "text/docx;charset=utf8;" });
        FileSaver.saveAs(blob, `${fileName}.docx`);
      } else if (format === Format.xml) {
        const blob = new Blob([res.data], { type: "text/xml;charset=utf8;" });
        FileSaver.saveAs(blob, `${fileName}.xml`);
      } else if (format === Format.zip) {
        const blob = new Blob([res.data], {
          type: "application/zip;charset=utf8;",
        });
        FileSaver.saveAs(blob, `${fileName}.zip`);
      }
    } catch (e) {
      error$.next(e);
    }
  }

  private openNewTab(buffer: any, fileName: string) {
    const chunk: string = Buffer.from(buffer).toString("base64");

    const newTab = window.open("", "_blank");
    if (newTab) {
      setTimeout(() => {
        newTab.document.title = fileName;
      }, 100);
      let html = "";

      html += "<html>";
      html += '<body style="margin:0!important" >';
      html += `<embed width="100%" height="100%" src="data:application/pdf;base64,${chunk}" type="application/pdf" />`;
      html += "</body>";
      html += "</html>";

      setTimeout(() => {
        newTab.document.write(html);
      }, 0);
    }
  }

  public async downloadCsv(entity: IComboBoxOption) {
    try {
      const res = await toast.promise(
        axios.get(`${this.getApiURL()}/${entity.key}/csv`, this.docOptions()),
        this.promiseConfig,
        this.tostifyConfig
      );

      const blob = new Blob([res.data], { type: "text/csv;charset=utf8;" });
      FileSaver.saveAs(blob, `${entity.text}.csv`);
    } catch (e) {
      error$.next(e);
    }
  }

  public async getClient() {
    try {
      return await axios.get<IClient>(
        `${this.baseRequestURL}/provider`,
        this.jsonOptions()
      );
    } catch (e) {
      error$.next(e);
    }
  }

  public async getYearlyOverview(year: number) {
    try {
      return await axios.get(
        `${this.baseRequestURL}/${contextSegments.cargolist.apiKey}/overview/${year}`,
        this.jsonOptions()
      );
    } catch (e) {
      error$.next(e);
    }
  }
  public async getChartData() {
    try {
      return await axios.get(
        `${this.baseRequestURL}/${contextSegments.cargolist.apiKey}/assistance`,
        this.jsonOptions()
      );
    } catch (e) {
      error$.next(e);
    }
  }

  // Cargolist endpoints
  public async getCargolist(query: string) {
    try {
      return await this.getAllItems(
        `${contextSegments.cargolist.apiKey}`,
        query
      );
    } catch (e) {
      error$.next(e);
    }
  }

  public async getSingleCargolist(id: number) {
    try {
      return await this.getOneItem(`${contextSegments.cargolist.apiKey}`, id);
    } catch (e) {
      error$.next(e);
    }
  }

  public async createCargolist(payload: ICargolistDTO) {
    return toast.promise(
      this.createItem(`${contextSegments.cargolist.apiKey}`, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async updateCargolist(payload: ICargolistDTO, id: number) {
    return toast.promise(
      this.updateItem(`${contextSegments.cargolist.apiKey}`, id, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async deleteCargolist(id: number) {
    return toast.promise(
      this.deleteItem(`${contextSegments.cargolist.apiKey}`, id),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  // GoodInfo endpoints
  public async getGoodInfo(query: string) {
    try {
      return await this.getAllItems(
        `${contextSegments.goodInfo.apiKey}`,
        query
      );
    } catch (e) {
      error$.next(e);
    }
  }

  public async getSingleGoodInfo(id: number) {
    try {
      return await this.getOneItem(`${contextSegments.goodInfo.apiKey}`, id);
    } catch (e) {
      error$.next(e);
    }
  }

  public async createGoodInfo(payload: IGoodInfoDTO) {
    return toast.promise(
      this.createItem(`${contextSegments.goodInfo.apiKey}`, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async updateGoodInfo(payload: IGoodInfoDTO, id: number) {
    return toast.promise(
      this.updateItem(`${contextSegments.goodInfo.apiKey}`, id, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async deleteGoodInfo(id: number) {
    return toast.promise(
      this.deleteItem(`${contextSegments.goodInfo.apiKey}`, id),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  // GoodCondition endpoints
  public async getGoodCondition(query: string) {
    try {
      return await this.getAllItems(
        `${contextSegments.goodCondition.apiKey}`,
        query
      );
    } catch (e) {
      error$.next(e);
    }
  }

  public async getSingleGoodCondition(id: number) {
    try {
      return await this.getOneItem(
        `${contextSegments.goodCondition.apiKey}`,
        id
      );
    } catch (e) {
      error$.next(e);
    }
  }

  public async createGoodCondition(payload: IGoodConditionDocument) {
    return toast.promise(
      this.createItem(`${contextSegments.goodCondition.apiKey}`, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async updateGoodCondition(
    payload: IGoodConditionDocument,
    id: number
  ) {
    return toast.promise(
      this.updateItem(`${contextSegments.goodCondition.apiKey}`, id, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async deleteGoodCondition(id: number) {
    return toast.promise(
      this.deleteItem(`${contextSegments.goodCondition.apiKey}`, id),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  // LoadingLocation endpoints
  public async getLoadingLocation(query: string) {
    try {
      return await this.getAllItems(
        `${contextSegments.loadingLocation.apiKey}`,
        query
      );
    } catch (e) {
      error$.next(e);
    }
  }

  public async getSingleLoadingLocation(id: number) {
    try {
      return await this.getOneItem(
        `${contextSegments.loadingLocation.apiKey}`,
        id
      );
    } catch (e) {
      error$.next(e);
    }
  }

  public async createLoadingLocation(payload: ILoadingLocationDTO) {
    return toast.promise(
      this.createItem(`${contextSegments.loadingLocation.apiKey}`, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async updateLoadingLocation(payload: ILoadingLocationDTO, id: number) {
    return toast.promise(
      this.updateItem(`${contextSegments.loadingLocation.apiKey}`, id, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async deleteLoadingLocation(id: number) {
    return toast.promise(
      this.deleteItem(`${contextSegments.loadingLocation.apiKey}`, id),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  // Address endpoints
  public async getAddress(query: string) {
    return await this.getAllItems(`${contextSegments.address.apiKey}`, query);
  }

  public async getSingleAddress(id: number) {
    try {
      return await this.getOneItem(`${contextSegments.address.apiKey}`, id);
    } catch (e) {
      error$.next(e);
    }
  }

  public async createAddress(payload: IAddressDocument) {
    return toast.promise(
      this.createItem(`${contextSegments.address.apiKey}`, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async updateAddress(payload: IAddressDocument, id: number) {
    return toast.promise(
      this.updateItem(`${contextSegments.address.apiKey}`, id, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async deleteAddress(id: number) {
    return toast.promise(
      this.deleteItem(`${contextSegments.address.apiKey}`, id),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  // Good endpoints
  public async getGood(query: string) {
    try {
      return await this.getAllItems(`${contextSegments.good.apiKey}`, query);
    } catch (e) {
      error$.next(e);
    }
  }

  public async getSingleGood(id: number) {
    try {
      return await this.getOneItem(`${contextSegments.good.apiKey}`, id);
    } catch (e) {
      error$.next(e);
    }
  }

  public async createGood(payload: IGoodDTO) {
    return toast.promise(
      this.createItem(`${contextSegments.good.apiKey}`, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async updateGood(payload: IGoodDTO, id: number) {
    return toast.promise(
      this.updateItem(`${contextSegments.good.apiKey}`, id, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async deleteGood(id: number) {
    return toast.promise(
      this.deleteItem(`${contextSegments.good.apiKey}`, id),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  // GoodCategory endpoints
  public async getGoodCategory(query: string) {
    try {
      return await this.getAllItems(
        `${contextSegments.goodCategory.apiKey}`,
        query
      );
    } catch (e) {
      error$.next(e);
    }
  }

  public async getSingleGoodCategory(id: number) {
    try {
      return await this.getOneItem(
        `${contextSegments.goodCategory.apiKey}`,
        id
      );
    } catch (e) {
      error$.next(e);
    }
  }

  public async createGoodCategory(payload: IGoodCategoryDocument) {
    return toast.promise(
      this.createItem(`${contextSegments.goodCategory.apiKey}`, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async updateGoodCategory(payload: IGoodCategoryDocument, id: number) {
    return toast.promise(
      this.updateItem(`${contextSegments.goodCategory.apiKey}`, id, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async deleteGoodCategory(id: number) {
    return toast.promise(
      this.deleteItem(`${contextSegments.goodCategory.apiKey}`, id),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  // GoodUnit endpoints
  public async getGoodUnit(query: string) {
    try {
      return await this.getAllItems(
        `${contextSegments.goodUnit.apiKey}`,
        query
      );
    } catch (e) {
      error$.next(e);
    }
  }

  public async getSingleGoodUnit(id: number) {
    try {
      return await this.getOneItem(`${contextSegments.goodUnit.apiKey}`, id);
    } catch (e) {
      error$.next(e);
    }
  }

  public async createGoodUnit(payload: IGoodUnitDocument) {
    return toast.promise(
      this.createItem(`${contextSegments.goodUnit.apiKey}`, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async updateGoodUnit(payload: IGoodUnitDocument, id: number) {
    return toast.promise(
      this.updateItem(`${contextSegments.goodUnit.apiKey}`, id, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async deleteGoodUnit(id: number) {
    return toast.promise(
      this.deleteItem(`${contextSegments.goodUnit.apiKey}`, id),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  // Trailer endpoints
  public async getTrailer(query: string) {
    try {
      return await this.getAllItems(`${contextSegments.trailer.apiKey}`, query);
    } catch (e) {
      error$.next(e);
    }
  }

  public async getSingleTrailer(id: number) {
    try {
      return await this.getOneItem(`${contextSegments.trailer.apiKey}`, id);
    } catch (e) {
      error$.next(e);
    }
  }

  public async createTrailer(payload: ITrailerDTO) {
    return toast.promise(
      this.createItem(`${contextSegments.trailer.apiKey}`, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async updateTrailer(payload: ITrailerDTO, id: number) {
    return toast.promise(
      this.updateItem(`${contextSegments.trailer.apiKey}`, id, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async deleteTrailer(id: number) {
    return toast.promise(
      this.deleteItem(`${contextSegments.trailer.apiKey}`, id),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  // TrailerType endpoints
  public async getTrailerType(query: string) {
    try {
      return await this.getAllItems(
        `${contextSegments.trailerType.apiKey}`,
        query
      );
    } catch (e) {
      error$.next(e);
    }
  }

  public async getSingleTrailerType(id: number) {
    try {
      return await this.getOneItem(`${contextSegments.trailerType.apiKey}`, id);
    } catch (e) {
      error$.next(e);
    }
  }

  public async createTrailerType(payload: ITrailerTypeDocument) {
    return toast.promise(
      this.createItem(`${contextSegments.trailerType.apiKey}`, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async updateTrailerType(payload: ITrailerTypeDocument, id: number) {
    return toast.promise(
      this.updateItem(`${contextSegments.trailerType.apiKey}`, id, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async deleteTrailerType(id: number) {
    return toast.promise(
      this.deleteItem(`${contextSegments.trailerType.apiKey}`, id),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  // Vehicle endpoints
  public async getVehicle(query: string) {
    try {
      return await this.getAllItems(`${contextSegments.vehicle.apiKey}`, query);
    } catch (e) {
      error$.next(e);
    }
  }

  public async getSingleVehicle(id: number) {
    try {
      return await this.getOneItem(`${contextSegments.vehicle.apiKey}`, id);
    } catch (e) {
      error$.next(e);
    }
  }

  public async createVehicle(payload: IVehicleDTO) {
    return toast.promise(
      this.createItem(`${contextSegments.vehicle.apiKey}`, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async updateVehicle(payload: IVehicleDTO, id: number) {
    return toast.promise(
      this.updateItem(`${contextSegments.vehicle.apiKey}`, id, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async deleteVehicle(id: number) {
    return toast.promise(
      this.deleteItem(`${contextSegments.vehicle.apiKey}`, id),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  // VehicleType endpoints
  public async getVehicleType(query: string) {
    try {
      return await this.getAllItems(
        `${contextSegments.vehicleType.apiKey}`,
        query
      );
    } catch (e) {
      error$.next(e);
    }
  }

  public async getSingleVehicleType(id: number) {
    try {
      return await this.getOneItem(`${contextSegments.vehicleType.apiKey}`, id);
    } catch (e) {
      error$.next(e);
    }
  }

  public async createVehicleType(payload: IVehicleTypeDocument) {
    return toast.promise(
      this.createItem(`${contextSegments.vehicleType.apiKey}`, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async updateVehicleType(payload: IVehicleTypeDocument, id: number) {
    return toast.promise(
      this.updateItem(`${contextSegments.vehicleType.apiKey}`, id, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async deleteVehicleType(id: number) {
    return toast.promise(
      this.deleteItem(`${contextSegments.vehicleType.apiKey}`, id),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  // Driver endpoints
  public async getDriver(query: string) {
    try {
      return await this.getAllItems(`${contextSegments.driver.apiKey}`, query);
    } catch (e) {
      error$.next(e);
    }
  }

  public async getSingleDriver(id: number) {
    try {
      return await this.getOneItem(`${contextSegments.driver.apiKey}`, id);
    } catch (e) {
      error$.next(e);
    }
  }

  public async createDriver(payload: IDriverDocument) {
    return toast.promise(
      this.createItem(`${contextSegments.driver.apiKey}`, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async updateDriver(payload: IDriverDocument, id: number) {
    return toast.promise(
      this.updateItem(`${contextSegments.driver.apiKey}`, id, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async deleteDriver(id: number) {
    return toast.promise(
      this.deleteItem(`${contextSegments.driver.apiKey}`, id),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  // Church endpoints
  public async getChurch(query: string) {
    try {
      return await this.getAllItems(`${contextSegments.church.apiKey}`, query);
    } catch (e) {
      error$.next(e);
    }
  }

  public async getSingleChurch(id: number) {
    try {
      return await this.getOneItem(`${contextSegments.church.apiKey}`, id);
    } catch (e) {
      error$.next(e);
    }
  }

  public async createChurch(payload: IChurchDocument) {
    return toast.promise(
      this.createItem(`${contextSegments.church.apiKey}`, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async updateChurch(payload: IChurchDocument, id: number) {
    return toast.promise(
      this.updateItem(`${contextSegments.church.apiKey}`, id, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async deleteChurch(id: number) {
    return toast.promise(
      this.deleteItem(`${contextSegments.church.apiKey}`, id),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  // Tour endpoints
  public async getTour(query: string) {
    try {
      return await this.getAllItems(`${contextSegments.tour.apiKey}`, query);
    } catch (e) {
      error$.next(e);
    }
  }

  public async getSingleTour(id: number) {
    try {
      return await this.getOneItem(`${contextSegments.tour.apiKey}`, id);
    } catch (e) {
      error$.next(e);
    }
  }

  public async createTour(payload: ITourDocument) {
    return toast.promise(
      this.createItem(`${contextSegments.tour.apiKey}`, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async updateTour(payload: ITourDocument, id: number) {
    return toast.promise(
      this.updateItem(`${contextSegments.tour.apiKey}`, id, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async deleteTour(id: number) {
    return toast.promise(
      this.deleteItem(`${contextSegments.tour.apiKey}`, id),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  // Text endpoints
  public async getText(query: string) {
    try {
      return await this.getAllItems(`${contextSegments.text.apiKey}`, query);
    } catch (e) {
      error$.next(e);
    }
  }

  public async getSingleText(id: number) {
    try {
      return await this.getOneItem(`${contextSegments.text.apiKey}`, id);
    } catch (e) {
      error$.next(e);
    }
  }

  public async createText(payload: ITextDocument) {
    return toast.promise(
      this.createItem(`${contextSegments.text.apiKey}`, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async updateText(payload: ITextDocument, id: number) {
    return toast.promise(
      this.updateItem(`${contextSegments.text.apiKey}`, id, payload),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  public async deleteText(id: number) {
    return toast.promise(
      this.deleteItem(`${contextSegments.text.apiKey}`, id),
      this.promiseConfig,
      this.tostifyConfig
    );
  }

  // Actions

  /**
   * Does execute GET Request
   * @param relativeUrl
   */
  private async getAllItems(
    relativeUrl: string,
    query: string
  ): Promise<AxiosResponse<IItemsFragment>> {
    return await axios.get(
      `${this.baseRequestURL}/${relativeUrl}?${query || ""}`,
      this.jsonOptions()
    );
  }

  /**
   * Does execute GET Request to get a single item
   * @param relativeUrl
   * @param id of the desired item
   */
  private async getOneItem(
    relativeUrl: string,
    id: number
  ): Promise<AxiosResponse<any>> {
    return await axios.get(
      `${this.baseRequestURL}/${relativeUrl}/${id}`,
      this.jsonOptions()
    );
  }

  /**
   * Does execute POST Request
   * @param relativeUrl
   * @param payload of the request
   */
  private async createItem(
    relativeUrl: string,
    payload: any
  ): Promise<AxiosResponse<any>> {
    return axios.post(
      `${this.baseRequestURL}/${relativeUrl}`,
      payload,
      this.jsonOptions()
    );
  }

  /**
   * Does execute PUT Request
   * @param relativeUrl
   * @param id of the item
   * @param payload of the request
   */
  private async updateItem(
    relativeUrl: string,
    id: number,
    payload: any
  ): Promise<AxiosResponse<any>> {
    return await axios.put(
      `${this.baseRequestURL}/${relativeUrl}/${id}`,
      payload,
      this.jsonOptions()
    );
  }

  /**
   * Does execute PUT Request
   * @param relativeUrl
   * @param id of the item
   */
  private async deleteItem(
    relativeUrl: string,
    id: number
  ): Promise<AxiosResponse<any>> {
    return await axios.delete(
      `${this.baseRequestURL}/${relativeUrl}/${id}`,
      this.jsonOptions()
    );
  }
}
