/* eslint-disable no-multi-assign */
import { Label, Spinner, SpinnerSize } from "@fluentui/react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import * as React from "react";

interface IProps {
  isLoading: boolean;
  options: any;
}

const Chart = (props: IProps) => {
  return props.isLoading ? (
    <div className="loading-container">
      <div>
        <Label>Wird geladen...</Label>
        <Spinner size={SpinnerSize.large} />
      </div>
    </div>
  ) : (
    <HighchartsReact highcharts={Highcharts} {...props} />
  );
};
export default Chart;
