import { ITourDocument } from './tour.type';

export interface ITourDTO {
  id?: number;
  title: string;
}

export const transferDTO = (item: ITourDocument): ITourDTO => ({
  id: item.id,
  title: item.title,
});

export const transferItems = (item: ITourDocument, index: number) => ({
  key: `key-${index}`,
  id: item.id,
  title: item.title,
  cargolists: item.cargolists?.length,
});
