/* eslint-disable react/no-unused-prop-types */
import { CommandBar, ICommandBarItemProps, TextField } from "@fluentui/react";
import * as React from "react";
import { useEffect, useState } from "react";
import { addIcon } from "../component-styles";
import { FormMode } from "../context-segments";

interface IProps {
  reload: boolean;
  cargoListFormMode?: FormMode;
  onChangeSearch: (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => void;
  toggleHideDeleteDialog: () => void;
  showModal: () => void;
}
export const DynamicListCommandBar = (props: IProps) => {
  // Own state
  const [search, setSearch] = useState<string>();

  useEffect(() => {
    // Reset search in case some changes occurs
    setSearch("");
  }, [props.reload]);

  const items: ICommandBarItemProps[] =
    props.cargoListFormMode === FormMode.view
      ? []
      : [
          {
            key: "newItem",
            text: "Hinzufügen",
            iconProps: addIcon,
            onClick: () => props.showModal(),
          },
          {
            key: "deleteItem",
            text: "Löschen",
            iconProps: { iconName: "Delete" },
            onClick: () => props.toggleHideDeleteDialog(),
          },
        ];

  const farItems: ICommandBarItemProps[] = [
    {
      key: "search",
      onRender: () => (
        <TextField
          value={search}
          placeholder="Suche..."
          ariaLabel="Text"
          onChange={(
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string | undefined
          ) => {
            setSearch(newValue);

            setTimeout(() => {
              props.onChangeSearch(event, newValue);
            }, 300);
          }}
        />
      ),
    },
  ];

  return (
    <div>
      <CommandBar
        items={items}
        farItems={farItems}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
    </div>
  );
};
