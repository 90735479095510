import { IconButton, Label, Modal, TextField } from "@fluentui/react";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { ApiService } from "../../../services/api.service";
import { HelperService } from "../../../services/helper.service";
import {
  cancelIcon,
  contentStyles,
  iconButtonStyles,
  labelStyles,
  valueStyles,
} from "../../Controls/component-styles";
import {
  contextSegments,
  FormMode,
  InputType,
} from "../../Controls/context-segments";
import DeleteDialog from "../../Controls/Dialogs/DeleteDialog";
import Dropdown from "../../Controls/Dropdown/Dropdown";
import { Option } from "../../Controls/Dropdown/option";
import { DynamicFormCommandBar } from "../../Controls/DynamicFormCommandBar/DynamicFormCommandBar";
import { IVehicleTypeDocument } from "../VehicleType/vehicle-type.type";
import VehicleTypeForm from "../VehicleType/VehicleTypeForm";
import { IVehicleDTO, transferDTO } from "./vehicle.dto";
import { attributes, IVehicleDocument, Vehicle } from "./vehicle.type";

interface IProps {
  isModalOpen: boolean;
  selectedItem: IVehicleDocument | null;
  formMode: FormMode;
  fkName?: string;
  closeModal: () => void;
  setFormMode: (formMode: FormMode) => void;
  setReload?: (reload: boolean) => void;
  setCreatedItem?: (e: any, newItem: any) => void;
}
const helperService = HelperService.getInstance();

const apiCalls: ApiService = ApiService.getInstance();
const formName: string = "vehicle-form";

const VehicleForm = (props: IProps) => {
  // Own state
  const [payload, setPayload] = useState<IVehicleDocument>(new Vehicle());
  const [hideDeleteDialog, toggleHideDeleteDialog] = useState<boolean>(true);

  //   VehicleType
  const [isVehicleTypeModalOpen, setIsVehicleTypeModalOpen] =
    useState<boolean>(false);
  const [vehicleTypeFormMode, setVehicleTypeFormMode] = useState<FormMode>(
    FormMode.create
  );
  const [selectedVehicleTypeItem, setSelectedVehicleTypeItem] =
    useState<IVehicleTypeDocument | null>(null);
  const [
    shouldSetDefaultVehicleTypeOption,
    setShouldSetDefaultVehicleTypeOption,
  ] = useState<boolean>(true);

  const closeVehicleTypeModal = async () => {
    setIsVehicleTypeModalOpen(false);
    setSelectedVehicleTypeItem(null);
    setVehicleTypeFormMode(FormMode.create);
  };

  const setVehicleTypeOption = async (e: any, newValue: any) => {
    handleOnChange(e, newValue);
    // Signal that the item need to be preselected in the dropdown
    setShouldSetDefaultVehicleTypeOption(true);
  };

  const formHeading: string = helperService.getFormHeading(
    contextSegments.vehicle.singularName,
    props.formMode
  );

  useEffect(() => {
    // In case some item was passed to the form
    // Set this item to the payload
    if (props.selectedItem) {
      setPayload(props.selectedItem);
    }
    // In case no item was passed to the form
    // Set default empty payload
    else {
      setPayload(new Vehicle());
      setShouldSetDefaultVehicleTypeOption(true);
    }
    // Clear the input when closing the modal
    if (!props.isModalOpen) {
      setPayload(new Vehicle());
    }
  }, [props.selectedItem, props.isModalOpen]);

  const titleId = helperService.makeId(10);

  /**
   * Invoke create action
   * @param e
   */
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const payloadDto: IVehicleDTO = transferDTO(payload);

    // In case payload id exist
    // Update the item
    if (payload.id) {
      apiCalls
        .updateVehicle(payloadDto, payload.id)
        .then((res: AxiosResponse<any>) => {
          props.setReload && props.setReload(true);

          props.closeModal();
          setPayload(new Vehicle());
        });
    }
    // In case no payload id exist
    // Create a new item
    else {
      apiCalls.createVehicle(payloadDto).then((res: AxiosResponse<any>) => {
        //   In case creating an item through the dropdown context
        if (props.setCreatedItem && props.fkName && res.data.id) {
          const fakeEvent = {
            target: {
              name: props.fkName,
              type: "dropdown",
            },
          };
          const newItem: IVehicleDocument = {
            id: res.data["id"],
            licensePlate: res.data["licensePlate"],
            payload: res.data["payload"],
            countryCode: res.data["countryCode"],
            vehicleType: res.data["vehicleType"],
          };
          props.setCreatedItem(fakeEvent, newItem);
        }
        props.setReload && props.setReload(true);

        props.closeModal();
        setPayload(new Vehicle());
      });
    }
  };

  /**
   * Write newValue to state by element name
   * @param e
   * @param newValue
   */
  const handleOnChange = (e: any, newValue: any) => {
    const { name } = e.target;

    if (newValue === undefined) {
      setPayload({ ...payload, [name]: null });
    } else {
      const inputType: string = e.target.type;
      if (inputType === InputType.text || inputType === InputType.textarea) {
        setPayload({ ...payload, [name]: String(newValue) });
      } else if (inputType === InputType.number) {
        setPayload({ ...payload, [name]: Number(newValue) });
      } else if (inputType === InputType.checkbox) {
        setPayload({ ...payload, [name]: Boolean(newValue) });
      } else if (inputType === InputType.dropdown) {
        setPayload({ ...payload, [name]: newValue });
      }
    }
  };
  const isEditable: boolean =
    props.formMode === FormMode.create || props.formMode === FormMode.edit;
  const deleteOpenedItem = async () => {
    toggleHideDeleteDialog(true);
    props.closeModal();
    await apiCalls.deleteVehicle(props.selectedItem!.id!);
    props.setReload && props.setReload(true);
  };

  return (
    <Modal
      titleAriaId={titleId}
      isOpen={props.isModalOpen}
      onDismiss={props.closeModal}
      isBlocking={false}
      containerClassName={contentStyles.container}
    >
      <DeleteDialog
        toggleHideDeleteDialog={toggleHideDeleteDialog}
        hideDeleteDialog={hideDeleteDialog}
        selectedItemsCount={1}
        deleteSelectedItems={deleteOpenedItem}
      />
      <div className={contentStyles.header}>
        <span id={titleId}>{formHeading} </span>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={props.closeModal}
        />
      </div>
      <div className={contentStyles.body} style={{ minHeight: "400px" }}>
        {isEditable && props.isModalOpen ? (
          <form id={formName} onSubmit={onSubmit}>
            <DynamicFormCommandBar
              formMode={props.formMode}
              toggleHideDeleteDialog={toggleHideDeleteDialog}
              closeModal={props.closeModal}
              formName={formName}
            />
            <Dropdown
              shouldSetDefaultOption={shouldSetDefaultVehicleTypeOption}
              isMulti={false}
              contextKey={contextSegments.vehicleType.contextKey}
              optionKey={contextSegments.vehicleType.displayAttribute}
              attributeName={attributes.vehicleType.name}
              label={attributes.vehicleType.label}
              setOption={setVehicleTypeOption}
              setShouldSetDefaultOption={setShouldSetDefaultVehicleTypeOption}
              showModal={() => setIsVehicleTypeModalOpen(true)}
              defaultOption={
                new Option(
                  String(payload.vehicleType?.titleDe),
                  String(payload.vehicleType?.id)
                )
              }
            />
            <TextField
              name={attributes.licensePlate.name}
              label={attributes.licensePlate.label}
              required
              type="text"
              onChange={handleOnChange}
              defaultValue={payload.licensePlate}
            />

            <TextField
              name={attributes.countryCode.name}
              label={attributes.countryCode.label}
              type="text"
              onChange={handleOnChange}
              defaultValue={payload.countryCode}
            />
            <TextField
              name={attributes.payload.name}
              label={attributes.payload.label}
              suffix="Kg"
              type="number"
              onChange={handleOnChange}
              defaultValue={String(payload.payload)}
            />
          </form>
        ) : (
          <div style={{ minHeight: "400px" }}>
            <DynamicFormCommandBar
              formMode={props.formMode}
              toggleHideDeleteDialog={toggleHideDeleteDialog}
              formName={formName}
              setFormMode={props.setFormMode}
              closeModal={props.closeModal}
            />
            <Label styles={labelStyles}>Fahrzeugart</Label>
            <span style={valueStyles}>{payload.vehicleType?.titleDe}</span>
            <Label styles={labelStyles}>Kennzeichen</Label>
            <span style={valueStyles}>{payload.licensePlate}</span>
            <Label styles={labelStyles}>Landcode</Label>
            <span style={valueStyles}>{payload.countryCode}</span>
            <Label styles={labelStyles}>Nutzlast</Label>
            <span style={valueStyles}>{payload.payload} Kg</span>
          </div>
        )}
      </div>
      {/* Additional Forms */}
      <VehicleTypeForm
        isModalOpen={isVehicleTypeModalOpen}
        formMode={vehicleTypeFormMode}
        selectedItem={selectedVehicleTypeItem}
        fkName={attributes.vehicleType.name}
        setFormMode={setVehicleTypeFormMode}
        closeModal={closeVehicleTypeModal}
        setCreatedItem={setVehicleTypeOption}
      />
    </Modal>
  );
};

export default VehicleForm;
