import { useEffect, useState } from "react";
import { ApiService } from "../../../services/api.service";
import Chart from "./Chart/Chart";
import { options as defaultOptions } from "./Chart/chart-options";
import { Export } from "./Export/Export";

const apiCalls = ApiService.getInstance();

const Home = () => {
  const [options, setOptions] = useState<any>(null);
  const [overview, setOverview] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      const chartData = await apiCalls.getChartData();
      const yearlyOverview = await apiCalls.getYearlyOverview(
        new Date().getFullYear()
      );

      if (chartData?.status === 200 && yearlyOverview?.status === 200) {
        const startRage: number = new Date().getFullYear();
        const endRange = startRage - 9;
        defaultOptions.plotOptions.series.pointStart = endRange;
        defaultOptions.xAxis.title.text = `Zeitrahmen ${endRange} - ${startRage}`;
        defaultOptions.series = chartData.data;

        setOptions(defaultOptions);
        setOverview(yearlyOverview.data);

        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div style={{ flex: "1 1 90%" }}>
      <Export isLoading={isLoading} overview={overview} />
      <Chart isLoading={isLoading} options={options} />
    </div>
  );
};

export default Home;
