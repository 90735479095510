import {
  ContextualMenu,
  IContextualMenuItem,
  ITextProps,
  Text,
} from "@fluentui/react";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../../services/api.service";
import { leaveIcon } from "../../Controls/component-styles";
import { IClient } from "../../Controls/context-segments";
import "./Header.scss";
interface IProps {}

const apiCalls: ApiService = ApiService.getInstance();

const menuItems: IContextualMenuItem[] = [
  {
    key: "logout",
    text: "Abmelden",
    iconProps: leaveIcon,
    onClick: () => apiCalls.logout(),
  },
];

const Header = (props: IProps) => {
  const [provider, setProvider] = useState<IClient>();
  const fullName: string = `${apiCalls.user.firstNameDe} ${apiCalls.user.lastNameDe}`;
  const { mail, titleDe } = apiCalls.user;
  const navigate = useNavigate();

  const ref = React.useRef(null);
  const [showContextualMenu, setShowContextualMenu] = React.useState(false);

  const onShowContextualMenu = React.useCallback(
    (ev: React.MouseEvent<HTMLElement>) => {
      ev.preventDefault(); // don't navigate
      setShowContextualMenu(true);
    },
    []
  );

  const onHideContextualMenu = React.useCallback(
    () => setShowContextualMenu(false),
    []
  );

  useEffect(() => {
    apiCalls.getClient().then((res) => {
      if (res?.status === 200) {
        setProvider(res?.data);
      }
    });
  }, []);

  return (
    <>
      <nav className="header">
        {provider ? (
          <div onClick={() => navigate("/home")} className="header-client">
            <Text block variant={"xxLarge" as ITextProps["variant"]}>
              {provider.title}
            </Text>
            <Text block variant={"small" as ITextProps["variant"]}>
              {`${provider.society} ${provider.location}`}
            </Text>
          </div>
        ) : (
          <div />
        )}
        <Text block variant={"medium" as ITextProps["variant"]}>
          Trachtet vielmehr nach dem Reich Gottes, so wird euch dies alles
          hinzugefügt werden!
        </Text>

        <div onClick={onShowContextualMenu} ref={ref} className="user">
          <Text block variant={"xSmall" as ITextProps["variant"]}>
            {titleDe}
          </Text>
          <Text block variant={"xLarge" as ITextProps["variant"]}>
            {fullName}
          </Text>
          <Text block variant={"xSmall" as ITextProps["variant"]}>
            {mail}
          </Text>
          <ContextualMenu
            items={menuItems}
            hidden={!showContextualMenu}
            target={ref}
            onItemClick={onHideContextualMenu}
            onDismiss={onHideContextualMenu}
          />
        </div>
      </nav>
    </>
  );
};

export default Header;
