import { useState } from "react";
import { ApiService } from "../../../services/api.service";
import { contextSegments, FormMode } from "../../Controls/context-segments";
import { DynamicDetailsList } from "../../Controls/DetailsList/DynamicDetailsList";
import { columns } from "./vehicle-type-columns";
import { IVehicleTypeDocument } from "./vehicle-type.type";
import VehicleTypeForm from "./VehicleTypeForm";

interface IProps {}

const apiCalls: ApiService = ApiService.getInstance();

const VehicleType = (props: IProps) => {
  // Own state
  const [reload, setReload] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode>(FormMode.create);
  const [selectedItem, setSelectedItem] = useState<IVehicleTypeDocument | null>(
    null
  );

  const closeModal = async () => {
    setIsModalOpen(false);
    setSelectedItem(null);
    setFormMode(FormMode.create);
  };

  const openItem = async (itemId: number) => {
    //   Fetch selected item by id
    const res = await apiCalls.getSingleVehicleType(itemId);
    if (res?.status === 200) {
      setSelectedItem(res.data);
      setFormMode(FormMode.view);
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <VehicleTypeForm
        isModalOpen={isModalOpen}
        formMode={formMode}
        selectedItem={selectedItem}
        setFormMode={setFormMode}
        closeModal={closeModal}
        setReload={setReload}
      />
      <DynamicDetailsList
        reload={reload}
        columns={columns}
        contextKey={contextSegments.vehicleType.contextKey}
        title={contextSegments.vehicleType.pluralName}
        setReload={setReload}
        showModal={() => setIsModalOpen(true)}
        openItem={openItem}
      />
    </>
  );
};

export default VehicleType;
