import { IColumn } from "@fluentui/react";
import { HelperService } from "../../../services/helper.service";
import { attributes } from "./loading-location.type";

const helperService: HelperService = HelperService.getInstance();

export const columns: IColumn[] = [
  {
    key: helperService.makeId(10),
    name: "Ansehen",
    fieldName: "edit",
    minWidth: 60,
    maxWidth: 70,
  },
  {
    key: helperService.makeId(10),
    name: attributes.city.label,
    fieldName: attributes.city.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.country.label,
    fieldName: attributes.country.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.street.label,
    fieldName: attributes.street.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.zip.label,
    fieldName: attributes.zip.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.contactPerson.label,
    fieldName: attributes.contactPerson.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.mail.label,
    fieldName: attributes.mail.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.phone.label,
    fieldName: attributes.phone.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.fax.label,
    fieldName: attributes.fax.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.exportCustomsOffice.label,
    fieldName: attributes.exportCustomsOffice.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.originCountry.label,
    fieldName: attributes.originCountry.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.code.label,
    fieldName: attributes.code.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
];
