import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  ITextProps,
  Label,
  MarqueeSelection,
  Selection,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Text,
  ThemeProvider,
} from "@fluentui/react";
import * as React from "react";
import { ApiService } from "../../../services/api.service";
import { HelperService } from "../../../services/helper.service";
import { IItemsFragment } from "../context-segments";
import DeleteDialog from "../Dialogs/DeleteDialog";
import "./DynamicDetailsList.scss";
import { DynamicListCommandBar } from "./DynamicListCommandBar";

export interface IState {
  columns: IColumn[];
  items: any[];
  currentPage: number;
  search: string;
  hideDeleteDialog: boolean;
  allowCallOnScroll: boolean;
  isLoading: boolean;
  isLastPage: boolean;
  sort: string;
}

interface IProps {
  reload: boolean;
  columns: IColumn[];
  title: string;
  contextKey: string;
  showModal: () => void;
  openItem: (itemId: number) => void;
  transferItems?: (item: any, index: number) => any;
  setReload: (flag: boolean) => any;
}

export class DynamicDetailsList extends React.Component<IProps, IState> {
  private limit: number = 30;

  private selection: Selection;
  public apiCalls: any = ApiService.getInstance();

  public helperService: any = HelperService.getInstance();

  private currentColumns: IColumn[];

  constructor(props: IProps) {
    super(props);

    this.currentColumns = this.generateColumns();

    this.selection = new Selection();

    this.state = {
      items: [],
      currentPage: 1,
      isLastPage: false,
      search: "",
      sort: "",
      columns: this.currentColumns,
      hideDeleteDialog: true,
      allowCallOnScroll: true,
      isLoading: true,
    };

    this.toggleHideDeleteDialog = this.toggleHideDeleteDialog.bind(this);
    this.generateColumns = this.generateColumns.bind(this);
    this.onColumnClick = this.onColumnClick.bind(this);
    this.deleteSelectedItems = this.deleteSelectedItems.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.handleOnScroll = this.handleOnScroll.bind(this);
    this.getItemsFragment = this.getItemsFragment.bind(this);
    this.getItems = this.getItems.bind(this);
    this.isSearchBoolean = this.isSearchBoolean.bind(this);
    this.getBooleanSearch = this.getBooleanSearch.bind(this);
    this.isSearchDate = this.isSearchDate.bind(this);
    this.getDateSearch = this.getDateSearch.bind(this);
  }

  componentDidMount() {
    // Fetch initial items and set items to state
    const query: string = `limit=${this.limit}&page=${this.state.currentPage}`;
    this.getItemsFragment(query).then((res) => {
      if (res?.status === 200) {
        this.setState((prevState) => ({
          items: this.getItems(res.data),
          currentPage: 1,
          isLastPage: res.data.isLastPage,
          //   Allow fetch next items
          allowCallOnScroll: true,
        }));
      }
    });
  }

  static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
    // Loading interaction
    if (prevState.isLoading && prevState.items.length >= 0) {
      return {
        isLoading: false,
      };
    }

    // Does not update the state, if no condition matched
    return null;
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.props.reload) {
      this.props.setReload(false);
      const query: string = `limit=${this.limit}&page=1`;
      this.getItemsFragment(query).then((res) => {
        if (res?.status === 200) {
          this.setState({
            items: this.getItems(res.data),
            currentPage: 1,
            isLastPage: res.data.isLastPage,
            search: "",
            //   Allow fetch next items
            allowCallOnScroll: true,
          });
        }
      });
    }
  }

  /**
   * Gets triggered on any scroll of the user and does call the fetch action by certain condition
   * @param event onScroll event
   */
  private handleOnScroll(event: React.UIEvent<HTMLDivElement>) {
    const el = document.getElementById("fabric-list-container");
    const { currentPage, isLastPage, search, allowCallOnScroll, sort } =
      this.state;

    // In case container exist
    if (el) {
      const scrollPosition: number = el.scrollTop + el.offsetHeight;
      const scrollHight: number = el.scrollHeight;
      const triggerPosition: number = scrollHight / 2;

      // If user scrolls over the trigger part of the scrollbar, fetch the next items
      if (scrollPosition >= triggerPosition && allowCallOnScroll) {
        // Prevent fetch next items
        this.setState({ allowCallOnScroll: false });
        // Build query
        let query: string = `limit=${this.limit}`;

        // In case some next page exist
        // Paste next page parameter with its value in the query
        if (!isLastPage) {
          const pageQuery: string = `&page=${currentPage + 1}`;
          query += pageQuery;
          query += sort;
        }

        // In case some search exist
        if (search.length > 0) {
          // Paste the search parameter with its value in the query
          const searchQuery: string = `&search=${search}`;
          query += searchQuery;
        }

        // In case not all items were fetched
        if (!isLastPage) {
          this.getItemsFragment(query).then((res) => {
            this.setState((prevState) => ({
              items: [...prevState.items, ...this.getItems(res.data)],
              currentPage: prevState.currentPage + 1,
              isLastPage: res.data.isLastPage,
              //   Allow fetch next items
              allowCallOnScroll: true,
            }));
          });
        }
      }
    }
  }

  private isSearchBoolean(search: string): boolean {
    if (search.toLowerCase() === "nein" || search.toLowerCase() === "ja") {
      return true;
    }
    return false;
  }

  private isSearchDate(search: string): boolean {
    const fullDateReg = /^\d{2}([.])\d{2}\1\d{4}$/;
    const dayMonthReg = /^\d{2}([.])\d{2}$/;
    if (fullDateReg.exec(search) || dayMonthReg.exec(search)) {
      return true;
    }
    return false;
  }

  private getDateSearch(search: string): string {
    const date: string[] = search.split(".");
    if (date.length === 2) {
      return `${date[1]}-${date[0]}`;
    }
    return `${date[2]}-${date[1]}-${date[0]}`;
  }

  private getBooleanSearch(search: string): boolean {
    if (search.toLowerCase() === "nein") {
      return false;
    }
    return true;
  }

  /**
   * Search for items by text
   * @param event
   * @param search Current input value
   */
  public onChangeSearch = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    search?: string
  ): void => {
    let query: string = `limit=${this.limit}`;

    query += `&page=1`;

    // In case some search exist
    if (search && search.length > 0) {
      this.resetColumns(this.state.columns);
      // In case search is ja/nein
      if (this.isSearchBoolean(search)) {
        const searchQuery: string = `&search=${this.getBooleanSearch(search)}`;
        query += searchQuery;
        // Set the current search to state
        this.setState({ search });
        this.getItemsFragment(query).then((res) => {
          this.setState((prevState) => ({
            items: this.getItems(res.data),
            currentPage: 1,
            isLastPage: res.data.isLastPage,
            //   Allow fetch next items
            allowCallOnScroll: true,
          }));
        });
      }
      // In case search is date
      else if (this.isSearchDate(search)) {
        const searchQuery: string = `&search=${this.getDateSearch(search)}`;
        query += searchQuery;
        // Set the current search to state
        this.setState({ search });
        this.getItemsFragment(query).then((res) => {
          this.setState((prevState) => ({
            items: this.getItems(res.data),
            currentPage: 1,
            isLastPage: res.data.isLastPage,
            //   Allow fetch next items
            allowCallOnScroll: true,
          }));
        });
      }
      // In other case
      else {
        const searchQuery: string = `&search=${search}`;
        query += searchQuery;
        // Set the current search to state
        this.setState({ search });
        this.getItemsFragment(query).then((res) => {
          this.setState((prevState) => ({
            items: this.getItems(res.data),
            currentPage: 1,
            isLastPage: res.data.isLastPage,
            //   Allow fetch next items
            allowCallOnScroll: true,
          }));
        });
      }
    }
    // In case search empty
    else {
      // Set the current search to state
      this.setState({ search: "" });
      this.getItemsFragment(query).then((res) => {
        this.setState((prevState) => ({
          items: this.getItems(res.data),
          currentPage: 1,
          isLastPage: res.data.isLastPage,
          //   Allow fetch next items
          allowCallOnScroll: true,
        }));
      });
    }
  };

  private resetColumns(columns: IColumn[]) {
    return columns.forEach((col: IColumn) => {
      this.setState({ sort: "" });
      col.isSorted = false;
      col.isSortedDescending = true;
    });
  }

  /**
   * Does sort items by clicked column
   * @param ev
   * @param column
   */
  private onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns } = this.state;
    let query: string = `limit=${this.limit}&page=1&search=${this.state.search}`;
    let isDesc: boolean = false;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];

    // Set column animation
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        isDesc = !currColumn.isSortedDescending;
        currColumn.isSortedDescending = isDesc;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    // Fetch sorted items
    const sortDirection: string = isDesc ? "DESC" : "ASC";
    const sort: string = `&sort=[${currColumn.fieldName}]:${sortDirection}`;
    query += sort;
    this.getItemsFragment(query).then((res) => {
      this.setState({
        items: this.getItems(res.data),
        currentPage: 1,
        isLastPage: res.data.isLastPage,
        columns: newColumns,
        sort,
        // Allow fetch next items
        allowCallOnScroll: true,
      });
    });
  };

  /**
   * Converts items for the list
   * @param res Response from the api
   * @returns {any[]} An array of custom items
   */
  private getItems(res: IItemsFragment): any[] {
    return res.items.map((i, index: number) =>
      this.props.transferItems ? this.props.transferItems(i, index) : i
    );
  }

  /**
   * Constructs an items fragment
   * @param query A Query which will be passed to the api call
   * @returns {Promise<IItemsFragment>}
   */
  private async getItemsFragment(query: string) {
    return await this.apiCalls[`get${this.props.contextKey}`](query);
  }

  /**
   * Does generate columns
   * Add sorting function to each column
   */
  private generateColumns = (): IColumn[] => {
    const currentColumns: IColumn[] = JSON.parse(
      JSON.stringify(this.props.columns)
    );
    currentColumns.forEach((x: IColumn) => {
      if (x.fieldName !== "edit") {
        x.onColumnClick = this.onColumnClick;
      }
    });

    return currentColumns;
  };

  /**
   * Does execute delete action
   */
  public async deleteSelectedItems(): Promise<void> {
    const selectedIds: number[] = this.selection
      .getSelection()
      .map((x: any) => x.id);
    this.setState({ hideDeleteDialog: true });
    this.selection.setAllSelected(false);

    for await (const id of selectedIds) {
      await this.apiCalls[`delete${this.props.contextKey}`](id);
    }

    document.getElementById("fabric-list-container")!.scrollTo(0, 0);

    this.props.setReload && this.props.setReload(true);
  }

  public toggleHideDeleteDialog(): void {
    if (this.selection.getSelectedCount() > 0) {
      this.setState({
        hideDeleteDialog: !this.state.hideDeleteDialog,
      });
    }
  }

  public render() {
    const { columns, items, isLoading, hideDeleteDialog } = this.state;
    const { title } = this.props;

    return (
      <ThemeProvider>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text
            style={{ margin: "10px 0 10px 30px" }}
            variant={"xxLarge" as ITextProps["variant"]}
          >
            {title}
          </Text>

          <div>
            <DynamicListCommandBar
              onChangeSearch={this.onChangeSearch}
              toggleHideDeleteDialog={this.toggleHideDeleteDialog}
              showModal={this.props.showModal}
              reload={this.props.reload}
            />
            <DeleteDialog
              toggleHideDeleteDialog={this.toggleHideDeleteDialog}
              hideDeleteDialog={hideDeleteDialog}
              selectedItemsCount={this.selection.getSelectedCount()}
              deleteSelectedItems={this.deleteSelectedItems}
            />
          </div>
        </div>
        {isLoading ? (
          <div className="loading-container">
            <div>
              <Label>Wird geladen...</Label>
              <Spinner size={SpinnerSize.large} />
            </div>
          </div>
        ) : (
          <div
            style={{ overflow: "auto", height: "77vh" }}
            onScroll={(event: React.UIEvent<HTMLDivElement>) =>
              this.handleOnScroll(event)
            }
            id="fabric-list-container"
          >
            <MarqueeSelection selection={this.selection}>
              <DetailsList
                items={items}
                columns={columns}
                selectionMode={SelectionMode.multiple}
                setKey="multiple"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                selection={this.selection}
                selectionPreservedOnEmptyClick={true}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="Row checkbox"
                onRenderRow={this.helperService.onRenderRow}
                onRenderItemColumn={(
                  item?: any,
                  index?: number | undefined,
                  column?: IColumn | undefined
                ) =>
                  this.helperService.renderItemColumn(
                    item,
                    index,
                    column,
                    this.props.openItem,
                    false
                  )
                }
                getKey={this.helperService.getKey}
              />
            </MarqueeSelection>
          </div>
        )}
      </ThemeProvider>
    );
  }
}
