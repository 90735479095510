import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
} from "@fluentui/react";
import * as React from "react";
import { HelperService } from "../../../services/helper.service";

const dialogStyles = { main: { maxWidth: 450 } };

interface IProps {
  toggleHideDeleteDialog: any;
  deleteSelectedItems: () => void;
  hideDeleteDialog: boolean;
  selectedItemsCount: number;
}
const helperService = HelperService.getInstance();

const DeleteDialog = (props: IProps) => {
  const labelId: string = helperService.makeId(10);
  const subTextId: string = helperService.makeId(10);

  const modalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: false,
      styles: dialogStyles,
    }),
    [labelId, subTextId]
  );

  const text = `Möchten sie wirklich ${props.selectedItemsCount} ${
    props.selectedItemsCount === 1 ? "Eintrag" : "Einträge"
  } löschen?`;

  const dialogContentProps = {
    type: DialogType.normal,
    title: "Einträge löschen",
    closeButtonAriaLabel: "Close",
    subText: text,
  };

  return (
    <Dialog
      hidden={props.hideDeleteDialog}
      onDismiss={props.toggleHideDeleteDialog}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
    >
      <DialogFooter>
        <PrimaryButton onClick={props.deleteSelectedItems} text="Löschen" />
        <DefaultButton
          onClick={props.toggleHideDeleteDialog}
          text="Abbrechen"
        />
      </DialogFooter>
    </Dialog>
  );
};

export default DeleteDialog;
