import { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router";
import { error$ } from "../../store/error";
import { contextSegments } from "../Controls/context-segments";
import { Layout } from "../Layout/Layout";
import Address from "../Pages/Address/Address";
import Cargolist from "../Pages/Cargolist/Cargolist";
import Church from "../Pages/Church/Church";
import Driver from "../Pages/Driver/Driver";
import Good from "../Pages/Good/Good";
import GoodCategory from "../Pages/GoodCategory/GoodCategory";
import GoodCondition from "../Pages/GoodCondition/GoodCondition";
import GoodInfo from "../Pages/GoodInfo/GoodInfo";
import GoodUnit from "../Pages/GoodUnit/GoodUnit";
import Home from "../Pages/Home/Home";
import LoadingLocation from "../Pages/LoadingLocation/LoadingLocation";
import Text from "../Pages/Text/Text";
import Tour from "../Pages/Tour/Tour";
import Trailer from "../Pages/Trailer/Trailer";
import TrailerType from "../Pages/TrailerType/TrailerType";
import Vehicle from "../Pages/Vehicle/Vehicle";
import VehicleType from "../Pages/VehicleType/VehicleType";

interface IProps {}

const Ladetrans = (props: IProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    error$.subscribe((e) => {
      if (e) navigate("/error");
    });
  }, []);

  return (
    <Layout>
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route
          path={contextSegments.cargolist.apiKey}
          element={<Cargolist />}
        />
        <Route path={contextSegments.address.apiKey} element={<Address />} />
        <Route path={contextSegments.church.apiKey} element={<Church />} />
        <Route path={contextSegments.driver.apiKey} element={<Driver />} />
        <Route path={contextSegments.good.apiKey} element={<Good />} />
        <Route
          path={contextSegments.goodCategory.apiKey}
          element={<GoodCategory />}
        />
        <Route
          path={contextSegments.goodCondition.apiKey}
          element={<GoodCondition />}
        />
        <Route path={contextSegments.goodInfo.apiKey} element={<GoodInfo />} />
        <Route path={contextSegments.goodUnit.apiKey} element={<GoodUnit />} />
        <Route
          path={contextSegments.loadingLocation.apiKey}
          element={<LoadingLocation />}
        />
        <Route path={contextSegments.text.apiKey} element={<Text />} />
        <Route path={contextSegments.tour.apiKey} element={<Tour />} />
        <Route path={contextSegments.trailer.apiKey} element={<Trailer />} />
        <Route
          path={contextSegments.trailerType.apiKey}
          element={<TrailerType />}
        />
        <Route path={contextSegments.vehicle.apiKey} element={<Vehicle />} />
        <Route
          path={contextSegments.vehicleType.apiKey}
          element={<VehicleType />}
        />
        <Route path="/*" element={<Navigate replace to="/home" />} />
      </Routes>
    </Layout>
  );
};

export default Ladetrans;
