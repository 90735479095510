import { IColumn } from "@fluentui/react";
import { HelperService } from "../../../services/helper.service";
import { attributes } from "./vehicle.type";

const helperService: HelperService = HelperService.getInstance();

export const columns: IColumn[] = [
  {
    key: helperService.makeId(10),
    name: "Ansehen",
    fieldName: "edit",
    minWidth: 60,
    maxWidth: 70,
  },
  {
    key: helperService.makeId(10),
    name: attributes.vehicleType.label,
    fieldName: attributes.vehicleType.name,
    minWidth: 70,
    maxWidth: 220,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.licensePlate.label,
    fieldName: attributes.licensePlate.name,
    minWidth: 70,
    maxWidth: 220,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },

  {
    key: helperService.makeId(10),
    name: attributes.countryCode.label,
    fieldName: attributes.countryCode.name,
    minWidth: 70,
    maxWidth: 220,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.payload.label,
    fieldName: attributes.payload.name,
    minWidth: 70,
    maxWidth: 220,
    isRowHeader: true,
    isResizable: true,
    data: "weight",
    isPadded: true,
    onColumnClick: undefined,
  },
];
