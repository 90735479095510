export const attributes = {
  city: {
    label: 'Stadt',
    name: 'city',
  },
  country: {
    label: 'Land',
    name: 'country',
  },
  street: {
    label: 'Straße',
    name: 'street',
  },
  zip: {
    label: 'PLZ',
    name: 'zip',
  },
  contactPerson: {
    label: 'Ansprechpartner',
    name: 'contactPerson',
  },
  mail: {
    label: 'E-Mail',
    name: 'mail',
  },
  phone: {
    label: 'Telefon',
    name: 'phone',
  },
  fax: {
    label: 'FAX',
    name: 'fax',
  },
  exportCustomsOffice: {
    label: 'Ausfuhrzollstelle',
    name: 'exportCustomsOffice',
  },
  originCountry: {
    label: 'Ursprungsbundesland',
    name: 'originCountry',
  },
  code: {
    label: 'Code',
    name: 'code',
  },
};
export interface ILoadingLocationDocument {
  id?: number;
  city: string;
  country: string;
  street: string;
  zip: string;
  contactPerson: string;
  mail: string;
  phone: string;
  fax: string;
  exportCustomsOffice: string;
  originCountry: string;
  code: string;
}

export class LoadingLocation implements ILoadingLocationDocument {
  city = '';

  country = '';

  street = '';

  zip = '';

  contactPerson = '';

  mail = '';

  phone = '';

  fax = '';

  exportCustomsOffice = '';

  originCountry = '';

  code = '';
}
