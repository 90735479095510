import { IColumn } from "@fluentui/react";
import { HelperService } from "../../../../services/helper.service";
import { attributes } from "./cargo.type";

const helperService: HelperService = HelperService.getInstance();

export const columns: IColumn[] = [
  {
    key: helperService.makeId(10),
    name: "Ansehen",
    fieldName: "edit",
    minWidth: 60,
    maxWidth: 70,
  },
  {
    key: helperService.makeId(10),
    name: attributes.customTitleDe.label,
    fieldName: attributes.customTitleDe.name,
    minWidth: 160,
    maxWidth: 200,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.customTitleRu.label,
    fieldName: attributes.customTitleRu.name,
    minWidth: 160,
    maxWidth: 200,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.good.label,
    fieldName: attributes.good.name,
    minWidth: 60,
    maxWidth: 80,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.amount.label,
    fieldName: attributes.amount.name,
    minWidth: 70,
    maxWidth: 70,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.customNettoWeight.label,
    fieldName: attributes.customNettoWeight.name,
    minWidth: 60,
    maxWidth: 80,
    isRowHeader: true,
    isResizable: true,
    data: "weight",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.customBruttoWeight.label,
    fieldName: attributes.customBruttoWeight.name,
    minWidth: 60,
    maxWidth: 80,
    isRowHeader: true,
    isResizable: true,
    data: "weight",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.customPrice.label,
    fieldName: attributes.customPrice.name,
    minWidth: 60,
    maxWidth: 80,
    isRowHeader: true,
    isResizable: true,
    data: "currency",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.customCode.label,
    fieldName: attributes.customCode.name,
    minWidth: 120,
    maxWidth: 160,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.goodCondition.label,
    fieldName: attributes.goodCondition.name,
    minWidth: 120,
    maxWidth: 160,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
];
