export const attributes = {
  titleDe: {
    label: 'Titel - Deutsch',
    name: 'titleDe',
  },
  titleRu: {
    label: 'Titel - Russisch',
    name: 'titleRu',
  },
  countryDe: {
    label: 'Land - Deutsch',
    name: 'countryDe',
  },
  countryRu: {
    label: 'Land - Russisch',
    name: 'countryRu',
  },
  cityDe: {
    label: 'Stadt - Deutsch',
    name: 'cityDe',
  },
  cityRu: {
    label: 'Stadt - Russisch',
    name: 'cityRu',
  },
  streetDe: {
    label: 'Straße - Deutsch',
    name: 'streetDe',
  },
  streetRu: {
    label: 'Straße - Russisch',
    name: 'streetRu',
  },
  zip: {
    label: 'PLZ',
    name: 'zip',
  },
  kod: {
    label: 'Ident. Code',
    name: 'kod',
  },
  countryIso: {
    label: 'ISO',
    name: 'countryIso',
  },
  exitCustomsOffice: {
    label: 'Ausgangszollnummer',
    name: 'exitCustomsOffice',
  },
  contactPerson: {
    label: 'Anschprechpartner',
    name: 'contactPerson',
  },
  phone: {
    label: 'Telefon',
    name: 'phone',
  },
  fax: {
    label: 'FAX',
    name: 'fax',
  },
  mail: {
    label: 'E-Mail',
    name: 'mail',
  },
  homepage: {
    label: 'Internetadresse',
    name: 'homepage',
  },
  additionalTextDe: {
    label: 'Zusatzinfo - Deutsch',
    name: 'additionalTextDe',
  },
  additionalTextRu: {
    label: 'Zusatzinfo - Russisch',
    name: 'additionalTextRu',
  },
};

export interface IAddressDocument {
  id?: number;
  titleDe: string;
  titleRu: string;
  streetDe: string;
  streetRu: string;
  zip: string;
  cityDe: string;
  cityRu: string;
  kod: string;
  countryDe: string;
  countryRu: string;
  countryIso: string;
  exitCustomsOffice: string;
  contactPerson: string;
  phone: string;
  fax: string;
  mail: string;
  homepage: string;
  additionalTextDe: string;
  additionalTextRu: string;
}

export class Address implements IAddressDocument {
  titleDe = '';

  titleRu = '';

  streetDe = '';

  streetRu = '';

  zip = '';

  cityDe = '';

  cityRu = '';

  kod = '';

  countryDe = '';

  countryRu = '';

  countryIso = '';

  exitCustomsOffice = '';

  contactPerson = '';

  phone = '';

  fax = '';

  mail = '';

  homepage = '';

  additionalTextDe = '';

  additionalTextRu = '';
}
