import { IGoodCategoryDocument } from '../GoodCategory/good-category.type';
import { IGoodUnitDocument } from '../GoodUnit/good-unit.type';

export const attributes = {
  goodUnit: {
    label: 'Einheit',
    name: 'goodUnit',
  },
  goodCategory: {
    label: 'Kategorie',
    name: 'goodCategory',
  },
  titleDe: {
    label: 'Titel - Deutsch',
    name: 'titleDe',
  },
  titleRu: {
    label: 'Titel - Russisch',
    name: 'titleRu',
  },
  price: {
    label: 'Preis',
    name: 'price',
  },
  weight: {
    label: 'Kg/Einheit',
    name: 'weight',
  },
  tara: {
    label: 'Tara/Einheit',
    name: 'tara',
  },
  code: {
    label: 'Code',
    name: 'code',
  },
};
export interface IGoodDocument {
  id?: number;
  titleDe: string;
  titleRu: string;
  price: number;
  weight: number;
  tara: number;
  code: string;
  goodUnit: IGoodUnitDocument | null;
  goodCategory: IGoodCategoryDocument | null;
}

export class Good implements IGoodDocument {
  titleDe = '';

  titleRu = '';

  price = 0;

  weight = 0;

  tara = 0;

  code = '';

  goodUnit = null;

  goodCategory = null;
}
