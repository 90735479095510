import {
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentProps,
  IModalProps,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import { AxiosError, AxiosResponse } from "axios";
import React, { useState } from "react";
import { ApiService } from "../../services/api.service";
import "./Login.scss";

interface IProps {}

const apiCalls: ApiService = ApiService.getInstance();

const modelProps: IModalProps = {
  isBlocking: true,
  topOffsetFixed: true,
};

const dialogContentProps: IDialogContentProps = {
  type: DialogType.largeHeader,
  title: "Anmeldung",
  styles: { title: { textAlign: "center" } },
};

function Login(props: IProps) {
  const [username, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<any>();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    apiCalls
      .login({
        username,
        password,
      })
      .then((res: AxiosResponse) => apiCalls.setToken(res.data.atc))
      .catch((res: AxiosError) => setError(res.response?.data));
  };

  return (
    <Dialog
      hidden={false}
      dialogContentProps={dialogContentProps}
      modalProps={modelProps}
      minWidth={400}
      maxWidth={400}
    >
      <form onSubmit={handleSubmit}>
        {error ? (
          <MessageBar
            onDismiss={() => setError(null)}
            messageBarType={MessageBarType.blocked}
            isMultiline={false}
            truncated={true}
          >
            {error.message}
          </MessageBar>
        ) : null}
        <TextField
          name="username"
          label="E-Mail"
          type="email"
          onChange={(
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string | undefined
          ) => setUserName(newValue!)}
        />
        <TextField
          name="password"
          label="Passwort"
          type="password"
          onChange={(
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string | undefined
          ) => setPassword(newValue!)}
        />
        <DialogFooter>
          <PrimaryButton type="submit" text="Login" />
        </DialogFooter>
      </form>
    </Dialog>
  );
}

export default Login;
