export const options = {
  accessibility: {
    enabled: false,
  },
  title: {
    text: "",
  },

  subtitle: {
    text: "",
  },

  yAxis: {
    title: {
      text: "Anzahl der Ladelisten",
    },
  },

  xAxis: {
    title: {
      text: "Range: 2010 to 2017",
    },
  },

  legend: {
    layout: "vertical",
    align: "right",
    verticalAlign: "middle",
  },

  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
      pointStart: 0,
    },
  },

  series: [],

  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
          },
        },
      },
    ],
  },
};
