export const attributes = {
  titleDe: {
    label: 'Titel - Deutsch',
    name: 'titleDe',
  },
  titleRu: {
    label: 'Titel - Russisch',
    name: 'titleRu',
  },
};
export interface IVehicleTypeDocument {
  id?: number;
  titleDe: string;
  titleRu: string;
}

export class VehicleType implements IVehicleTypeDocument {
  titleDe = '';

  titleRu = '';
}
