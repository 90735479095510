import { IColumn } from "@fluentui/react";
import { HelperService } from "../../../services/helper.service";
import { attributes } from "./good-info.type";

const helperService: HelperService = HelperService.getInstance();

export const columns: IColumn[] = [
  {
    key: helperService.makeId(10),
    name: "Ansehen",
    fieldName: "edit",
    minWidth: 60,
    maxWidth: 70,
  },
  {
    key: helperService.makeId(10),
    name: attributes.commodityNumber.label,
    fieldName: attributes.commodityNumber.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.approvalNumber.label,
    fieldName: attributes.approvalNumber.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.referenceNumber.label,
    fieldName: attributes.referenceNumber.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "string",
    isPadded: true,
    onColumnClick: undefined,
  },
  {
    key: helperService.makeId(10),
    name: attributes.issueDate.label,
    fieldName: attributes.issueDate.name,
    minWidth: 70,
    maxWidth: 510,
    isRowHeader: true,
    isResizable: true,
    data: "date",
    isPadded: true,
    onColumnClick: undefined,
  },
];
