export interface IClient {
  title: string;
  subtitle: string;
  society: string;
  street: string;
  zip: string;
  location: string;
  country: string;
  customsNumber: string;
  approvedExporter: string;
  tollCollectMail: string;
  logo: string;
  logoText: string;
  mail: string;
  phone: string;
  fax: string;
  iban: string;
  bic: string;
  bank: string;
  website: string;
}
export interface ISegment {
  contextKey: string;
  displayAttribute: string;
  apiKey: string;
  pluralName: string;
  singularName: string;
}
export interface IContextSegments {
  address: ISegment;
  goodInfo: ISegment;
  loadingLocation: ISegment;
  trailer: ISegment;
  trailerType: ISegment;
  vehicle: ISegment;
  vehicleType: ISegment;
  good: ISegment;
  goodCategory: ISegment;
  goodUnit: ISegment;
  driver: ISegment;
  church: ISegment;
  tour: ISegment;
  text: ISegment;
  cargolist: ISegment;
  cargo: ISegment;
  goodCondition: ISegment;
}
export const contextSegments: IContextSegments = {
  goodCondition: {
    displayAttribute: "title",
    apiKey: "good_conditions",
    contextKey: "GoodCondition",
    singularName: "Warenzustand",
    pluralName: "Warenzustände",
  },
  goodInfo: {
    displayAttribute: "commodityNumber",
    apiKey: "good_infos",
    contextKey: "GoodInfo",
    singularName: "Wareninformation",
    pluralName: "Wareninformationen",
  },
  loadingLocation: {
    displayAttribute: "city",
    apiKey: "loading_locations",
    contextKey: "LoadingLocation",
    singularName: "Ladeort",
    pluralName: "Ladeorte",
  },
  address: {
    displayAttribute: "titleDe",
    apiKey: "addresses",
    contextKey: "Address",
    singularName: "Adresse",
    pluralName: "Adressen",
  },
  church: {
    displayAttribute: "title",
    apiKey: "churches",
    contextKey: "Church",
    singularName: "Gemeinde",
    pluralName: "Gemeinden",
  },
  trailer: {
    displayAttribute: "licensePlate",
    apiKey: "trailers",
    contextKey: "Trailer",
    singularName: "Anhänger",
    pluralName: "Anhänger",
  },
  trailerType: {
    displayAttribute: "titleDe",
    apiKey: "trailer_types",
    contextKey: "TrailerType",
    singularName: "Anhängerart",
    pluralName: "Anhängerarten",
  },
  vehicle: {
    displayAttribute: "licensePlate",
    apiKey: "vehicles",
    contextKey: "Vehicle",
    singularName: "Fahrzeug",
    pluralName: "Fahrzeuge",
  },
  vehicleType: {
    displayAttribute: "titleDe",
    apiKey: "vehicle_types",
    contextKey: "VehicleType",
    singularName: "Fahrzeugart",
    pluralName: "Fahrzeugarten",
  },
  good: {
    displayAttribute: "titleDe",
    apiKey: "goods",
    contextKey: "Good",
    singularName: "Ware",
    pluralName: "Waren",
  },
  goodCategory: {
    displayAttribute: "title",
    apiKey: "good_categories",
    contextKey: "GoodCategory",
    singularName: "Warenkategorie",
    pluralName: "Warenkategorien",
  },
  goodUnit: {
    displayAttribute: "title",
    apiKey: "good_units",
    contextKey: "GoodUnit",
    singularName: "Wareneinheit",
    pluralName: "Wareneinheiten",
  },
  driver: {
    displayAttribute: "name",
    apiKey: "drivers",
    contextKey: "Driver",
    singularName: "Fahrer",
    pluralName: "Fahrer",
  },
  tour: {
    displayAttribute: "title",
    apiKey: "tours",
    contextKey: "Tour",
    singularName: "Tour",
    pluralName: "Touren",
  },
  text: {
    displayAttribute: "topicDe",
    apiKey: "texts",
    contextKey: "Text",
    singularName: "Text",
    pluralName: "Texte",
  },
  cargolist: {
    displayAttribute: "title",
    apiKey: "cargolists",
    contextKey: "Cargolist",
    singularName: "Ladeliste",
    pluralName: "Ladelisten",
  },
  cargo: {
    displayAttribute: "title",
    apiKey: "cargos",
    contextKey: "Cargo",
    singularName: "Ware",
    pluralName: "Waren",
  },
};

export enum FormMode {
  view,
  edit,
  create,
}

export interface IItemsFragment {
  items: any[];
  limit: string;
  page: string;
  totalCount: number;
  isLastPage: boolean;
}

export enum Format {
  docx = "docx",
  pdf = "pdf",
  xml = "xml",
  zip = "zip",
}

export enum Lang {
  de = "de",
  ru = "ru",
}

export enum DocType {
  list = "list",
  cmr = "cmr",
  cert = "cert",
  customs = "customs",
  invoice = "invoice",
  maut = "maut",
  billing = "billing",
  docs = "docs",
}

export enum NotificationTitle {
  success = "Vorgang wurde erfolgreich abgeschlossen",
  error = "Vorgang wurde leider abgebrochen",
  pending = "Vorgang wird ausgeführt",
}

export enum InputType {
  text = "text",
  textarea = "textarea",
  number = "number",
  checkbox = "checkbox",
  dropdown = "dropdown",
  combobox = "combobox",
  datepicker = "datepicker",
}
