import moment from 'moment';
import 'moment/locale/de';

export const attributes = {
  commodityNumber: {
    label: 'Warennummer',
    name: 'commodityNumber',
  },
  approvalNumber: {
    label: 'Genehmigung des statischen bundesamtes',
    name: 'approvalNumber',
  },
  referenceNumber: {
    label: 'Referenznummer',
    name: 'referenceNumber',
  },

  issueDate: {
    label: 'Ausstelungsdatum',
    name: 'issueDate',
  },
};
export interface IGoodInfoDocument {
  id?: number;
  commodityNumber: string;
  approvalNumber: string;
  referenceNumber: string;
  issueDate: string;
}

export class GoodInfo implements IGoodInfoDocument {
  commodityNumber = '';

  approvalNumber = '';

  referenceNumber = '';

  issueDate = moment().format('YYYY-MM-DD');
}
