import { useState } from "react";
import { ApiService } from "../../../services/api.service";
import {
  contextSegments,
  FormMode,
  IClient,
} from "../../Controls/context-segments";
import { DynamicDetailsList } from "../../Controls/DetailsList/DynamicDetailsList";
import { columns } from "./cargolist-columns";
import { transferItems } from "./cargolist.dto";
import { ICargolistDocument } from "./cargolist.type";
import CargolistForm from "./CargolistForm";

interface IProps {}

const apiCalls: ApiService = ApiService.getInstance();

const Cargolist = (props: IProps) => {
  // Own state
  const [reload, setReload] = useState<boolean>(false);
  const [provider, setProvider] = useState<IClient>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode>(FormMode.create);
  const [selectedItem, setSelectedItem] = useState<ICargolistDocument | null>(
    null
  );

  const closeModal = async () => {
    setIsModalOpen(false);
    setSelectedItem(null);
    setFormMode(FormMode.create);
  };

  const openItem = async (itemId: number) => {
    //   Fetch selected item by id
    const providerRes = await apiCalls.getClient();
    const res = await apiCalls.getSingleCargolist(itemId);

    if (res?.status === 200 && providerRes?.status === 200) {
      setProvider(providerRes.data);

      setSelectedItem(res!.data);
      setFormMode(FormMode.view);
      setIsModalOpen(true);
    }
  };

  return (
    <div style={{ flex: "1 1 90%", overflow: "auto" }}>
      <CargolistForm
        isModalOpen={isModalOpen}
        formMode={formMode}
        tollCollectMail={provider?.tollCollectMail}
        selectedItem={selectedItem}
        setFormMode={setFormMode}
        closeModal={closeModal}
        setReload={setReload}
      />
      <DynamicDetailsList
        reload={reload}
        columns={columns}
        contextKey={contextSegments.cargolist.contextKey}
        title={contextSegments.cargolist.pluralName}
        transferItems={transferItems}
        setReload={setReload}
        showModal={() => setIsModalOpen(true)}
        openItem={openItem}
      />
    </div>
  );
};

export default Cargolist;
