import { DefaultButton, ITextProps, Text } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { error$ } from "../../store/error";

interface IProps {}

enum Messages {
  unauthorized = "Nicht autorisiert",
  internalServerError = "Interner Serverfehler",
  notFound = "Nicht gefuden",
  allRight = "Alles ist in ordnung",
}

const buttonText = {
  home: {
    text: "Home",
    location: "/home",
  },
  login: {
    text: "Anmelden",
    location: "/login",
  },
};

const ErrorPage = (props: IProps) => {
  const navigate = useNavigate();

  const generateButton = (text: string, location: string) => (
    <DefaultButton
      onClick={() => navigate("/home")}
      style={{ marginTop: "20px" }}
      text={text}
    />
  );

  const [status, setStatus] = useState<number>(200);
  const [message, setMessage] = useState<string>(Messages.allRight);
  const [button, setButton] = useState<JSX.Element>(
    generateButton(buttonText.home.text, buttonText.home.location)
  );

  useEffect(() => {
    const subscription = error$.subscribe((e) => {
      const statusCode = e?.response?.status;
      const msg = e?.message;

      if (msg && statusCode) {
        setStatus(statusCode);
        switch (statusCode) {
          case 401:
            setMessage(Messages.unauthorized);
            setButton(
              generateButton(buttonText.login.text, buttonText.login.location)
            );
            break;
          case 500:
            setMessage(Messages.internalServerError);
            setButton(
              generateButton(buttonText.home.text, buttonText.home.location)
            );
            break;
          case 404:
            setMessage(Messages.notFound);
            setButton(
              generateButton(buttonText.home.text, buttonText.home.location)
            );
            break;
          default:
            setMessage(msg);
            setButton(
              generateButton(buttonText.home.text, buttonText.home.location)
            );
            break;
        }
      } else {
        setStatus(500);
        setMessage(Messages.internalServerError);
        setButton(
          generateButton(buttonText.home.text, buttonText.home.location)
        );
      }
    });

    return () => {
      error$.next(null);
      subscription.unsubscribe();
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text block variant={"xLarge" as ITextProps["variant"]}>
          Oops!
        </Text>
        <Text
          style={{ fontSize: "200px" }}
          block
          variant={"xxLarge" as ITextProps["variant"]}
        >
          {status}
        </Text>
        <Text block variant={"xLarge" as ITextProps["variant"]}>
          {message}
        </Text>
        {button}
      </div>
    </div>
  );
};

export default ErrorPage;
