export const attributes = {
  title: {
    label: 'Titel',
    name: 'title',
  },
};

export interface IGoodCategoryDocument {
  id?: number;
  title: string;
}

export class GoodCategory implements IGoodCategoryDocument {
  title = '';
}
