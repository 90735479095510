import {
  Checkbox,
  ComboBox,
  IComboBox,
  IComboBoxOption,
  IconButton,
  ITextProps,
  Label,
  Modal,
  Separator,
  Text,
  TextField,
} from "@fluentui/react";
import { AxiosResponse } from "axios";
import moment from "moment";
import "moment/locale/de";
import React, { useEffect, useState } from "react";
import { ApiService } from "../../../services/api.service";
import { HelperService } from "../../../services/helper.service";
import {
  cancelIcon,
  checkboxStyles,
  iconButtonStyles,
  labelStyles,
  twoGroupedForm,
  valueStyles,
  wideFormContentStyles,
} from "../../Controls/component-styles";
import {
  contextSegments,
  FormMode,
  InputType,
} from "../../Controls/context-segments";
import DynamicDatePicker from "../../Controls/DatePicker/DynamicDatePicker";
import DeleteDialog from "../../Controls/Dialogs/DeleteDialog";
import Dropdown from "../../Controls/Dropdown/Dropdown";
import { Option } from "../../Controls/Dropdown/option";
import { DynamicFormCommandBar } from "../../Controls/DynamicFormCommandBar/DynamicFormCommandBar";
import ErrorCallout from "../../Controls/ErrorCallout/ErrorCallout";
import { IAddressDocument } from "../Address/address.type";
import AddressForm from "../Address/AddressForm";
import { IDriverDocument } from "../Driver/driver.type";
import DriverForm from "../Driver/DriverForm";
import { IGoodInfoDocument } from "../GoodInfo/good-info.type";
import GoodInfoForm from "../GoodInfo/GoodInfoForm";
import { ILoadingLocationDocument } from "../LoadingLocation/loading-location.type";
import LoadingLocationForm from "../LoadingLocation/LoadingLocationForm";
import { ITextDocument } from "../Text/text.type";
import TextForm from "../Text/TextForm";
import { ITourDocument } from "../Tour/tour.type";
import TourForm from "../Tour/TourForm";
import { ITrailerDocument } from "../Trailer/trailer.type";
import TrailerForm from "../Trailer/TrailerForm";
import { IVehicleDocument } from "../Vehicle/vehicle.type";
import VehicleForm from "../Vehicle/VehicleForm";
import Cargo from "./Cargo/Cargo";
import { transferCargo } from "./Cargo/cargo.dto";
import { ICargoDocument } from "./Cargo/cargo.type";
import { ICargolistDTO, transferDTO } from "./cargolist.dto";
import { attributes, Cargolist, ICargolistDocument } from "./cargolist.type";

moment.locale("de");

interface IProps {
  isModalOpen: boolean;
  tollCollectMail?: string;
  selectedItem: ICargolistDocument | null;
  formMode: FormMode;
  closeModal: () => void;
  setFormMode: (formMode: FormMode) => void;
  setReload?: (reload: boolean) => void;
}

const apiCalls: ApiService = ApiService.getInstance();
const formName: string = "cargolist-form";
const helperService = HelperService.getInstance();
const buttonId: string = helperService.makeId(10);

const transportedOptions: IComboBoxOption[] = [
  { key: "carrier", text: "Spedition" },
  { key: "mission", text: "Mission" },
  { key: "rental-vehicle ", text: "Mietfahrzeug" },
];

const CargolistForm = (props: IProps) => {
  // Own state
  const [payload, setPayload] = useState<ICargolistDocument>(new Cargolist());
  const [hideDeleteDialog, toggleHideDeleteDialog] = useState<boolean>(true);
  const [cargos, setCargos] = useState<ICargoDocument[]>(
    payload.cargos.map((x) => transferCargo(x))
  );

  //   Tour
  const [isTourModalOpen, setIsTourModalOpen] = useState<boolean>(false);
  const [showTourSelectedError, setShowTourSelectedError] =
    useState<boolean>(false);
  const [tourFormMode, setTourFormMode] = useState<FormMode>(FormMode.create);
  const [selectedTourItem, setSelectedTourItem] =
    useState<ITourDocument | null>(null);
  const [shouldSetDefaultTourOption, setShouldSetDefaultTourOption] =
    useState<boolean>(true);

  const closeTourModal = async () => {
    setIsTourModalOpen(false);
    setSelectedTourItem(null);
    setTourFormMode(FormMode.create);
  };

  const setTourOption = async (e: any, newValue: any) => {
    handleOnChange(e, newValue);
    // Signal that the item need to be preselected in the dropdown
    setShouldSetDefaultTourOption(true);
  };

  //   Driver
  const [isDriverModalOpen, setIsDriverModalOpen] = useState<boolean>(false);
  const [driverFormMode, setDriverFormMode] = useState<FormMode>(
    FormMode.create
  );
  const [selectedDriverItem, setSelectedDriverItem] =
    useState<IDriverDocument | null>(null);
  const [shouldSetDefaultDriverOption, setShouldSetDefaultDriverOption] =
    useState<boolean>(true);

  const closeDriverModal = async () => {
    setIsDriverModalOpen(false);
    setSelectedDriverItem(null);
    setDriverFormMode(FormMode.create);
  };

  const setDriverOption = async (e: any, newValue: any) => {
    handleOnChange(e, newValue);
    // Signal that the item need to be preselected in the dropdown
    setShouldSetDefaultDriverOption(true);
  };

  //   Certificate Content
  const [isCertificateContentModalOpen, setIsCertificateContentModalOpen] =
    useState<boolean>(false);
  const [certificateContentFormMode, setCertificateContentFormMode] =
    useState<FormMode>(FormMode.create);
  const [selectedCertificateContentItem, setSelectedCertificateContentItem] =
    useState<ITextDocument | null>(null);
  const [
    shouldSetDefaultCertificateContentOption,
    setShouldSetDefaultCertificateContentOption,
  ] = useState<boolean>(true);

  const closeCertificateContentModal = async () => {
    setIsCertificateContentModalOpen(false);
    setSelectedCertificateContentItem(null);
    setCertificateContentFormMode(FormMode.create);
  };

  const setCertificateContentOption = async (e: any, newValue: any) => {
    handleOnChange(e, newValue);
    // Signal that the item need to be preselected in the dropdown
    setShouldSetDefaultCertificateContentOption(true);
  };

  //   List Content
  const [isListContentModalOpen, setIsListContentModalOpen] =
    useState<boolean>(false);
  const [listContentFormMode, setListContentFormMode] = useState<FormMode>(
    FormMode.create
  );
  const [selectedListContentItem, setSelectedListContentItem] =
    useState<ITextDocument | null>(null);
  const [
    shouldSetDefaultListContentOption,
    setShouldSetDefaultListContentOption,
  ] = useState<boolean>(true);

  const closeListContentModal = async () => {
    setIsListContentModalOpen(false);
    setSelectedListContentItem(null);
    setListContentFormMode(FormMode.create);
  };

  const setListContentOption = async (e: any, newValue: any) => {
    handleOnChange(e, newValue);
    // Signal that the item need to be preselected in the dropdown
    setShouldSetDefaultListContentOption(true);
  };

  //   Address
  const [isAddressModalOpen, setIsAddressModalOpen] = useState<boolean>(false);
  const [addressFormMode, setAddressFormMode] = useState<FormMode>(
    FormMode.create
  );
  const [selectedAddressItem, setSelectedAddressItem] =
    useState<IAddressDocument | null>(null);
  const [shouldSetDefaultAddressOption, setShouldSetDefaultAddressOption] =
    useState<boolean>(true);

  const closeAddressModal = async () => {
    setIsAddressModalOpen(false);
    setSelectedAddressItem(null);
    setAddressFormMode(FormMode.create);
  };

  const setAddressOption = async (e: any, newValue: any) => {
    handleOnChange(e, newValue);
    // Signal that the item need to be preselected in the dropdown
    setShouldSetDefaultAddressOption(true);
  };

  //   GoodInfo
  const [isGoodInfoModalOpen, setIsGoodInfoModalOpen] =
    useState<boolean>(false);
  const [goodInfoFormMode, setGoodInfoFormMode] = useState<FormMode>(
    FormMode.create
  );
  const [selectedGoodInfoItem, setSelectedGoodInfoItem] =
    useState<IGoodInfoDocument | null>(null);
  const [shouldSetDefaultGoodInfoOption, setShouldSetDefaultGoodInfoOption] =
    useState<boolean>(true);

  const closeGoodInfoModal = async () => {
    setIsGoodInfoModalOpen(false);
    setSelectedGoodInfoItem(null);
    setGoodInfoFormMode(FormMode.create);
  };

  const setGoodInfoOption = async (e: any, newValue: any) => {
    handleOnChange(e, newValue);
    // Signal that the item need to be preselected in the dropdown
    setShouldSetDefaultGoodInfoOption(true);
  };

  //   LoadingLocation
  const [isLoadingLocationModalOpen, setIsLoadingLocationModalOpen] =
    useState<boolean>(false);
  const [loadingLocationFormMode, setLoadingLocationFormMode] =
    useState<FormMode>(FormMode.create);
  const [selectedLoadingLocationItem, setSelectedLoadingLocationItem] =
    useState<ILoadingLocationDocument | null>(null);
  const [
    shouldSetDefaultLoadingLocationOption,
    setShouldSetDefaultLoadingLocationOption,
  ] = useState<boolean>(true);

  const closeLoadingLocationModal = async () => {
    setIsLoadingLocationModalOpen(false);
    setSelectedLoadingLocationItem(null);
    setLoadingLocationFormMode(FormMode.create);
  };

  const setLoadingLocationOption = async (e: any, newValue: any) => {
    handleOnChange(e, newValue);
    // Signal that the item need to be preselected in the dropdown
    setShouldSetDefaultLoadingLocationOption(true);
  };

  //   Vehicle
  const [isVehicleModalOpen, setIsVehicleModalOpen] = useState<boolean>(false);
  const [vehicleFormMode, setVehicleFormMode] = useState<FormMode>(
    FormMode.create
  );
  const [selectedVehicleItem, setSelectedVehicleItem] =
    useState<IVehicleDocument | null>(null);
  const [shouldSetDefaultVehicleOption, setShouldSetDefaultVehicleOption] =
    useState<boolean>(true);

  const closeVehicleModal = async () => {
    setIsVehicleModalOpen(false);
    setSelectedVehicleItem(null);
    setVehicleFormMode(FormMode.create);
  };

  const setVehicleOption = async (e: any, newValue: any) => {
    handleOnChange(e, newValue);
    // Signal that the item need to be preselected in the dropdown
    setShouldSetDefaultVehicleOption(true);
  };

  //   Trailer
  const [isTrailerModalOpen, setIsTrailerModalOpen] = useState<boolean>(false);
  const [trailerFormMode, setTrailerFormMode] = useState<FormMode>(
    FormMode.create
  );
  const [selectedTrailerItem, setSelectedTrailerItem] =
    useState<ITrailerDocument | null>(null);
  const [shouldSetDefaultTrailerOption, setShouldSetDefaultTrailerOption] =
    useState<boolean>(true);

  const closeTrailerModal = async () => {
    setIsTrailerModalOpen(false);
    setSelectedTrailerItem(null);
    setTrailerFormMode(FormMode.create);
  };

  const setTrailerOption = async (e: any, newValue: any) => {
    handleOnChange(e, newValue);
    // Signal that the item need to be preselected in the dropdown
    setShouldSetDefaultTrailerOption(true);
  };

  const formHeading: string = helperService.getFormHeading(
    "Ladeliste",
    props.formMode
  );

  useEffect(() => {
    // In case some item was passed to the form
    // Set this item to the payload
    if (props.selectedItem) {
      setPayload(props.selectedItem);
      // Also set the cargos of cargolist
      const cargoItems = props.selectedItem.cargos.map((x) => transferCargo(x));
      setCargos(cargoItems);
    }
    // In case no item was passed to the form
    // Set default empty payload
    else {
      setCargos([]);
      setPayload(new Cargolist());
      setShouldSetDefaultVehicleOption(true);
      setShouldSetDefaultAddressOption(true);
      setShouldSetDefaultGoodInfoOption(true);
      setShouldSetDefaultLoadingLocationOption(true);
      setShouldSetDefaultTrailerOption(true);
      setShouldSetDefaultDriverOption(true);
      setShouldSetDefaultCertificateContentOption(true);
      setShouldSetDefaultListContentOption(true);
      setShouldSetDefaultTourOption(true);
    }
    // Clear the input when closing the modal
    if (!props.isModalOpen) {
      setPayload(new Cargolist());
    }
  }, [props.selectedItem, props.isModalOpen]);

  const titleId = helperService.makeId(10);

  const getBooleanExpression = (flag: boolean) => (flag ? "Ja" : "Nein");

  /**
   * Invoke create action
   * @param e
   */
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!payload.tour) {
      setShowTourSelectedError(true);

      return;
    }
    setShowTourSelectedError(false);

    const payloadDto: ICargolistDTO = transferDTO(payload, cargos);

    // In case payload id exist
    // Update the item
    if (payload.id) {
      apiCalls
        .updateCargolist(payloadDto, payload.id)
        .then((res: AxiosResponse<any>) => {
          props.setReload && props.setReload(true);

          props.closeModal();
          setPayload(new Cargolist());
        });
    }
    // In case no payload id exist
    // Create a new item
    else {
      apiCalls.createCargolist(payloadDto).then((res: AxiosResponse<any>) => {
        props.setReload && props.setReload(true);

        props.closeModal();
        setPayload(new Cargolist());
      });
    }
  };

  /**
   * Write newValue to state by element name
   * @param e
   * @param newValue
   */
  const handleOnChange = (e: any, newValue: any) => {
    const { name } = e.target;
    const inputType: string = e.target.type;

    if (newValue === undefined && name === attributes.certificateContent.name) {
      // Set prop value to null in case no value was passed
      setPayload({ ...payload, [name]: [] });
    } else if (newValue === undefined && name === attributes.listContent.name) {
      // Set prop value to null in case no value was passed
      setPayload({ ...payload, [name]: [] });
    } else if (newValue === undefined && name === attributes.drivers.name) {
      // Set prop value to null in case no value was passed
      setPayload({ ...payload, [name]: [] });
    } else if (newValue === undefined) {
      // Set prop value to null in case no value was passed
      setPayload({ ...payload, [name]: null });
    } else {
      // Set value to the payload object by their type
      if (inputType === InputType.text || inputType === InputType.textarea) {
        // Remove umlaute
        if (name === attributes.notes.name) {
          newValue = helperService.removeUmlaute(newValue);
        }

        setPayload({ ...payload, [name]: String(newValue) });
      } else if (inputType === InputType.number) {
        setPayload({ ...payload, [name]: Number(newValue) });
      } else if (inputType === InputType.checkbox) {
        setPayload({ ...payload, [name]: Boolean(newValue) });
      } else if (inputType === InputType.datepicker) {
        setPayload({ ...payload, [name]: newValue });
      } else if (
        inputType === InputType.dropdown &&
        name === attributes.drivers.name &&
        !Array.isArray(newValue)
      ) {
        const newDrivers: IDriverDocument[] = JSON.parse(
          JSON.stringify(payload.drivers)
        );
        newDrivers.push(newValue);
        setPayload({ ...payload, [name]: newDrivers });
      } else if (
        inputType === InputType.dropdown &&
        name === attributes.certificateContent.name &&
        !Array.isArray(newValue)
      ) {
        const newTexts: ITextDocument[] = JSON.parse(
          JSON.stringify(payload.certificateContent)
        );
        newTexts.push(newValue);
        setPayload({ ...payload, [name]: newTexts });
      } else if (
        inputType === InputType.dropdown &&
        name === attributes.listContent.name &&
        !Array.isArray(newValue)
      ) {
        const newTexts: ITextDocument[] = JSON.parse(
          JSON.stringify(payload.listContent)
        );
        newTexts.push(newValue);
        setPayload({ ...payload, [name]: newTexts });
      } else if (
        inputType === InputType.dropdown &&
        name === attributes.drivers.name &&
        Array.isArray(newValue)
      ) {
        setPayload({ ...payload, [name]: newValue });
      } else if (
        inputType === InputType.dropdown &&
        name === attributes.certificateContent.name &&
        Array.isArray(newValue)
      ) {
        setPayload({ ...payload, [name]: newValue });
      } else if (
        inputType === InputType.dropdown &&
        name === attributes.listContent.name &&
        Array.isArray(newValue)
      ) {
        setPayload({ ...payload, [name]: newValue });
      } else if (inputType === InputType.combobox) {
        setPayload({ ...payload, [name]: newValue.text });
      } else if (inputType === InputType.dropdown) {
        // Calculate the predefined values
        if (name === attributes.tour.name) {
          // Tour error handling
          if (newValue.id !== undefined) {
            setShowTourSelectedError(false);
          }
        }
        setPayload({ ...payload, [name]: newValue });
      }
    }
  };

  const isEditable: boolean =
    props.formMode === FormMode.create || props.formMode === FormMode.edit;
  const deleteOpenedItem = async () => {
    toggleHideDeleteDialog(true);
    props.closeModal();
    await apiCalls.deleteCargolist(props.selectedItem!.id!);
    props.setReload && props.setReload(true);
  };

  const drivers: Option[] = payload.drivers
    ? payload.drivers.map((x) => new Option(x.name, String(x.id)))
    : [];
  const certificateContent: Option[] = payload.certificateContent
    ? payload.certificateContent.map((x) => new Option(x.topicDe, String(x.id)))
    : [];
  const listContent: Option[] = payload.listContent
    ? payload.listContent.map((x) => new Option(x.topicDe, String(x.id)))
    : [];

  const transportedDefaultOption = transportedOptions.find(
    (o) => o.text === payload.transported
  );

  const transportedFakeEvent = {
    target: {
      name: "transported",
      type: "combobox",
    },
  };

  const driversView: null | string = payload.drivers
    ? payload.drivers.map((d) => d.name.replace(",", " ")).join(", ")
    : null;

  const certificateContentView: null | string = payload.certificateContent
    ? payload.certificateContent.map((d) => d.topicDe).join(", ")
    : null;
  const listContentView: null | string = payload.listContent
    ? payload.listContent.map((d) => d.topicDe).join(", ")
    : null;

  return (
    <Modal
      titleAriaId={titleId}
      isOpen={props.isModalOpen}
      onDismiss={props.closeModal}
      isBlocking={false}
      containerClassName={wideFormContentStyles.container}
    >
      <DeleteDialog
        toggleHideDeleteDialog={toggleHideDeleteDialog}
        hideDeleteDialog={hideDeleteDialog}
        selectedItemsCount={1}
        deleteSelectedItems={deleteOpenedItem}
      />
      <div className={wideFormContentStyles.header}>
        <Text id={titleId} variant={"xxLarge" as ITextProps["variant"]}>
          {formHeading}
        </Text>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={props.closeModal}
        />
      </div>
      <div className={wideFormContentStyles.body}>
        {isEditable && props.isModalOpen ? (
          <div>
            <form id={formName} onSubmit={onSubmit}>
              <DynamicFormCommandBar
                formMode={props.formMode}
                toggleHideDeleteDialog={toggleHideDeleteDialog}
                closeModal={props.closeModal}
                formName={formName}
              />
              <div style={twoGroupedForm.container}>
                <div style={twoGroupedForm.leftGroup}>
                  <Dropdown
                    required={true}
                    id={buttonId}
                    shouldSetDefaultOption={shouldSetDefaultTourOption}
                    isMulti={false}
                    contextKey={contextSegments.tour.contextKey}
                    optionKey={contextSegments.tour.displayAttribute}
                    attributeName={attributes.tour.name}
                    label={attributes.tour.label}
                    setOption={setTourOption}
                    setShouldSetDefaultOption={setShouldSetDefaultTourOption}
                    showModal={() => setIsTourModalOpen(true)}
                    defaultOption={
                      new Option(
                        String(payload.tour?.title),
                        String(payload.tour?.id)
                      )
                    }
                  />
                  {showTourSelectedError && (
                    <ErrorCallout
                      hideCallout={() => setShowTourSelectedError(false)}
                      buttonId={buttonId}
                    />
                  )}
                  <TextField
                    name={attributes.displayNumber.name}
                    label={attributes.displayNumber.label}
                    type="text"
                    required
                    onChange={handleOnChange}
                    defaultValue={payload.displayNumber}
                  />
                  <TextField
                    name={attributes.seal.name}
                    label={attributes.seal.label}
                    type="text"
                    onChange={handleOnChange}
                    defaultValue={payload.seal}
                  />
                  <TextField
                    name={attributes.aeTrafficBranch.name}
                    label={attributes.aeTrafficBranch.label}
                    type="number"
                    onChange={handleOnChange}
                    defaultValue={String(payload.aeTrafficBranch)}
                  />
                  <TextField
                    name={attributes.packType.name}
                    label={attributes.packType.label}
                    type="text"
                    onChange={handleOnChange}
                    defaultValue={payload.packType}
                  />
                  <TextField
                    name={attributes.registrationType.name}
                    label={attributes.registrationType.label}
                    type="text"
                    onChange={handleOnChange}
                    defaultValue={payload.registrationType}
                  />

                  <DynamicDatePicker
                    label={attributes.date.label}
                    isRequired={false}
                    defaultValue={payload.date}
                    name={attributes.date.name}
                    handleOnChange={handleOnChange}
                  />

                  <TextField
                    name={attributes.transportCosts.name}
                    label={attributes.transportCosts.label}
                    type="number"
                    suffix="€"
                    onChange={handleOnChange}
                    defaultValue={String(payload.transportCosts)}
                  />
                  <TextField
                    name={attributes.notes.name}
                    label={attributes.notes.label}
                    type="text"
                    onChange={handleOnChange}
                    defaultValue={payload.notes}
                  />
                  <Label>{attributes.layout.label}</Label>
                  <Checkbox
                    styles={checkboxStyles}
                    name={attributes.layout.name}
                    label="Ja/Nein"
                    defaultChecked={payload.layout}
                    onChange={handleOnChange}
                  />
                  <Label>{attributes.sign.label}</Label>
                  <Checkbox
                    styles={checkboxStyles}
                    name={attributes.sign.name}
                    label="Ja/Nein"
                    defaultChecked={payload.sign}
                    onChange={handleOnChange}
                  />
                </div>
                <div style={twoGroupedForm.rightGroup}>
                  <ComboBox
                    onChange={
                      (
                        event: React.FormEvent<IComboBox>,
                        option?: IComboBoxOption | undefined
                      ) => handleOnChange(transportedFakeEvent, option)
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    defaultSelectedKey={transportedDefaultOption?.key}
                    label={attributes.transported.label}
                    options={transportedOptions}
                  />
                  <Dropdown
                    shouldSetDefaultOption={
                      shouldSetDefaultLoadingLocationOption
                    }
                    isMulti={false}
                    contextKey={contextSegments.loadingLocation.contextKey}
                    optionKey={contextSegments.loadingLocation.displayAttribute}
                    attributeName={attributes.loadingLocation.name}
                    label={attributes.loadingLocation.label}
                    setOption={setLoadingLocationOption}
                    setShouldSetDefaultOption={
                      setShouldSetDefaultLoadingLocationOption
                    }
                    showModal={() => setIsLoadingLocationModalOpen(true)}
                    defaultOption={
                      new Option(
                        String(payload.loadingLocation?.city),
                        String(payload.loadingLocation?.id)
                      )
                    }
                  />
                  <Dropdown
                    shouldSetDefaultOption={shouldSetDefaultAddressOption}
                    isMulti={false}
                    contextKey={contextSegments.address.contextKey}
                    optionKey={contextSegments.address.displayAttribute}
                    attributeName={attributes.address.name}
                    label={attributes.address.label}
                    setOption={setAddressOption}
                    setShouldSetDefaultOption={setShouldSetDefaultAddressOption}
                    showModal={() => setIsAddressModalOpen(true)}
                    defaultOption={
                      new Option(
                        String(payload.address?.titleDe),
                        String(payload.address?.id)
                      )
                    }
                  />
                  <Dropdown
                    shouldSetDefaultOption={shouldSetDefaultDriverOption}
                    isMulti={true}
                    contextKey={contextSegments.driver.contextKey}
                    optionKey={contextSegments.driver.displayAttribute}
                    attributeName={attributes.drivers.name}
                    label={attributes.drivers.label}
                    setOption={setDriverOption}
                    setShouldSetDefaultOption={setShouldSetDefaultDriverOption}
                    showModal={() => setIsDriverModalOpen(true)}
                    defaultOption={drivers}
                  />
                  <Dropdown
                    shouldSetDefaultOption={
                      shouldSetDefaultCertificateContentOption
                    }
                    isMulti={true}
                    contextKey={contextSegments.text.contextKey}
                    optionKey={contextSegments.text.displayAttribute}
                    attributeName={attributes.certificateContent.name}
                    label={attributes.certificateContent.label}
                    setOption={setCertificateContentOption}
                    setShouldSetDefaultOption={
                      setShouldSetDefaultCertificateContentOption
                    }
                    showModal={() => setIsCertificateContentModalOpen(true)}
                    defaultOption={certificateContent}
                  />
                  <Dropdown
                    shouldSetDefaultOption={shouldSetDefaultListContentOption}
                    isMulti={true}
                    contextKey={contextSegments.text.contextKey}
                    optionKey={contextSegments.text.displayAttribute}
                    attributeName={attributes.listContent.name}
                    label={attributes.listContent.label}
                    setOption={setListContentOption}
                    setShouldSetDefaultOption={
                      setShouldSetDefaultListContentOption
                    }
                    showModal={() => setIsListContentModalOpen(true)}
                    defaultOption={listContent}
                  />

                  <Dropdown
                    shouldSetDefaultOption={shouldSetDefaultVehicleOption}
                    isMulti={false}
                    contextKey={contextSegments.vehicle.contextKey}
                    optionKey={contextSegments.vehicle.displayAttribute}
                    attributeName={attributes.vehicle.name}
                    label={attributes.vehicle.label}
                    setOption={setVehicleOption}
                    setShouldSetDefaultOption={setShouldSetDefaultVehicleOption}
                    showModal={() => setIsVehicleModalOpen(true)}
                    defaultOption={
                      new Option(
                        String(payload.vehicle?.licensePlate),
                        String(payload.vehicle?.id)
                      )
                    }
                  />
                  <Dropdown
                    shouldSetDefaultOption={shouldSetDefaultTrailerOption}
                    isMulti={false}
                    contextKey={contextSegments.trailer.contextKey}
                    optionKey={contextSegments.trailer.displayAttribute}
                    attributeName={attributes.trailer.name}
                    label={attributes.trailer.label}
                    setOption={setTrailerOption}
                    setShouldSetDefaultOption={setShouldSetDefaultTrailerOption}
                    showModal={() => setIsTrailerModalOpen(true)}
                    defaultOption={
                      new Option(
                        String(payload.trailer?.licensePlate),
                        String(payload.trailer?.id)
                      )
                    }
                  />

                  <Dropdown
                    shouldSetDefaultOption={shouldSetDefaultGoodInfoOption}
                    isMulti={false}
                    contextKey={contextSegments.goodInfo.contextKey}
                    optionKey={contextSegments.goodInfo.displayAttribute}
                    attributeName={attributes.goodInfo.name}
                    label={attributes.goodInfo.label}
                    setOption={setGoodInfoOption}
                    setShouldSetDefaultOption={
                      setShouldSetDefaultGoodInfoOption
                    }
                    showModal={() => setIsGoodInfoModalOpen(true)}
                    defaultOption={
                      new Option(
                        String(payload.goodInfo?.commodityNumber),
                        String(payload.goodInfo?.id)
                      )
                    }
                  />
                  <Label>{attributes.sent.label}</Label>
                  <Checkbox
                    styles={checkboxStyles}
                    name={attributes.sent.name}
                    label="Ja/Nein"
                    defaultChecked={payload.sent}
                    onChange={handleOnChange}
                  />
                  <Label>{attributes.commodityCode.label}</Label>
                  <Checkbox
                    styles={checkboxStyles}
                    name={attributes.commodityCode.name}
                    label="Ja/Nein"
                    defaultChecked={payload.commodityCode}
                    onChange={handleOnChange}
                  />
                </div>
              </div>
            </form>
            <Separator />
            <Cargo
              cargoListFormMode={props.formMode}
              cargos={cargos}
              setCargos={setCargos}
            />
          </div>
        ) : (
          <div>
            <DynamicFormCommandBar
              formMode={props.formMode}
              toggleHideDeleteDialog={toggleHideDeleteDialog}
              cargolistId={payload.id}
              fileName={`${payload.tour?.title}/${payload.displayNumber}`}
              tollCollectMail={props.tollCollectMail}
              cargoReceiver={payload.address!}
              formName={formName}
              setFormMode={props.setFormMode}
              closeModal={props.closeModal}
            />
            <div style={twoGroupedForm.container}>
              <div style={twoGroupedForm.leftGroup}>
                <Label styles={labelStyles}>{attributes.tour.label}</Label>
                <span style={valueStyles}>{payload.tour?.title}</span>
                <Label styles={labelStyles}>
                  {attributes.displayNumber.label}
                </Label>
                <span style={valueStyles}>{payload.displayNumber}</span>
                <Label styles={labelStyles}>{attributes.seal.label}</Label>
                <span style={valueStyles}>{payload.seal}</span>
                <Label styles={labelStyles}>
                  {attributes.aeTrafficBranch.label}
                </Label>
                <span style={valueStyles}>{payload.aeTrafficBranch}</span>
                <Label styles={labelStyles}>{attributes.packType.label}</Label>
                <span style={valueStyles}>{payload.packType}</span>
                <Label styles={labelStyles}>
                  {attributes.registrationType.label}
                </Label>
                <span style={valueStyles}>{payload.registrationType}</span>
                <Label styles={labelStyles}>{attributes.date.label}</Label>
                <span style={valueStyles}>
                  {payload.date
                    ? moment(payload.date).format("DD.MM.YYYY")
                    : ""}
                </span>
                <Label styles={labelStyles}>
                  {attributes.transportCosts.label}
                </Label>
                <span style={valueStyles}>{payload.transportCosts} €</span>
                <Label styles={labelStyles}>{attributes.notes.label}</Label>
                <span style={valueStyles}>{payload.notes}</span>
                <Label styles={labelStyles}>{attributes.layout.label}</Label>
                <span style={valueStyles}>
                  {getBooleanExpression(payload.layout)}
                </span>
                <Label styles={labelStyles}>{attributes.sign.label}</Label>
                <span style={valueStyles}>
                  {getBooleanExpression(payload.sign)}
                </span>
              </div>
              <div style={twoGroupedForm.rightGroup}>
                <Label styles={labelStyles}>
                  {attributes.transported.label}
                </Label>
                <span style={valueStyles}>{payload.transported}</span>
                <Label styles={labelStyles}>
                  {attributes.loadingLocation.label}
                </Label>
                <span style={valueStyles}>{payload.loadingLocation?.city}</span>
                <Label styles={labelStyles}>{attributes.address.label}</Label>
                <span style={valueStyles}>{payload.address?.titleDe}</span>
                <Label styles={labelStyles}>{attributes.drivers.label}</Label>
                <span style={valueStyles}>{driversView}</span>
                <Label styles={labelStyles}>
                  {attributes.certificateContent.label}
                </Label>
                <span style={valueStyles}>{certificateContentView}</span>
                <Label styles={labelStyles}>
                  {attributes.listContent.label}
                </Label>
                <span style={valueStyles}>{listContentView}</span>
                <Label styles={labelStyles}>{attributes.vehicle.label}</Label>
                <span style={valueStyles}>{payload.vehicle?.licensePlate}</span>
                <Label styles={labelStyles}>{attributes.trailer.label}</Label>
                <span style={valueStyles}>{payload.trailer?.licensePlate}</span>
                <Label styles={labelStyles}>{attributes.goodInfo.label}</Label>
                <span style={valueStyles}>
                  {payload.goodInfo?.commodityNumber}
                </span>
                <Label styles={labelStyles}>{attributes.sent.label}</Label>
                <span style={valueStyles}>
                  {getBooleanExpression(payload.sent)}
                </span>
                <Label styles={labelStyles}>
                  {attributes.commodityCode.label}
                </Label>
                <span style={valueStyles}>
                  {getBooleanExpression(payload.commodityCode)}
                </span>
              </div>
            </div>
            <Separator />
            <Cargo
              cargoListFormMode={props.formMode}
              cargos={cargos}
              setCargos={setCargos}
            />
          </div>
        )}
      </div>

      {/* Additional Forms */}
      <DriverForm
        isModalOpen={isDriverModalOpen}
        formMode={driverFormMode}
        selectedItem={selectedDriverItem}
        fkName={attributes.drivers.name}
        setFormMode={setDriverFormMode}
        closeModal={closeDriverModal}
        setCreatedItem={setDriverOption}
      />
      <TourForm
        isModalOpen={isTourModalOpen}
        formMode={tourFormMode}
        selectedItem={selectedTourItem}
        fkName={attributes.tour.name}
        setFormMode={setTourFormMode}
        closeModal={closeTourModal}
        setCreatedItem={setTourOption}
      />
      <TextForm
        isModalOpen={isCertificateContentModalOpen}
        formMode={certificateContentFormMode}
        selectedItem={selectedCertificateContentItem}
        name={attributes.certificateContent.name}
        setFormMode={setCertificateContentFormMode}
        closeModal={closeCertificateContentModal}
        setCreatedItem={setCertificateContentOption}
      />
      <TextForm
        isModalOpen={isListContentModalOpen}
        formMode={listContentFormMode}
        selectedItem={selectedListContentItem}
        name={attributes.listContent.name}
        setFormMode={setListContentFormMode}
        closeModal={closeListContentModal}
        setCreatedItem={setListContentOption}
      />
      <AddressForm
        isModalOpen={isAddressModalOpen}
        formMode={addressFormMode}
        selectedItem={selectedAddressItem}
        fkName={attributes.address.name}
        setFormMode={setAddressFormMode}
        closeModal={closeAddressModal}
        setCreatedItem={setAddressOption}
      />
      <GoodInfoForm
        isModalOpen={isGoodInfoModalOpen}
        formMode={goodInfoFormMode}
        selectedItem={selectedGoodInfoItem}
        fkName={attributes.goodInfo.name}
        setFormMode={setGoodInfoFormMode}
        closeModal={closeGoodInfoModal}
        setCreatedItem={setGoodInfoOption}
      />
      <LoadingLocationForm
        isModalOpen={isLoadingLocationModalOpen}
        formMode={loadingLocationFormMode}
        selectedItem={selectedLoadingLocationItem}
        fkName={attributes.loadingLocation.name}
        setFormMode={setLoadingLocationFormMode}
        closeModal={closeLoadingLocationModal}
        setCreatedItem={setLoadingLocationOption}
      />
      <VehicleForm
        isModalOpen={isVehicleModalOpen}
        formMode={vehicleFormMode}
        selectedItem={selectedVehicleItem}
        fkName={attributes.vehicle.name}
        setFormMode={setVehicleFormMode}
        closeModal={closeVehicleModal}
        setCreatedItem={setVehicleOption}
      />
      <TrailerForm
        isModalOpen={isTrailerModalOpen}
        formMode={trailerFormMode}
        selectedItem={selectedTrailerItem}
        fkName={attributes.trailer.name}
        setFormMode={setTrailerFormMode}
        closeModal={closeTrailerModal}
        setCreatedItem={setTrailerOption}
      />
    </Modal>
  );
};

export default CargolistForm;
